import React, { useEffect, useState } from 'react';
import { UseCartContext } from '../context/cart-context';
import { UseOverlayContext } from '../context/overlay-context';
import { DatalayerCheckoutButton } from '../shared/datalayer-events';
import { LocalePrice } from '../shared/locale-price';
import InputCartCoupon from './input-cart-coupon';
import InputCartSeller from './input-cart-seller';
import ProductCardMini from './product-card-mini';

export default function Cart() {

    // *---------------
    // * STATES
    // *---------------
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        ToggleQrcode, setOverlayZindex
    } = UseOverlayContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        formOrder
    } = UseCartContext()

    useEffect(() => {
        if( formOrder ){
            if( formOrder.items ){
                setItems( formOrder.items )
            }

            if( formOrder.value !== undefined ){
                setTotal( LocalePrice( formOrder.value / 100 ) )
            }

            if( formOrder.totalizers && formOrder.totalizers.length > 0 ){
                setSubtotal( LocalePrice( formOrder.totalizers[0].value / 100 ) )
            }
        }
    }, [formOrder])

    return(
       <>
            { /* CART HEADER */ }
            <header className='w-full px-[0.5vw] py-[2vw]'>
                <h2 className='uppercase text-[1.8vw] font-RedHatBold text-black text-center'>
                    Meu carrinho

                    <svg className='inline-block align-middle h-[2vw] w-[2vw] ml-[1vw]' width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.6005 34.0005H12.7602L7.18007 3.32002C7.11478 2.9526 6.92312 2.61956 6.63825 2.37851C6.35338 2.13747 5.99321 2.00357 5.62005 2H2" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.7989 41.9972C17.0081 41.9972 18.7989 40.2063 18.7989 37.9971C18.7989 35.788 17.0081 33.9971 14.7989 33.9971C12.5897 33.9971 10.7988 35.788 10.7988 37.9971C10.7988 40.2063 12.5897 41.9972 14.7989 41.9972Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M35.5997 41.9972C37.8088 41.9972 39.5997 40.2063 39.5997 37.9971C39.5997 35.788 37.8088 33.9971 35.5997 33.9971C33.3905 33.9971 31.5996 35.788 31.5996 37.9971C31.5996 40.2063 33.3905 41.9972 35.5997 41.9972Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.2995 26.0041H36.4198C37.1677 26.0064 37.8926 25.745 38.4668 25.2658C39.0411 24.7866 39.4281 24.1204 39.5598 23.3841L41.9999 10.0039H8.39941" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </h2>
            </header>
            
            { /* CART BODY */ }
            <div className='px-[1.2vw] pb-[1.2vw]'>

                { /* CART ITEMS */ }
                <div>
                    { ( formOrder && formOrder.orderFormId && items.length > 0 ) ? (
                        <>


                            { /* CART PRODUCTS */ }
                            { items && (
                                <>
                                    <h3 className='font-RedHatBold uppercase text-arsenic text-[1.2vw]'> seus produtos </h3>
                                    <div className='relative h-[38vw] mt-[2vw] mb-[6vw] overflow-auto scrollbar-none'>
                                        <ul className='absolute top-0 left-0'>
                                            { items.map( (product, index) => (
                                                <li className='mb-[2vw] pr-[1vw] last:mb-0' key={index}>
                                                    <ProductCardMini productdata={product} />
                                                </li>
                                            ) ) }
                                        </ul>
                                    </div>
                                </>
                            )}


                            { /* CART CUPOM */ }
                            <InputCartCoupon />


                            { /* CART CUSTOM SELLER */ }
                            <InputCartSeller />

                            { /* CART TOTAL */ }
                            { total && (
                                <div className='pt-[4vw] uppercase font-RedHatBold text-[1.1vw] '>
                                    <div className='mb-[0.7vw] pb-[0.7vw] border-b-[0.2vw] border-darkgray text-darkgray flex justify-between'>
                                        <p> Subtotal </p>
                                        <span className='font-RedHatBold'> {subtotal} </span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <p> Total </p>
                                        <span className='font-RedHatBold'> {total} </span>
                                    </div>
                                </div>
                            ) }

                            <button 
                                onClick={(e) => {
                                    e.preventDefault() 
                                    ToggleQrcode()
                                    setOverlayZindex(50)
                                    DatalayerCheckoutButton( formOrder )
                                } } 
                                className='py-[0.8vw] mt-[3.19vw] font-RedHatBold  text-white rounded-[4.56vw] text-[1.09vw] bg-arsenic text-center w-full'
                            >
                                FINALIZAR COMPRA
                            </button>
                        </>
                    ) : (
                        <p className='text-center text-black font-RedHatBold leading-tight text-[1.2vw] uppercase'>
                            Ainda não há produtos<br/> no carrinho. Adicione!
                        </p>
                    ) }
                </div>

            </div>


        </>
    )

}