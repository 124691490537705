import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import { UseCartModalContext } from '../context/cart-modal-context';
import { UseOverlayContext } from '../context/overlay-context';
import { UseCartContext } from '../context/cart-context';
import { NavLink } from 'react-router-dom';
import qrcode1Image from '../assets/images/qrCode1.png'
import qrcode2Image from '../assets/images/qrCode2.png'
import QRCode from 'react-qr-code';


export default function QrcodeSession() {
    const logoutButton = useRef(null)

    // *---------------
    // * ANIMATIONS
    // *--------------
    const pathVariants = {
        hidden: {
            opacity: 1,
            pathLength: 0
        },
        visible: i => ({
            opacity: 1,
            pathLength: 1,
            transition: {
                duration: 128,
                delay: i,
            }
        })
    } 

    // *---------------
    // * STATES
    // *---------------
    const [carturl, setCartUrl] = useState( '' );
    const [min, setMin] = useState( 3 )
    const [seconds, setSeconds] = useState( 0 )
    const [first, setFirst] = useState( true )
    
    // *---------------
    // * CART MODAL CONTEXT
    // *---------------
    const { 
        ToggleCartModal
    } = UseCartModalContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        formOrder
    } = UseCartContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        ToggleOverlay, ToggleQrcode, qrcode, setOverlayZindex
    } = UseOverlayContext()

    // *---------------
    // * TIMER
    // *--------------- 
    useEffect(() => {
        if (min !== 0 && seconds !== 0 && qrcode && !first) {
            const intervalSec = setInterval(() => {
                setSeconds(prevSec => prevSec - 1);        
            }, 1000);
            return () => {clearInterval(intervalSec);}
        }

        if (first && qrcode) {
            setFirst(false) 

            const intervalFirst = setInterval(() => {
                setMin(prevMin => prevMin - 1);        
                setSeconds(59); 
            }, 1000);
            return () => {clearInterval(intervalFirst);}
        }
        
    }, [seconds, qrcode]);

    useEffect(() => {
        if (!first) {
            if (min !== 1 && seconds === 0) {
                setSeconds(59)
                setMin(min - 1)
            }

            if (min === 1 && seconds === 0 ) {
                setSeconds(0)
                setMin(1)
                
                logoutButton.current.click()
            }
        }
    }, [min, seconds])
    // *---------------
    // * TIMER
    // *--------------- 

    useEffect(() => {
        if (!qrcode) {
            setMin(3)
            setSeconds(0)
            setFirst(true)
        }
    }, [qrcode])

    useEffect(() => {
        if( formOrder ){
            if( formOrder.orderFormId ){
                const carturl = `https://www.aramis.com.br/checkout/?orderFormId=${formOrder.orderFormId}&utm_source=totem&utm_medium=link`
                console.log( carturl )
                //setCartUrl( `https://www.aramis.com.br/checkout/?orderFormId=${formOrder.orderFormId}#/payment` )
                setCartUrl( carturl )
            }
        }
    }, [formOrder])

    return(
        <AnimatePresence>
            <article className={`bg-white relative rounded-[1.09vw] ${qrcode ? '' : 'hidden'} pt-[4.60vw] overflow-hidden flex flex-col w-[79.76vw]`}>
                <button onClick={() => [ToggleQrcode(), setOverlayZindex(40)]} className='absolute top-[3.42vw] right-[3.42vw]'>
                    <svg className='w-[1.8vw] h-[1.8vw]' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39 3L3 39" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M39 39L3 3" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>

                <div className='flex items-center flex-col mb-[2.78vw]'>
                    <h3 className='text-[3.65vw] mb-[3.42vw] font-RedHatBold text-arsenic'>Finalize sua compra</h3>
                    { /* QR CODE */ }
                    { /* https://www.aramis.com.br/checkout/?orderFormId=6ad86bd272e74539879b945e06970ede#/payment */}
                    { carturl && <QRCode size={170} value={carturl} /> }
                    <p className='text-[1.64vw] mt-[2.69vw] mb-[1.87vw] font-RedHatRegular text-center'>Aproxime a câmera do seu celular do QR CODE para <br/> finalizar sua compra pelo seu dispositivo</p>

                    <div className='w-[5.2vw] mb-[1.87vw] relative flex items-center justify-center h-[5.2vw]'>
                        <div className='w-full h-full rounded-full top-0 left-0 absolute border-[0.31vw] border-gray'></div>
                        <p className='text-[1.34vw] text-gray'>0{min - 1}:{seconds < 10 ? `0${seconds}` : seconds}s</p>

                        <svg className='absolute ' width="5.2vw" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <motion.path variants={pathVariants} initial="hidden" animate={qrcode ? "visible" : 'hidden'}  d="M37.5 2C57.1061 2 73 17.8939 73 37.5C73 57.1061 57.1061 73 37.5 73C17.8939 73 2 57.1061 2 37.5C2 17.8939 17.8939 2 37.5 2Z" stroke="#D8FF33" strokeWidth="4"/>
                        </svg>
                    </div>

                    <NavLink ref={logoutButton} onClick={() => [ToggleQrcode(), ToggleOverlay(), setOverlayZindex(40), ToggleCartModal()]} to='/' className='text-[0.911577vw] font-RedHatBold text-arsenic h-[2.55vw] w-[13.35vw] rounded-[4.56vw] items-center justify-center flex border-[0.091158vw] border-darkgray'>ENCERRAR SESSÃO</NavLink>
                </div>

                <div className='bg-black pt-[3.19vw] pb-[1.14vw]'>
                    <h3 className='text-[1.82vw] text-center mb-[4.06vw] text-white font-RedHatBold'>COMO COMPRAR VIA QR CODE?</h3>
                    <ul className='flex gap-[3.42vw] items-center justify-center'>
                        <li className='flex flex-col items-center'>
                            <div className='flex mb-[2.5vw] gap-[0.911577vw] items-center'>
                                <span className='text-[2.19vw] text-black bg-pear w-[3.46vw] font-RedHatRegular items-center justify-center rounded-full h-[3.46vw] flex'>1</span>
                                <p className='font-RedHatRegular text-[1.19vw] text-pear leading-[1.42vw]'>Aponte a câmera do<br/> seu celular para o<br/> código</p>
                            </div>
                            
                            <img alt="who pay with qrcode" className='w-[18vw] shadow-md h-[18.1vw]' src={qrcode1Image}/>
                        </li>

                        <svg className='w-[10.94vw]' width="241" height="15" viewBox="0 0 241 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M240.707 8.2032C241.098 7.81268 241.098 7.17951 240.707 6.78899L234.343 0.425026C233.953 0.0345016 233.319 0.0345016 232.929 0.425026C232.538 0.81555 232.538 1.44872 232.929 1.83924L238.586 7.49609L232.929 13.1529C232.538 13.5435 232.538 14.1766 232.929 14.5672C233.319 14.9577 233.953 14.9577 234.343 14.5672L240.707 8.2032ZM0 8.49609H240V6.49609H0V8.49609Z" fill="white"/>
                        </svg>

                        <li className='flex flex-col items-center'>
                            <div className='flex mb-[2.5vw] gap-[0.911577vw] items-center'>
                                <span className='text-[2.19vw] text-black bg-pear w-[3.46vw] font-RedHatRegular items-center justify-center rounded-full h-[3.46vw] flex'>2</span>
                                <p className='font-RedHatRegular text-[1.19vw] leading-[1.42vw] text-pear'>Continue e conclua<br/> seu pagamento pelo<br/> seu dispositivo</p>
                            </div>

                            <img alt="window pay ilustration" className='w-[18vw] shadow-md h-[18.1vw]' src={qrcode2Image}/>
                        </li>
                    </ul>
                </div>
            </article>
        </AnimatePresence>
    )

}