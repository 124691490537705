import { useState, useContext, createContext } from 'react'


const SearchContext = createContext("")

export default function SearchProvider({ children }){

    const [ searchModal, setSearchModal ] = useState( false )
    const [ searchQuery, setSearchQuery ] = useState('')
    const [ queryInPage, setQueryInPage ] = useState('')
    const [ modalQuery, setModalQuery ] = useState('')

    return (
        <SearchContext.Provider 
            value={{
                searchModal,
                setSearchModal,
                searchQuery,
                setSearchQuery,
                queryInPage,
                setQueryInPage,
                modalQuery,
                setModalQuery
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}

export function UseSearchContext() {
    return useContext(SearchContext)
}