import React, { useState, useContext } from 'react'


const CartModalContext = React.createContext()
export default function CartModalProvider({ children }){

    // SIDEBAR MODAL
    const [ cart_modal, _setCartModal ] = useState( false )

    const ToggleCartModal = () => {
        _setCartModal( !cart_modal )
    }

    const DisableCartModal = () => {
        _setCartModal( false )
    }


    return (
        <CartModalContext.Provider 
            value={{
                cart_modal,
                ToggleCartModal,
                DisableCartModal,
            }}
        >
            {children}
        </CartModalContext.Provider>
    )
}

export function UseCartModalContext() {
    return useContext(CartModalContext)
}