import { ENV_CORSPROXY } from './envdata'


//https://developers.vtex.com/vtex-rest-api/reference/productsearchsimilars 

export const QueryProductSimilars = async ( { productId, controller, retrys, delay } ) => {
    const HEADERS =  {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }

    const OPTIONS = {
        method: 'GET',
        headers: HEADERS,
    }

    // Injetando o signal no options
    if( controller ){
        OPTIONS.signal = controller.signal
    }

    // FETCH DELAY
    await new Promise(r => setTimeout(r, delay ? delay : 1000) )

    if( productId ){
        try{
            const fetch_url = `${ENV_CORSPROXY}/api/catalog_system/pub/products/crossselling/similars/${productId}`
            const response = await fetch( fetch_url, OPTIONS )
            const data = await response.json()
            
            let similars_products_url = []
            if( data && data.length > 0){
                data.forEach( ( item ) => {
                    if( similars_products_url.indexOf( item.linkText ) === -1 ){
                        similars_products_url.push( item.linkText )
                    }
                } )
            }

            return similars_products_url
        }catch( errors ){
            if( retrys > 0 ){
                console.warn( `Request Error! trying again, \nerror log: ${errors}` )
                return QueryProductSimilars( { productId, controller, retrys: retrys - 1, delay: 5000 } )
            }else{
                if (errors.name === "AbortError") {
                    console.warn("Operation timed out", errors);
                    return -1
                } else {
                    if ( controller && controller.signal.aborted ) return;
                    console.warn( errors )
                    return -1
                }
            }
            
        }
    }

}