import { React, useEffect, useState } from 'react';
import { UseCartContext } from '../context/cart-context';
import { LocalePrice } from '../shared/locale-price';
import { DatalayerAddOrderItem, DatalayerRemoveOrderItem } from '../shared/datalayer-events';

export default function ProductCardMini( { productdata } ) {

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        UpdateProductItem, productIds, formOrder
    } = UseCartContext()
    
    // *---------------
    // * STATES
    // *---------------
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const the_price = productdata.sellingPrice / 100

        setProduct({
            id: productdata.id,
            seller: productdata.seller,
            availability: productdata.availability,
            name: productdata.name,
            imgsrc: productdata.imageUrl,
            price: LocalePrice( the_price ),
            quantity: productdata.quantity,
        })
        //console.log( productdata )

    }, [productdata]);


    // *---------------
    // * FUNCTIONS
    // *---------------
    const HandlerAddQantity = () => {
        console.log( 'HandlerAddQantity()' )

        if( product ){
            UpdateProductItem( product.id, ( product.quantity + 1 ), product.seller  )
        }

        // ADD EVENT ADD ORDER ITEM IN DATA LAYER
        if (formOrder?.items?.length > 0) {
            formOrder.items.forEach( item => {
                if ( item?.id && item?.id === product?.id ) {
                    DatalayerAddOrderItem(item)
                }
            } )
        }
    }

    const HandlerRemoveQantity = () => {
        console.log( 'HandlerRemoveQantity()' )

        if( product ){
            UpdateProductItem( product.id, ( product.quantity - 1 ), product.seller  )

            if ( productIds ) {
                if ( product.quantity - 1 === 0 ) {
                    productIds.splice( productIds.indexOf(product.id), 1 )
                }
            }

            // REMOVE EVENT REMOVE ORDER ITEM IN DATA LAYER
        }

        // ADD EVENT REMOVE ORDER ITEM IN DATA LAYER
        if (formOrder?.items?.length > 0) {
            formOrder.items.forEach( item => {
                if ( item?.id && item?.id === product?.id ) {
                    DatalayerRemoveOrderItem(item)
                }
            } )
        }
    }

    const HandlerRemoveProduct = () => {
        console.log( 'HandlerRemoveProduct()' )
        if( product ){
            UpdateProductItem( product.id, 0, product.seller  )

            if ( productIds ) {
                productIds.splice( productIds.indexOf(product.id), 1 )
            }
        }

        if (formOrder?.items?.length > 0) {
            formOrder.items.forEach( item => {
                if ( item?.id && item?.id === product?.id ) {
                    DatalayerRemoveOrderItem(item, item?.quantity)
                }
            } )
        }
    }


    return(
        <figure className='flex h-[10vw] relative'>
            { product && (
                <>
                    { product.imgsrc && product.name && (
                        <img className='w-[6vw] h-[10vw] object-cover' src={ product.imgsrc } alt={ product.name } />
                    ) }
                    <figcaption className='flex-auto px-[1vw]'>
                        { product.name && (
                            <h3 className='font-RedHatRegular text-[0.95vw] uppercase line-clamp-3 mb-[0.6vw] pr-[3.2vw]'>{ product.name }</h3>
                        ) }

                        {  product.price && (
                            <span className='font-RedHatBold text-[0.95vw] uppercase text-arsenic'>{ product.price }</span>
                        ) }

                        <button className='absolute top-0 right-0 w-[3vw] h-[2.5vw] text-right' onClick={ () => { HandlerRemoveProduct() }}> 
                            <svg className='w-[1.5vw] inline-block' viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <title> Remover Todos </title>
                                <path d="M10.4561 11.8154C11.0083 11.8154 11.4561 12.2631 11.4561 12.8154V22.27C11.4561 22.8223 11.0083 23.27 10.4561 23.27C9.90377 23.27 9.45605 22.8223 9.45605 22.27V12.8154C9.45605 12.2631 9.90377 11.8154 10.4561 11.8154Z" fill="#424242"/>
                                <path d="M18.5439 12.8154C18.5439 12.2631 18.0962 11.8154 17.5439 11.8154C16.9917 11.8154 16.5439 12.2631 16.5439 12.8154V22.27C16.5439 22.8223 16.9917 23.27 17.5439 23.27C18.0962 23.27 18.5439 22.8223 18.5439 22.27V12.8154Z" fill="#424242"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4554 0C9.56334 0 8.70779 0.354382 8.07698 0.985186C7.44618 1.61599 7.0918 2.47154 7.0918 3.36364V4.72656H1C0.447715 4.72656 0 5.17428 0 5.72656C0 6.27885 0.447715 6.72656 1 6.72656H2.36328V28.1811C2.36328 28.7598 2.59315 29.3147 3.00232 29.7239C3.41149 30.1331 3.96645 30.3629 4.5451 30.3629H23.4542C24.0328 30.3629 24.5878 30.1331 24.997 29.7239C25.4061 29.3147 25.636 28.7598 25.636 28.1811V6.72656H27C27.5523 6.72656 28 6.27885 28 5.72656C28 5.17428 27.5523 4.72656 27 4.72656H20.91V3.36364C20.91 2.47154 20.5556 1.61599 19.9248 0.985186C19.294 0.354382 18.4384 0 17.5463 0H10.4554ZM19.8719 6.72656C19.8845 6.72704 19.8972 6.72727 19.91 6.72727C19.9227 6.72727 19.9354 6.72704 19.948 6.72656H23.636V28.1811C23.636 28.2293 23.6169 28.2756 23.5828 28.3097C23.5487 28.3438 23.5024 28.3629 23.4542 28.3629H4.5451C4.49688 28.3629 4.45063 28.3438 4.41653 28.3097C4.38244 28.2756 4.36328 28.2293 4.36328 28.1811V6.72656H8.05374C8.06636 6.72704 8.07905 6.72727 8.0918 6.72727C8.10454 6.72727 8.11723 6.72704 8.12986 6.72656H19.8719ZM18.91 4.72656V3.36364C18.91 3.00198 18.7663 2.65513 18.5106 2.3994C18.2548 2.14367 17.908 2 17.5463 2H10.4554C10.0938 2 9.74693 2.14367 9.4912 2.3994C9.23547 2.65513 9.0918 3.00198 9.0918 3.36364V4.72656H18.91Z" fill="#424242"/>
                            </svg>
                        </button> 
                        
                        { product.quantity && (
                            <div className='flex items-center justify-center mt-[0.6vw]'>
                                <span className='text-[0.9vw] font-RedHatBold text-darkgray mr-[1vw]'> Quantidade: </span>
                                <button className='border p-2 rounded-full border-arsenic w-[2.8vw] h-[2.2vw] flex items-center justify-center' onClick={ () => { HandlerRemoveQantity() }}>
                                    <svg className='w-[1vw] h-[1vw]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 2" fill="none">
                                        <title> Remover Item </title>
                                        <path d="M1 1H15.2295" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button> 
                                
                                <span className='font-RedHatBold text-[1.4vw] w-[2.5vw] text-center'>
                                    {product.quantity}
                                </span>
                                
                                <button className='border p-2 rounded-full border-arsenic w-[2.8vw] h-[2.2vw] flex items-center justify-center bg-pear' onClick={ () => {  HandlerAddQantity() }}> 
                                    <svg className='w-[1vw] h-[1vw]' viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title> Adicionar Item </title>
                                        <path d="M9.11426 1C9.11426 0.447715 8.66654 0 8.11426 0C7.56197 0 7.11426 0.447715 7.11426 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7.11426V15C7.11426 15.5523 7.56197 16 8.11426 16C8.66654 16 9.11426 15.5523 9.11426 15V9H15.2295C15.7818 9 16.2295 8.55229 16.2295 8C16.2295 7.44772 15.7818 7 15.2295 7H9.11426V1Z" fill="black"/>
                                    </svg>
                                </button> 
                            </div>
                        ) }
                    </figcaption>
                </>
            ) }
        </figure>
    )

}