import React, { useCallback, useEffect, useState } from 'react';
import { motion } from "framer-motion"
import { UseFilterContext } from '../context/filter-context';
import { LocalePrice } from '../shared/locale-price';
import { UseColorsContext } from '../context/color-context';

import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'

export default function FilterProducts( { filterFacets } ) {
    // *---------------
    // * FILTER CONTEXT DATA
    // *---------------
    const {
        HideFilterModal,
        filter_is_visible,
        filterquery,
        SetFilterQuery
    } = UseFilterContext()

    // *---------------
    // * COLORS CONTEXT
    // *---------------
    const {
        GetColorsByName
    } = UseColorsContext()

    // *---------------
    // * LOCAL STATES
    // *---------------
    const [ activefilters ] = useState([ 'tamanho', 'modelagem', 'cor', 'price' ])
    const [ inputs, setInputs ] = useState( {} )

    const UpdateInputs = useCallback( () => {
        const the_facets = filterFacets.facets
        
        the_facets.forEach( facet => {
            if( activefilters.includes( facet.key ) ){
                let values = facet.values

                if( facet.key === 'price' ){
                    let min = null
                    let max = null
                    values.forEach( value => {
                        if( min === null ){
                            min = value.range.from
                        }

                        if( max === null ){
                            max = value.range.to
                        }

                        if( value.range.from < min ){
                            min = value.range.from
                        }

                        if( value.range.to > max ){
                            max = value.range.to
                        }
                    } )

                    facet = { ...facet, min, max }
                }

                setInputs( inputs => ( { ...inputs, [ facet.key ]: { ...facet, values: values  } } ) )
            }
        })
    }, [activefilters, filterFacets] )

    useEffect(() => {
        if( filterFacets ){
            UpdateInputs()
        }
    }, [filterFacets, UpdateInputs] )


    // SUBMIT HANDLER
    const SubmitHandler = (e) => {
        e.preventDefault();

        let facetquery = ''
        

        inputs && Object.keys(inputs).map( ( key ) => {
            if( key !== 'price' ){
                let values = inputs[ key ].values
                let selected = values.filter( value => value.selected )
                console.log( selected )

                facetquery += selected.map( target => `/${target.key}/${target.value}` )  
            }else{
                const { minvalue, maxvalue } = inputs[ key ]
                if( minvalue && maxvalue ){
                    facetquery += `/${key}/${minvalue}:${maxvalue}`
                }
            }

            return null
        })

        facetquery =  facetquery.replace( ',', '' )
        HideFilterModal()
        if( facetquery.length > 0 ){
            console.log( `enviando a query: ${facetquery}` )
            SetFilterQuery( facetquery )
        }else{
            SetFilterQuery( null )
        }
    }

    // ACCORDION CHANGE HANDLER
    const AccordionHandler = ( e, key ) => {
        e.preventDefault()

        if( inputs[key] ){
            setInputs( {
                ...inputs,
                [key]:{
                    ...inputs[key],
                    hidden: inputs[key] ? ( !inputs[key].hidden ) : true
                }
            } )
        }
    }
    
    // INPUTS HANDLER
    const InputHandler = ( key, value ) => {

        if( key === 'price' ){
            
            setInputs({
                ...inputs, 
                price: {
                    ...inputs.price, 
                    minvalue:value[0], 
                    maxvalue:value[1]
                }
            })

            return
        }

        if( inputs[key] ){
            let new_values = inputs[key].values.map( val => {
                // Check if the value is the same and select it
                if( val.name === value.name ){
                    return { ...val, selected: !val.selected }
                }

                // On radio inputs Check if the value is not the same and unselect it
                if( key !== 'modelagem' && val.name !== value.name ){
                    return { ...val, selected: false }
                }

                return val
            } )

            setInputs( {
                ...inputs,
                [key]:{
                    ...inputs[key],
                    values: new_values
                }
            } )
        }
    }

    const ResetHandler = ( e ) => {
        e.preventDefault()
        
        if( filterquery ){
            SetFilterQuery( null )
            HideFilterModal()
        }

        UpdateInputs()
    }

    return(
        <motion.nav 
            variants={{ 
                hidden: {
                    x: '-30vw',
                },
                visible: {
                    x: 0,
                }
            }}
            initial="hidden"
            animate={ filter_is_visible ? 'visible' : 'hidden' }
            transition={{ type: "tween"  }}
            className={ `fixed flex top-[26vh] left-0 z-50` }
        >
            <div className="flex flex-col w-[30vw] min-h-[50vh] bg-white shadow-lg px-[1.5vw] py-[3vw] rounded-r-[1vw]">

                { /* FILTER HEADER */ }
                <div className='flex  justify-between w-full'>
                    <h3 className='font-RedHatBold text-[1.8vw] leading-[3vw] uppercase'> Filtrar </h3>
                    <button 
                        className='relative w-[3vw] h-[3vw]'
                        onClick={ () => HideFilterModal() }
                    > 
                        <svg className='absolute w-[1.4vw] h-[1.4vw] left-0 right-0 bottom-0 top-0 m-auto' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M47 3L3 47M47 47L3 3" stroke="#424242" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>

                { /* FILTER CLEAR */ }
                <div className='pt-[2vw]'>
                    <button 
                        className='font-RedHatBold text-[1.1vw] leading-[2.4vw] uppercase px-[1vw] text-darkgray bg-white border-[0.1vw] border-darkgray rounded-full'
                        onClick={ (e) => ResetHandler(e) }
                    > 
                        
                        Limpar

                        <svg className='ml-[0.5vw] w-[1.2vw] h-[1.2vw] inline-block' viewBox="0 0 46 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5078 15.5L30.4893 30.4815M42.9761 41.1668H11.2259L3.04016 32.9811C2.71061 32.6548 2.44901 32.2665 2.27047 31.8386C2.09193 31.4106 2 30.9515 2 30.4878C2 30.0241 2.09193 29.565 2.27047 29.1371C2.44901 28.7091 2.71061 28.3208 3.04016 27.9946L27.9946 3.04016C28.3208 2.71061 28.7091 2.44901 29.1371 2.27047C29.565 2.09193 30.0241 2 30.4878 2C30.9515 2 31.4106 2.09193 31.8386 2.27047C32.2665 2.44901 32.6548 2.71061 32.9811 3.04016L42.9761 13.0131C43.6323 13.6815 44 14.5807 44 15.5174C44 16.4541 43.6323 17.3533 42.9761 18.0216L19.8088 41.1668" stroke="#AAAAAA" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </button>
                </div>
            
                <form 
                    className='pt-[1vw]'
                    onSubmit={ (e) => { SubmitHandler(e) }}
                >
                    { inputs && Object.keys(inputs).map( ( i, field_index) => {
                        if( inputs[i].key && activefilters.indexOf( inputs[i].key ) !== -1 ) {
                            return(
                                <div key={ field_index } className='border-t-[0.1vw] border-darkgray flex flex-col mt-[1vw] first:border-t-transparent'>
                                    <button 
                                        className='flex justify-between leading-[3vw]'
                                        onClick={ (e) => { AccordionHandler( e, inputs[i].key  ) } }
                                    >
                                        <span className='font-RedHatBold text-[1.3vw] uppercase '>{ inputs[i].name }</span>

                                        <i 
                                            className='relative w-[3vw] h-[3vw]'
                                            
                                        >
                                            <svg className='absolute w-[1.1vw] h-[1.1vw] left-0 right-0 bottom-0 top-0 m-auto' viewBox="0 0 41 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 20.5L20.5 2L39 20.5" stroke="#424242" strokeWidth="4" strokeLinecap="round"        strokeLinejoin="round"/>
                                            </svg>
                                        </i>
                                    </button>
                                    <motion.div
                                        variants={{ 
                                            hidden: {
                                                height: 0,
                                            },
                                            visible: {
                                                height: 'auto',
                                            }
                                        }}
                                        initial="hidden"
                                        animate={  inputs[inputs[i].key] ? ( inputs[inputs[i].key].hidden ? 'hidden' : 'visible' ) : 'visible' }
                                        className='overflow-hidden'
                                    >
                                        { inputs[i].key === 'tamanho' && (
                                            <ul className='flex flex-wrap gap-[1vw] py-[2vw]'>
                                                { inputs[i].values.map( ( input, input_index ) => (
                                                    <li key={`tamanho-${input_index}`}>
                                                        <input 
                                                            className="sr-only peer" 
                                                            type="radio" 
                                                            value={input.value} 
                                                            name="tamanho" 
                                                            id={`tamanho-${input.id}`}
                                                            checked={ input.selected }
                                                            onChange={ () => { InputHandler( inputs[i].key, input ) } }
                                                        />
                                                        <label 
                                                            className={`block text-center leading-[3vw] w-[3vw] h-[3vw]  text-[1.1vw] font-RedHatBold    justify-center items-center border border-darkgray rounded-full cursor-pointer uppercase focus:outline-none bg-white peer-checked:border-darkgray peer-checked:bg-arsenic peer-checked:text-white`} 
                                                            htmlFor={`tamanho-${input.id}`}>
                                                            { input.value }
                                                        </label>
                                                    </li>
                                                ) ) }
                                            </ul>
                                        )}

                                        { inputs[i].key === 'cor' && (
                                            <ul className='flex flex-wrap gap-[1vw] py-[2vw] px-[0.3vw]'>
                                                 { inputs[i].values.map( ( input, input_index ) => (
                                                    <li key={`tamanho-${input_index}`}>
                                                        <input 
                                                            className="sr-only peer" 
                                                            type="radio" 
                                                            value={input.value} 
                                                            name="cor" 
                                                            id={`cor-${input.id}`}
                                                            checked={ input.selected }
                                                            onChange={ () => { InputHandler( inputs[i].key, input ) } }
                                                        />
                                                        <label 
                                                            className={`block text-center leading-[3vw] w-[2.9vw] h-[3vw]  text-[1.1vw] font-RedHatBold    justify-center items-center border-[0.2vw] border-darkgray rounded-full cursor-pointer uppercase focus:outline-none bg-white shadow-[0px_0px_0px_0.07vw_#fff] peer-checked:border-darkgray peer-checked:bg-arsenic peer-checked:text-white  peer-checked:shadow-[0px_0px_0px_0.07vw_#fff,0px_0px_0px_0.2vw_#424242] transition-shadow`} 
                                                            htmlFor={`cor-${input.id}`}
                                                            style={{ backgroundColor: GetColorsByName ? GetColorsByName( input.value ) : 'black' }}
                                                        >
                                                            
                                                        </label>
                                                    </li>
                                                 ) ) }
                                            </ul>
                                        ) }

                                        { inputs[i].key === 'modelagem' && (
                                            <ul className='py-[1vw]'>
                                                { inputs[i].values.map( ( input, input_index ) => (
                                                    <li key={`tamanho-${input_index}`}>
                                                        <label className='flex items-center text-[1.3vw] leading-[2.2vw]'>
                                                            <input 
                                                                className="mr-[0.4vw]" 
                                                                type="checkbox" 
                                                                value={input.value} 
                                                                name="tamanho" 
                                                                id={`tamanho-${input.id}`}
                                                                checked={ input.selected }
                                                                onChange={ () => { InputHandler( inputs[i].key, input ) } }
                                                            />
                                                            {input.value} ({input.quantity})
                                                        </label>
                                                    </li>
                                                ) ) }
                                            </ul>
                                        ) }

                                        { inputs[i].key === 'price' && inputs[i].values && (
                                            <div className=''>
                                                <div>
                                                    <span className='text-[1.1vw] font-RedHatLight '>
                                                        Selecionado: <b>{ `${LocalePrice( inputs[i].minvalue ? inputs[i].minvalue : 0 )} - ${LocalePrice( inputs[i].maxvalue ? inputs[i].maxvalue : 0 )}`  } </b>
                                                    </span>
                                                </div>
                                                <div className='relative py-[1vw] px-[1vw]'>
                                                    <span className='font-RedHatBold text-[1.1vw] absolute bottom-0 left-0 text-darkgray'>
                                                        { LocalePrice( inputs[i].min ) }
                                                    </span>
                                                    
                                                    <Box sx={{ width: '100%' }}>

                                                            <Slider
                                                                value={ [inputs[i].minvalue, inputs[i].maxvalue] }
                                                                min={ inputs[i].min }
                                                                max={ inputs[i].max }
                                                                valueLabelDisplay="off"
                                                                onChange={ (e) => InputHandler('price', e.target.value) }
                                                                sx={{
                                                                    '& .MuiSlider-track': {
                                                                      color: '#424242',
                                                                    },
                                                                    '& .MuiSlider-rail': {
                                                                      color: '#aaaaaa',
                                                                      opacity: 1
                                                                    },
                                                                    '& .MuiSlider-thumb': {
                                                                      width: 18,
                                                                      height: 18,
                                                                      backgroundColor: '#424242',
                                                                      '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                      },
                                                                    },
                                                                }}
                                                            />
                                                    </Box>

                                                    <span className='font-RedHatBold text-[1.1vw] absolute bottom-0 right-0 text-darkgray'>
                                                        { LocalePrice( inputs[i].max ) }
                                                    </span>
                                                </div>
                                            </div>
                                        ) }
                                    </motion.div>
                                </div>
                            )
                        }else{
                            return null
                        }
                    } ) }  
                    { /* FILTER SUBMIT */ }
                    <div className='mt-[2vw]'>
                        <input 
                            type='submit' 
                            className='flex items-center justify-center w-[70%] h-[3vw] bg-arsenic text-white text-[1.1vw] font-RedHatBold uppercase rounded-full mt-[1vw] mx-auto' 
                            value='Aplicar'
                        />
                    </div>
                </form>
            </div>
        </motion.nav>
    )

}