import { AnimatePresence } from 'framer-motion';
import { useRef, useState } from 'react';
import StandByOverlay from './standby-overlay';

export default function BackgroundVideo( {video, overlay, classname} ) {
    const ref = useRef()
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isOverlayLoaded, setIsOverlayLoaded] = useState(false);

    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };

    const onEnded = () => {
        //console.log( ref.current.duration  - 4, ref.current.currentTime )
        if(  ref.current.currentTime > ref.current.duration  - 6 ){
            setIsOverlayLoaded( true )
        }else{
            setIsOverlayLoaded( false )
        }
    };


    return(
        <>
            <div className='relative'>
                <AnimatePresence exitBeforeEnter>
                    { overlay && isOverlayLoaded && (
                        <StandByOverlay />
                    ) }
                </AnimatePresence>
                <video 
                    autoPlay 
                    muted 
                    loop 
                    ref={ref}
                    onLoadedData={onLoadedData}
                    onTimeUpdate={onEnded}
                    src={video} 
                    className={`w-screen h-screen object-cover ${classname} `}
                / >
                { !isVideoLoaded && (
                    <div className='block absolute top-0 left-0 w-screen h-screen bg-black z-[1]'>
                        
                    </div>
                )}
            </div>
        </>
    )

}
