import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import ProductLoader from './product-loader';
import ProductCard from './product-card-revised';
import { DatalayerViewItems } from '../shared/datalayer-events';

const showLoadCard = {
    hidden: {
        opacity: 0
    },
    visible: i => ({
        opacity: 1,
        transition: {
            type: "Spring",
            duration: 1,
            delay: i
        },
    })
  };

export default function ProductList( { products, placeholders, columns, loading = false } ) {

    useEffect( () => {
        if( products?.length ){
            DatalayerViewItems( products )
        }
    }, [products])

    return(
        <section className='flex'>
            { !loading && products && products.length > 0 && (
                <ul className={
                    `grid  gap-3 lg:gap-[3.65vw] w-full`
                }
                style={{
                    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr) )`
                }}>
                    
                    { products.map( ( product, index ) => (
                        <li key={ index }>
                            <ProductCard rawproduct={ product } i={ index } allproducts={ products }  />
                        </li>
                    ) ) }
                </ul>
            ) }

            { ( loading || products.length === 0 ) && placeholders && (
                    <ul className={
                            `grid  gap-3 lg:gap-[2.3vw] w-full`
                        }
                        style={{
                            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr) )`
                        }}
                    >
                        {[...Array(placeholders)].map((x, i) =>
                            <motion.li 
                            custom={i * 0.5}
                            variants={showLoadCard}
                            initial="hidden"
                            animate="visible"
                            className='overflow-hidden' key={i}>
                                <ProductLoader index={ i } width='100%' height="32.5vw" rounded="1.4vw" />
                                <ProductLoader className='mt-[1.4vw]' index={ i } width='100%' height="2.2vw" rounded="0.6vw" />
                                <ProductLoader className='mt-[1.4vw]' index={ i } width='66%' height="2.2vw" rounded="0.6vw" />
                                <ProductLoader className='ml-auto mt-[1.7vw]' index={ i } width='50%' height="2.2vw" rounded="2vw" />
                            </motion.li>
                        )}
                    </ul>
                )
            }
            
        </section>
    )

}