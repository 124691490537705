import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function FooterSection( { rawdata } ) {
    const [footerCards, setFooterCards] = useState( null );

    useEffect( () => {
        if( rawdata ){
            setFooterCards( rawdata )
        }
    }, [rawdata])
    

    return(
        <footer className='p-4 px-8 lg:px-[3.87vw] lg:py-[3vw] bg-[#D9D9D9]'>
            { footerCards && footerCards.length > 0 && (
                <ul className='flex w-full gap-[2vw] h-auto'>
                    { footerCards.map( ( footerCard, index ) => (
                        <motion.li key={index} className='h-full flex-1 relative rounded-[1vh] overflow-hidden'>
                            { footerCard.target && footerCard.imgurl && footerCard.target !== '#' ?
                                <NavLink to={footerCard.target} className='w-full h-full relative'>
                                    <img src={footerCard.imgurl} alt={footerCard.title} className='w-full h-full object-cover '/>
                                </NavLink> 
                                :
                                <img src={footerCard.imgurl} alt={footerCard.title} className='w-full h-full object-cover '/>
                            }
                            
                        </motion.li> 
                        ) ) 
                    }
                </ul>
         ) }
         
    </footer>
    )
}