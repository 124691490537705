import React from 'react';

export default function ProductLoader({width, height, rounded, className}) {

    return(
        <div 
        className={`${className} animate-loading bg-[length:200%_100%] bg-gradient-to-r from-[#D0D0D0] via-[#F9F9F9] to-[#D0D0D0] overflow-hidden bg-[#D9D9D9]`}
        style={ { width: width, height: height, borderRadius: rounded  } }
        />
    )

}