import React from 'react';

import { UseOverlayContext } from '../context/overlay-context';
import { UseSearchContext } from '../context/search-context';
import { UseCartContext } from '../context/cart-context';
import { useNavigate } from 'react-router-dom';
import Keyboard from './keyboard';

export default function SearchModal() {
    const navigate = useNavigate();
    
    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        setOverlayZindex
    } = UseOverlayContext()

    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        searchModal, setSearchModal, setSearchQuery, modalQuery, setModalQuery
    } = UseSearchContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        openKeyboard, keyboardInputReference, setKeyboardInputReference
    } = UseCartContext()

    const submit = () => {
        setSearchQuery(modalQuery)

        navigate(`/search?q=${modalQuery}` )

        setSearchModal(false)
        setKeyboardInputReference('')
        setOverlayZindex(40)
        setModalQuery('')
    }

    return(
        <article className={`w-[79.76vw] relative ${searchModal ? '' : 'hidden'} min-h-[24.4vw] h-max item-center overflow-auto flex flex-col px-[7.93vw] pb-[4.74vw] pt-[4.60vw] rounded-[1.09vw] bg-white`}>
            <h3 className='font-RedHatBold mb-[3.74vw] text-[2.92vw] text-arsenic'>O que você procura?</h3>

            <button className='absolute top-[1.5vw] right-[2.10vw]' onClick={() => [setOverlayZindex(40), setSearchModal(false)]}>
                <svg  className='w-[1.64vw]' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39 3L3 39" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M39 39L3 3" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            
            <form onSubmit={submit} className='w-full flex h-[3.46vw] overflow-hidden outline-none border-[0.091158vw] pl-[1.41vw] pr-[1.19vw] border-arsenic rounded-[4.19vw]'>
                <input onClick={() => openKeyboard('search')} placeholder="Busque" className='outline-none text-[1.64vw] font-RedHatRegular h-full flex-1' value={modalQuery} onChange={e => setModalQuery(e.target.value)} type='text' />
                <button className='outline-none py-[0.6vw] h-full' type='submit'>
                    <svg className=' w-full h-full top-0 left-0 right-0 bottom-0 m-auto block' fill="#424242" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30">
                        <title> Search </title>
                        <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/>
                    </svg>
                </button>
            </form>

            {keyboardInputReference === 'search'  ? <div className='mt-[10.53vw]'><Keyboard position='relative' /></div> : null}

        </article>
    )

}