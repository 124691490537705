import React, { useState, useContext, createContext } from 'react'
import { AddSellerData, CreatFormOrder, PostCouponData, RemoveSellerData, UpdateCartItem } from '../shared/formorder-service'
import { ValidateCustomSeller } from '../shared/customsellers-service';

const CartContext = createContext()

export default function CartProvider({ children }){

    const [formOrder, _setFormOrder] = useState('')

    const [cupomName, setName] = useState('');

    const [error, setError] = useState('');

    const [coupon, setCoupon] = useState('');

    const [seller, setSeller] = useState('');

    const [keyboardHidden, setKeyboardHidden] = useState(true)

    const [firstAccessCart, setFirstAccessCart] = useState(false)

    const [keyboardInputReference, setKeyboardInputReference] = useState([])

    const [ productIds, setProdutsIds ] = useState( [] )

    const openKeyboard = async(inputReference) => {
        setKeyboardHidden(false)

        setKeyboardInputReference(inputReference)
    }

    const GetFormOrder = async() => {
        const _formorder = await CreatFormOrder( 3 )
        _setFormOrder( _formorder )

        return _formorder
    }

    const UpdateProductItem = async( productId, quantity, sellerId ) => {
        let _formorder = formOrder
        if( !_formorder ){
            _formorder = await GetFormOrder()
        }

        if( productId !== undefined && sellerId !== undefined && quantity !== undefined ){
            const _updated_formorder = await UpdateCartItem( productId, quantity, sellerId,  _formorder )
            _setFormOrder( _updated_formorder )
        }else{
            console.warn( 'productId, sellerId ou quantity não enviado para AddProductItem()' )
        }
    }

    const UpdateSellerData = async( sellerId ) => {
        let _formorder = formOrder
        if( !_formorder ){
            _formorder = await GetFormOrder()
        }

        if( sellerId ){
            const _updated_formorder = await AddSellerData( sellerId, _formorder.orderFormId )
            _setFormOrder( _updated_formorder )
        }else{
            await RemoveSellerData()
        }
    }

    const AddCoupom = async( coupon ) => {
        let _formorder = formOrder
        if( !_formorder ){
            _formorder = await GetFormOrder()
        }

        if( coupon !== undefined ){
            const _updated_formorder = await PostCouponData( coupon, _formorder.orderFormId )
            _setFormOrder( _updated_formorder )
        }
    }

    const HandlerSubmit = async(e) => {
        e.preventDefault();
        const search_result = await ValidateCustomSeller( seller )
        setError( '' )

        if( search_result && search_result.length > 0 ){
            setName( search_result[0].nomevendedora )
            UpdateSellerData( seller )
        }else{
            setError( 'Código inválido - Vendedor não encontrado' )
        }
    }

    const CheckCartItem = ( productId ) => {
        if( formOrder && formOrder.items ){
            formOrder.items.map( item => item.id === productId )
        }else{
            return false
        }
    }

    return (
        <CartContext.Provider 
            value={{
                formOrder,
                CheckCartItem,
                UpdateProductItem,
                UpdateSellerData,
                AddCoupom,
                firstAccessCart,
                setFirstAccessCart,
                coupon,
                setCoupon,
                keyboardInputReference,
                setKeyboardInputReference,
                seller, 
                setSeller,
                keyboardHidden, 
                setKeyboardHidden,
                HandlerSubmit,
                openKeyboard,
                cupomName,
                setName,
                error,
                GetFormOrder,
                setError,
                productIds,
                setProdutsIds
            }}
        >
            {children}
        </CartContext.Provider>
    )
}


export function UseCartContext() {
    return useContext(CartContext)
}