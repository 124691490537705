import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import Main from "./components/main";
import './theme.css';
import OverlayProvider from './context/overlay-context';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import CartModalProvider from './context/cart-modal-context';
import CartProvider from './context/cart-context';
import ColorsProvider from './context/color-context';
import FrontEndProvider from './context/frontend-context';
import FaqProvider from "./context/faq-context";
import FilterProvider from "./context/filter-context";
import SearchProvider from "./context/search-context";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <FrontEndProvider>
            <FilterProvider>
                <FaqProvider>
                    <SearchProvider> 
                        <ColorsProvider>
                            <CartProvider>
                                <CartModalProvider>
                                    <OverlayProvider>
                                        <HashRouter>
                                            <Main />
                                        </HashRouter>
                                    </OverlayProvider>
                                </CartModalProvider>
                            </CartProvider>
                        </ColorsProvider>
                    </SearchProvider>
                </FaqProvider>
            </FilterProvider>
        </FrontEndProvider>
    </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();