import React from "react";
import { Route, Routes, useLocation  } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import PageHome from "../pages/page-home";
import PageStandBy from '../pages/page-standby';
import PageCollection from '../pages/page-collection'
import PageCategory from '../pages/page-category'
import NavigationProvider from '../context/navigation-context';
import ProductModalProvider from '../context/product-modal-context';
import FilterProvider from '../context/filter-context';
import PagePrototype from '../pages/page-prototype';
import PageSearch from "../pages/page-search";


export default function Main() {
    const location = useLocation()
    return(
        <>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.key}>
                    <Route
                        exact
                        path='/'
                        element={<PageStandBy />}
                    />
                    <Route
                        exact
                        path='/standby/:id'
                        element={<PageStandBy />}
                    />
                    <Route
                        exact
                        path='/home'
                        element={
                            <NavigationProvider>
                                <ProductModalProvider>
                                    <PageHome />
                                </ProductModalProvider>
                            </NavigationProvider>
                        }
                    />
                    <Route
                        exact
                        path='/collection'
                        element={
                            <NavigationProvider>
                                <ProductModalProvider>
                                    <PageCollection />
                                </ProductModalProvider>
                            </NavigationProvider>
                        }
                    />
                    <Route
                        exact
                        path='/category/:categoryid'
                        element={
                            <FilterProvider>
                                <NavigationProvider>
                                    <ProductModalProvider>
                                        <PageCategory />
                                    </ProductModalProvider>
                                </NavigationProvider>
                            </FilterProvider>
                        }
                    />
                    <Route
                        exact
                        path='/search'
                        element={
                            <FilterProvider>
                                <NavigationProvider>
                                    <ProductModalProvider>
                                        <PageSearch />
                                    </ProductModalProvider>
                                </NavigationProvider>
                            </FilterProvider>
                        }
                    />
                    <Route
                        exact
                        path='/prototype'
                        element={
                            <PagePrototype />
                        }
                    />
                </Routes>
            </AnimatePresence>
        </>
    )
}
