import { useState, useContext, useEffect, useCallback, createContext } from 'react'
import { QueryMasterData } from '../shared/masterdata-service'

const ColorsContext = createContext( "")
export default function ColorsProvider({ children }){

    const [ _colors, _setColors ] = useState( false )

    const FetchColors = useCallback( async ( controller ) => {
        const data = await QueryMasterData( { acronym: 'CO', controller: controller, page: 0, perpage: 1000, retrys: 3, delay: 1000 } )

        if( data ){
            _setColors( data )
        }
    }, [] )

    const GetColorsByName = ( name ) => {
        const color = _colors.find( ( color ) => color.colorname.toLowerCase() === name.toLowerCase() )
        
        if( color ){
            return color.value
        }else{
            return "black"
        }
    }

    useEffect( () => {
        const controller = new AbortController()
        FetchColors( { acronym: 'CO', controler: controller } )
        
        return () => {
            controller.abort()
        }
    }, [FetchColors] )

    return (
        <ColorsContext.Provider 
            value={{
                GetColorsByName
            }}
        >
            {children}
        </ColorsContext.Provider>
    )
}

export function UseColorsContext() {
    return useContext(ColorsContext)
}