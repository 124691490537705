import React, { useState, useContext } from 'react'


const ProductModalContext = React.createContext()
export default function ProductModalProvider({ children }){

    // SIDEBAR MODAL
    const [ activeproduct, setActiveModalItem ] = useState( null )

    return (
        <ProductModalContext.Provider 
            value={{
                activeproduct, 
                setActiveModalItem
            }}
        >
            {children}
        </ProductModalContext.Provider>
    )
}

export function UseProductModalContext() {
    return useContext(ProductModalContext)
}