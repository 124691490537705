import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion"
import BackgroundSection from '../components/background-section';
import FooterSection from '../components/footer-section';
import BannerCarousel from '../components/banner-carousel';
import Highlights from '../components/highlights';
import Navigation from '../components/navigation';

import { useNavigate } from 'react-router-dom';
import SidebarArea from '../components/sidebar';
import Overlay from '../components/overlay';

import { GetCategories } from '../shared/get-categories';

import Cart from '../components/cart';
import { UseNavigationContext } from '../context/navigation-context';
import ProductList from '../components/product-list-revised';
import ProdctModal from '../components/product-modal';
import { ProductsQueryIntelligentSearch } from '../shared/intelligent-search';
import { UseFrontEndContext } from '../context/frontend-context';
import { DatalayerPageView, DatalayerSimpleEvent } from '../shared/datalayer-events';

const containerVariants = {
    hidden: {
        x: '-100vw',
        opacity: 0
    },
    visible: {
        x: '0px',
        opacity: 1,
        transition: {
            delay: .3,
            duration: .6
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}


export default function PageHome(){

    const navigate = useNavigate();

    // *---------------
    // * DATALAYER CHECKER
    // *---------------
    const [ datalayer_pushed, setDatalayerPushed ] = useState(false)

    // *---------------
    // * NAVIGATION CONTEXT
    // *---------------
    const { 
        activeTab,
        setActiveTab,
    } = UseNavigationContext()

    // *---------------
    // * CATEGORIES & PRODUCTS 
    // *---------------
    const [ categories ] = GetCategories( 3, 1000 )
    const [ products, setProducts ] = useState([])
    const [ productsLoading, setProductsLoading ] = useState(false)

    const QueryData = useCallback( async( { brandname, page, perpage, categoryid, controller } ) => {
        setProductsLoading( true )
        const rawdata = await ProductsQueryIntelligentSearch( { brandname, page, perpage, categoryid, controller, retrys: 3, delay: 2000 } )

        if( rawdata ) {
            if( rawdata.products ){ 
                setProducts( rawdata.products )
                setDatalayerPushed( true )
            }
        }

        setProductsLoading( false )
    }, [] )

    useEffect( () => { 
        if( categories && categories.length > 0 ){
            setActiveTab( categories[0].id )
        }
    }, [categories, setActiveTab ] )

    useEffect(() => {
        const controller = new AbortController()

        if( activeTab ){
            QueryData( {brandname: 'aramis', page: 1, perpage: 4, categoryid: activeTab, orderdesc: true, controller: controller } )
        }

        return () => {
            controller.abort()
        }
    },[activeTab, QueryData])


    // *---------------
    // * MASTERDATA CONTEXT
    // *---------------
    const { banners, footerBanners } = UseFrontEndContext();
    const [footerCards, setFooterCards] = useState( null );

    useEffect( () => {

        if (footerBanners) {
            if (footerBanners.length > 0) {
                setFooterCards(footerBanners)
            }
        }
    }, [footerBanners])



    // *---------------
    // * DATALAYER
    // *---------------
    useEffect(() => {
        if( !datalayer_pushed ){
            DatalayerPageView()
            DatalayerSimpleEvent( 'HomePageView' )
        }
    },[datalayer_pushed])

    // *---------------
    // * MARKUP
    // *---------------
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='bg-darkgray min-h-screen w-screen flex flex-col overflow-hidden'
        >
            <BackgroundSection foreigner_color={'#fff'} background_color={'#424242'} >
                { /* BANNER */ }
                <BannerCarousel rawdata={ banners } delay={ 6000 } />
                { /* HIGHLIGHTS */ }
                <Highlights />
            </BackgroundSection>


            <BackgroundSection  className={'flex-1'} foreigner_color={'#D9D9D9'} background_color={'#fff'} >
                { /* CATEGORIES NAV */ }
                <div className='w-full'>
                    <Navigation title='CATEGORIAS' items={categories} />
                </div>

                <div className=''>
                    { /* PRODUCTS PREVIEW */ }
                    <div className='w-full mb-5 lg:mb-[5vw]'>
                        <ProductList products={products} placeholders={4} columns={4} loading={productsLoading} />
                    </div>

                    { /* PRODUCTS CTA */ }
                    <div className=''>
                        <button 
                            className=' bg-[#424242] font-bold m-auto text-white text-[10px] lg:text-[0.91vw] block py-[0.4vw] px-[2.2vw] rounded-[2vw]' 
                            type="submit"
                            onClick={ () => {   
                                navigate(`/category/${activeTab}` ) 
                            }}
                            >
                            VEJA MAIS
                        </button>
                    </div>
                </div>
            </BackgroundSection>


            { /* FOOTER*/ }
            <FooterSection rawdata={ footerCards } />

            { /* SIDEBAR */ }
            <SidebarArea>
                <Cart />
            </SidebarArea>

            <ProdctModal />

            <Overlay />
        </motion.div>
    );
}


// const [counter, setCounter] = useState(0);
// let navigate = useNavigate();

// useEffect(() => {
//     const redirect_timer = window.setTimeout( () => navigate("/"), 15000 );

//     return () => clearTimeout(redirect_timer);
// }, [navigate]); 

// useEffect(() => {
//     const counter_timer = setTimeout( () => setCounter(counter + 1), 1000);

//     return () => clearTimeout(counter_timer);
// }, [counter]); 