import React, { useEffect, useState } from 'react';
import { UseCartContext } from '../context/cart-context';
import { UseCartModalContext } from '../context/cart-modal-context';
import { UseSearchContext } from '../context/search-context';
import { useNavigate } from 'react-router-dom';
import { UseOverlayContext } from '../context/overlay-context';

export default function Keyboard({top, left, className, zIndex = 40, position = 'absolute', right, bottom}) {
    const navigate = useNavigate();

    // *---------------
    // * STATES
    // *---------------
    const [keyboardUse, setKeyboardUse] = useState('ABC 123')
    
    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        AddCoupom, seller, setSeller, setName, coupon, setCoupon, setKeyboardHidden, HandlerSubmit, keyboardInputReference, setKeyboardInputReference
    } = UseCartContext()

    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        setSearchQuery, setSearchModal, queryInPage, setQueryInPage,  modalQuery, setModalQuery
    } = UseSearchContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        setOverlayZindex
    } = UseOverlayContext()


    // *---------------
    // * CART MODAL CONTEXT DATA
    // *---------------
    const {
        cart_modal
    } = UseCartModalContext()


    const toogleUppercase = () => {
        setKeyboards({ 
            ...keyboards,

            [keyboardUse]: {
                ...keyboards[keyboardUse],

                keys: keyboards[keyboardUse].keys.map( keys => 
                    keys.map( key => {
                        if (typeof key === 'string') {
                            if (key === key.toUpperCase()) {
                                return key.toLowerCase()
                            } else {
                                return key.toUpperCase()
                            }
                        } else {
                            return key
                        }
                    } ) 
                )
            },
        })
    }

    const [keyboards, setKeyboards] = useState({
        'ABC 123': {
            colAddButtons: 4,
            deleteButton: true,
            colDiferentGap: 2,
            toggleUpperCaseButton: true,
            proxKeyboard: '=\\#',
            keys: [[1, 2, 3, 4, 5, 6, 7, 8, 9, 0], ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'], ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], ['Z', 'X', 'C', 'V', 'B', 'N', 'M']]
        },

        '=\\#': {
            colAddButtons: 4,
            colDiferentGap: 2,
            proxKeyboard: 'ABC 123',
            keys: [['"', "'", '@', '#', '%', '¨', '&', '*', '(', ')'], ['_', '-', '+', '=', '§', '´', '`', '{', '[', 'ª'], ['^', '~', '}', ']', 'º', '\\', '|', ',', '<'], ['>', '.', ':', ';', '?', '/', '°']]
        }
    })

    const keyboardInputs = {
        'seller' : (key) => {
            setName('')
            setSeller( seller + key )
        },
        'coupon' : (key) => {
            setCoupon( coupon + key )
        },
        'search' : (key) => {
            setModalQuery( modalQuery + key )
        },
        'search-page' : (key) => {
            setQueryInPage( queryInPage + key )
        }
    }

    const keyboardActionOk = {
        'seller' : (e) => {
            HandlerSubmit( e )
        },
        'coupon' : () => {
            AddCoupom( coupon )
        },
        'search' : () => {
            setSearchQuery(modalQuery)

            navigate(`/search?q=${modalQuery}` )

            setSearchModal(false)
            setKeyboardInputReference('')
            setOverlayZindex(40)
            setModalQuery('')
        },
        'search-page' : () => {
            setSearchQuery( queryInPage )
            setKeyboardInputReference('')
        }
    }

    const keyboardActionDelete = {
        'seller' : () => {
            setName('') 
            setSeller( seller.slice(0, seller.length - 1) )
        },
        'coupon' : () => {
            setCoupon( coupon.slice(0, coupon.length - 1) )
        },
        'search' : () => {
            setModalQuery( modalQuery.slice(0, modalQuery.length - 1) )
        },
        'search-page' : (key) => {
            setQueryInPage( queryInPage.slice(0, queryInPage.length - 1) )
        }
    }

    const keyboardActionSpace = {
        'seller' : () => {
            setSeller( seller + ' ' )
        },
        'coupon' : () => {
            setCoupon( coupon + ' ' )
        },
        'search' : () => {
            setModalQuery( modalQuery + ' ' )
        },
        'search-page' : (key) => {
            setQueryInPage( queryInPage + ' ' )
        }
    }

    useEffect(() => {
         if (!cart_modal) {
            setKeyboardHidden(true)
         }
    }, [cart_modal, setKeyboardHidden])

    return(
        keyboards && keyboardInputReference && (
            <article 
                className={`items-center rounded-[2.05vw] ${className} gap-y-[1.28vw] shadow-md flex flex-col text-pear bg-black bg-opacity-90 px-[2.78vw]  py-[2.05vw]`}
                style={{ top, left, right, bottom, zIndex, position: position }}
            >
                <button onClick={() => setKeyboardInputReference('')} className={`absolute ${keyboardInputReference === 'seller' || keyboardInputReference === 'coupon' ? 'text-white' : 'text-black'} text-[2.19vw] left-[1.1vw] font-RedHatRegular -top-[4.83vw]`}>[ Fechar X ]</button>

                {keyboards[keyboardUse].keys.map((keys, index) => (
                    <ul key={index} className={`flex ${index+1 === keyboards[keyboardUse].colDiferentGap ? 'gap-x-[0.72vw]' : 'gap-x-[1vw]'}`}>
                        {keyboards[keyboardUse].colAddButtons === index+1 && keyboards[keyboardUse].toggleUpperCaseButton !== false && (
                            <li key="upperCase">
                                <button onClick={() => toogleUppercase()} className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[4.92vw] text-[2.8vw]'>
                                    <svg className='w-[2.28vw] h-[2.28vw]' width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 26L26 1L51 26H38.5V48.9167C38.5 49.4692 38.2805 49.9991 37.8898 50.3898C37.4991 50.7805 36.9692 51 36.4167 51H15.5833C15.0308 51 14.5009 50.7805 14.1102 50.3898C13.7195 49.9991 13.5 49.4692 13.5 48.9167V26H1Z" fill="#AAAAAA" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </li>
                        ) }

                        {keys.map(key => (
                            <li key={key}>
                                <button  
                                    className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[4.92vw] text-[2.8vw]'
                                    onClick={() => keyboardInputs[keyboardInputReference](key)}
                                >
                                    {key}
                                </button>
                            </li>
                        ))}

                        {keyboards[keyboardUse].colAddButtons === index+1 && keyboards[keyboardUse].deleteButton !== false && (
                            <li key="delete">
                                <button onClick={(e) => keyboardActionDelete[keyboardInputReference](e)} className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[8.3vw] text-[2.8vw]'>
                                    <svg className='w-[3.5vw] h-[2.5vw]' width="74" height="57" viewBox="0 0 74 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2587 55.5356L0 28.4615L16.2587 1.3875C16.5063 0.965715 16.8597 0.61586 17.284 0.372519C17.7083 0.129178 18.1888 0.000773568 18.6779 0H71.1538C71.9087 0 72.6326 0.299862 73.1664 0.833619C73.7001 1.36738 74 2.09131 74 2.84615V54.0769C74 54.8317 73.7001 55.5557 73.1664 56.0894C72.6326 56.6232 71.9087 56.923 71.1538 56.923H18.6779C18.1888 56.9223 17.7083 56.7939 17.284 56.5505C16.8597 56.3072 16.5063 55.9573 16.2587 55.5356Z" fill="#D8FF33"/>
                                        <path d="M51.2371 19.9375L34.1602 37.0144" stroke="#424242" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M51.2371 37.0144L42.6986 28.476L34.1602 19.9375" stroke="#424242" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </li>
                        ) }
                    </ul>
                ))}

                <ul className='flex gap-x-[1.28vw] mt-[0.31vw]'>
                    <li>
                        <button onClick={() => setKeyboardUse(keyboards[keyboardUse].proxKeyboard)} className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[11.94vw] text-[2.19vw]'>{keyboards[keyboardUse].proxKeyboard}</button>
                    </li>
                    <li>
                        <button onClick={() => keyboardActionSpace[keyboardInputReference]()} className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[28.62vw] text-[2.19vw]'>Space</button>
                    </li>
                    <li>
                        <button onClick={(e) => keyboardActionOk[keyboardInputReference](e)} className='flex shadow-sm font-RedHatRegular rounded-[0.865998vw] items-center justify-center bg-arsenic h-[4.92vw] w-[12.67vw] text-[2.19vw]'>Ok</button>
                    </li>
                </ul>
            </article>
        ) 
    )

}