import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence } from "framer-motion"
import { UseProductModalContext } from '../context/product-modal-context';
import { UseOverlayContext } from '../context/overlay-context';
import { UseCartContext } from '../context/cart-context';
import icoAntimicrobiano from '../assets/images/antimicro.png'
import icoAntiodor from '../assets/images/antiodor.png'
import icoFlexibilidade from '../assets/images/flex.png'
import icoLiquidRepeller from '../assets/images/provada.png'
import icoAcabacmentosustentavel from '../assets/images/acabamentosus.png'
import icoSecagemRapida from '../assets/images/secrap.png'
import icoSemPassadoria from '../assets/images/sempass.png'
import icoProtecaoUV from '../assets/images/protecaoUV.png'

export default function ProdctModal(  ) {

    // *---------------
    // * PRODUCT MODAL CONTEXT
    // *---------------
    const { 
        activeproduct, 
        setActiveModalItem
    } = UseProductModalContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const {
        ActiveOverlay,
        DisableOverlay
    } = UseOverlayContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        UpdateProductItem, 
        firstAccessCart, 
        setFirstAccessCart,
        productIds
    } = UseCartContext()

    // *---------------
    // * LOCAL STATES
    // *---------------

    // Product
    const [ activeSize, setActiveSize ] = useState( 0 )
    const [ activeImg, setActiveImg ] = useState( 0 )
    const [ activeProductAttrs, setActiveProductAttrs ] = useState( [] )

    // DESCRIPTIONS
    const [ descriptiontabs, setDescriptionTabs ] = useState( {} )

    // VIDEO
    const [ video, setVideo ] = useState( {} )

    useEffect(() => {
        console.log(activeproduct)
    }, [activeproduct])
    // update description tabs
    const UpdateDescriptionTabs = useCallback( ( { activeproduct } ) => {
        let obj = {}

        if( activeproduct.product ) {
            const the_product = activeproduct.product

            // DESCRIPTION TAB
            if( the_product.description ){
                obj = { 
                    ...obj,
                    description: { 
                        active: true,
                        name: 'Descrição',
                        content: the_product.description
                    }
                }
            }

            if( the_product.properties ){
                const specifications_table = []
                const shaping_table = []
                const care_table = []

                the_product.properties.map( ( property ) => {
                    if( property.name && property.values ){

                        // SPECIFICATIONS TAB
                        if( 
                            property.name === "Indicado" || 
                            property.name === "Composição" || 
                            property.name === "Cor Real" || 
                            property.name === "Matéria-Prima" ||
                            property.name === "Detalhes" ||
                            property.name === "Atributos" 
                        ){
                            specifications_table.push(
                                {
                                    key: property.name,
                                    value: property.values
                                }
                            )
                        }

                        // PRODUCT FIT TAB
                        if( 
                            property.name === "Modelagem" ||
                            property.name === "Medidas Complementares" ||
                            ( property.name === "Tamanho" && ( shaping_table.findIndex( element => element.key === "Tamanho" ) === -1  )) ||
                            property.name === "Modelo veste" ||
                            property.name === "Modelo veste" ||
                            property.name === "Medidas modelo" 
                        ){
                            shaping_table.push(
                                {
                                    key: property.name,
                                    value: property.values
                                }
                            )
                        }

                        // PRODUCT CARE TAB
                        if( 
                            property.name === "Lavagem" ||
                            property.name === "Alvejamento" ||
                            property.name === "Secadora" ||
                            property.name === "Secagem natural" ||
                            property.name === "Passadoria" ||
                            property.name === "Limpeza profissional a seco e a úmido"
                         ){
                            care_table.push(
                                {
                                    key: property.name,
                                    value: property.values
                                }
                            )
                        }

                        return null
                    }

                    return null
                } )

                obj =  { 
                    ...obj,
                    specifications_tab: { 
                        active: false,
                        name: 'Especificações ténicas',
                        table: specifications_table
                    },
                    fit_tab: {
                        active: false,
                        name: 'modelagens e medidas',
                        table: shaping_table
                    },
                    care_tab: {
                        active: false,
                        name: 'cuidados com a peça',
                        table: care_table
                    }
                }
            }

            setDescriptionTabs( obj )
        }
    }, [] )

    // update video
    const UpdateVideo = useCallback( ( { activeproduct } ) => {
        if( activeproduct && activeproduct.product ){
            let items = activeproduct.product.items

            if( items ){
                if( items.length > 0 ){
                    if( items[0].videos && items[0].videos.length > 0  ){
                        setVideo( {
                            visible: true,
                            videoUrl: items[0].videos[0]
                        }  )
                    }
                }
            }
        }
    }, [] )

    useEffect(() => {
        if( activeproduct !== null ){
            ActiveOverlay()

            UpdateDescriptionTabs( { activeproduct } )
            UpdateVideo( { activeproduct } )
        }

        if( activeproduct && activeproduct.product && activeproduct.product.specificationGroups && activeproduct.product.specificationGroups.length > 0 ) {
            activeproduct.product.specificationGroups.forEach(specification => {

                if ( specification && specification.name === "allSpecifications" && specification.specifications && specification.specifications.length > 0 ) {
                    specification.specifications.forEach(specification => {

                        if ( specification && specification.name === "Atributos" && specification.values && specification.values.length > 0 ) {
                            const arrayAttr = specification.values.reduce((acc, attr) => {
                                acc.push(attr)

                                return acc 
                            }, [])

                            setActiveProductAttrs(arrayAttr)
                        }

                    })
                }

            })
        }

    }, [activeproduct, ActiveOverlay, UpdateVideo, UpdateDescriptionTabs])


    // ADD TO CART HANDLER
    const AddToCartHandler = () => { 
        const the_product = activeproduct.product.items ? activeproduct.product.items[activeSize] : null

        if( the_product ){
            let productId = the_product.itemId ? the_product.itemId : null
            let sellerId = the_product.sellers ? the_product.sellers[0].sellerId : null
            
            if( productId && sellerId ){
                UpdateProductItem( productId, sellerId, 1 )

                if (!firstAccessCart) {
                    setFirstAccessCart('enable')
                } 
            }else{
                console.warn( 'AddToCartHandler não encontrou productId ou sellerId' )
            }
        }
    }

    // ACTIVE TAB HANDLER
    const ActiveTabHandler = ( tab ) => {
            let obj = {}

            Object.keys( descriptiontabs ).map( ( key ) => {
                obj = {
                    ...obj,
                    [key]: {
                        ...descriptiontabs[key],
                        active: false
                    }
                }

                return null
            } )

            obj = {
                ...obj,
                [tab]: {
                    ...descriptiontabs[tab],
                    active: true
                }
            }

            setDescriptionTabs( obj )
    }

    // PLAY VIDEO HANDLER
    const PlayVideoHandler = () => {
        setVideo(
            {
                ...video,
                visible: !video.visible,
            }
        )
    }

    // ACTIVE IMAGE HANDLER
    const ActiveImageHandler = ( index ) => {
        setActiveImg( index )
        setVideo(
            {
                ...video,
                visible: false,
            }
        )
    }

    // eslint-disable-next-line
    const attrProduct = [
        {
            name: 'Proteção UV',
            img: icoProtecaoUV
        },
        {
            name: 'Antimicrobiano',
            img: icoAntimicrobiano
        },
        ,
        {
            name: 'Antiodor',
            img: icoAntiodor
        },
        ,
        {
            name: 'Secagem Rápida',
            img: icoSecagemRapida
        },
        ,
        {
            name: 'Sem Passadoria',
            img: icoSemPassadoria
        },
        ,
        {
            name: 'Acabamento Sustentável',
            img: icoAcabacmentosustentavel
        },
        ,
        {
            name: 'Flexibilidade',
            img: icoFlexibilidade
        },
        ,
        {
            name: 'Liquid Repeller',
            img: icoLiquidRepeller
        }
    ]

    return(
        <AnimatePresence>
            {  activeproduct !== null && (
                <article className='fixed top-[6%] left-0 right-0 m-auto w-[77vw] rounded-[0.8vw]  overflow-hidden bg-white z-50'>
                    <div className='bg-alto px-[5vw] pt-[5vw] pb-[2.5vw] mb-[2.5vw] rounded-b-[2vw]'>
                        <button 
                            onClick={(e) => { e.preventDefault(); setActiveModalItem(null); DisableOverlay() } }
                            className='absolute top-[1vw] right-[1vw] w-[3.4vw] h-[3.4vw]'
                        > 
                            <svg className='absolute left-0 top-0 bottom-0 right-0 m-auto w-[1.6vw] h-[1.6vw]' viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <title> Fechar </title>
                                <path d="M41.1213 5.12132C42.2929 3.94975 42.2929 2.05025 41.1213 0.87868C39.9497 -0.292893 38.0503 -0.292893 36.8787 0.87868L21 16.7574L5.12132 0.87868C3.94975 -0.292893 2.05025 -0.292893 0.87868 0.87868C-0.292893 2.05025 -0.292893 3.94975 0.87868 5.12132L16.7574 21L0.87868 36.8787C-0.292893 38.0503 -0.292893 39.9497 0.87868 41.1213C2.05025 42.2929 3.94975 42.2929 5.12132 41.1213L21 25.2426L36.8787 41.1213C38.0503 42.2929 39.9497 42.2929 41.1213 41.1213C42.2929 39.9497 42.2929 38.0503 41.1213 36.8787L25.2426 21L41.1213 5.12132Z" fill="black"/>
                            </svg>
                        </button>
                        <header>
                            <span className='block font-RedHatLight text-[1vw] text-arsenic leading-none'> {activeproduct.product.brand} </span>
                            <h1 className='font-RedHatBold text-[1.6vw] text-arsenic uppercase line-clamp-1'> { activeproduct?.activeitem?.complementName || ''} </h1>
                            <div className='flex'> 
                                { activeproduct?.activeitem?.referenceId && activeproduct?.activeitem?.referenceId.length > 0 && (
                                    <span className='font-RedHatRegular leading-[1.2vw] text-[1.1vw] text-gray pr-[1vw] mr-[1vw]  border-r border-r-gray'> 
                                        { activeproduct.activeitem.referenceId[0].Value } 
                                    </span>
                                ) } 
                                { activeproduct?.activeitem?.sellers?.length > 0 && (
                                    <span className='font-RedHatRegular leading-[1.2vw] text-[1.1vw]  text-gray'>
                                        Vendido e entregue por { activeproduct.activeitem.sellers[0].sellerName }
                                    </span>
                                ) }
                            </div>
                        </header>

                        <div className='flex items-start justify-between mt-[3.6vw]'>
                            <div className='relative w-[9%] h-0 pb-[74%] flex flex-col scrollbar-none overflow-auto'>
                                <ul className='absolute left-0 top-0'>
                                    { activeproduct?.activeitem?.images?.length > 0 && activeproduct?.activeitem?.images?.map( (image, index) => ( 
                                        <li key={index} className='w-[100%] h-auto mb-[1vw]'>
                                            <button onClick={ (e) =>{ e.preventDefault(); ActiveImageHandler(index)} }>
                                                <img src={image.imageUrl} alt={image.imageText} className='w-[100%] h-auto object-cover rounded-[0.5vw]' />
                                            </button>
                                        </li>
                                    ) ) }
                                </ul>
                            </div>
                            <figure className='relative w-[calc(56%-2vw)] h-0 pb-[74%] px-[2vw] rounded-[1vw] overflow-hidden'>
                                { /* ACTIVE IMG*/  }
                                <img 
                                    className='absolute left-0 top-0 h-full object-cover w-full' 
                                    src={ activeproduct?.activeitem?.images[activeImg]?.imageUrl || '' } alt={ activeproduct?.activeitem?.images[activeImg]?.imageText || '' } 
                                />
                                { /* VIDEO PLAYER */  }
                                { activeproduct.activeitem?.videos?.length > 0 && video && video?.videoUrl && video?.visible && (     
                                    <video 
                                        autoPlay 
                                        muted 
                                        loop
                                        src={video.videoUrl}
                                        className='absolute inset-0 h-full w-full object-cover'
                                    />
                                )}

                                { /* VIDEO BUTTON */  }
                                { activeproduct.activeitem && activeproduct.activeitem.videos && activeproduct.activeitem.videos.length > 0 && video.videoUrl &&  (
                                    <button 
                                        className='absolute bottom-[0.8vw] right-[0.8vw] flex items-center justify-center w-[2.4vw] h-[2.4vw]  bg-arsenic rounded-full'
                                        onClick={ () => PlayVideoHandler() }
                                    >
                                        <svg className='w-[1.6vw] h-[1.6vw]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5V19L19 12L8 5Z" fill="#FFF"/>
                                        </svg>
                                    </button>
                                ) }
                            </figure>
                            <div className='w-[35%]'>
                                { activeproduct?.commertialOffer && (
                                    <div>
                                        { /* LISTPRICE */ }
                                        { activeproduct.commertialOffer.listPrice > activeproduct.commertialOffer.price && (
                                            <span className=' block text-arsenic text-[1vw] mr-[0.45vw] font-semibold relative line-through'> { activeproduct.commertialOffer.listPrice  } </span>
                                        ) }
                                        
                                        { /* PRICE */ }
                                        { activeproduct.commertialOffer.price && (
                                            <span className=' text-arsenic text-[1.6vw] mr-[0.45vw] font-semibold relative'> 
                                                { activeproduct.commertialOffer.price  }  
                                                { activeproduct.commertialOffer.installment && (
                                                    <small className='ml-[0.5vw] text-[1.1vw] text-arsenic'>
                                                        { activeproduct.commertialOffer.installment }
                                                    </small>
                                                ) }
                                            </span>
                                        )}

                                        { /* FREESHIPPING */ }
                                        { activeproduct.commertialOffer.freeShipping && (
                                            <p className=' border border-black text-center w-max rounded-[0.9vw] pt-[0.1vw] mt-[0.1vw] px-[0.6vw] h-max text-[0.8vw] font-bold bg-white'>FRETE GRÁTIS</p>
                                        )}
                                    </div>
                                ) }

                                { /* VARIANT "TAMANHO" */  }
                                { activeproduct.product && activeproduct.product.items && activeproduct.product.items[0].Tamanho && activeproduct.product.items[0].Tamanho[0] &&  (
                                    <>
                                        <span className='block font-RedHatRegular uppercase text-[0.8vw] mt-[2vw] mb-[0.5vw]'> selecione um tamanho: </span> 
                                        <ul className='flex flex-wrap gap-[0.6vw]'>
                                            { activeproduct.product.items.map((item, index) => (
                                                <li key={index} className={`relative flex ${(item.Tamanho[0].toLowerCase() === 'p') ? '!order-1' : 'order-4'} ${ (item.Tamanho[0].toLowerCase() === 'm') ? '!order-2' : 'order-4' } ${ (item.Tamanho[0].toLowerCase() === 'g') ? '!order-3' : 'order-4' }`}>
                                                    { item.sellers && item.sellers[0].commertialOffer &&  item.sellers[0].commertialOffer.AvailableQuantity > 0 && (
                                                        <button 
                                                            className={
                                                                `block leading-[2.2vw] w-[2.2vw] h-[2.2vw] text-[1vw] justify-center items-center  border rounded-full cursor-pointer uppercase focus:outline-none ${
                                                                    ( activeSize === index ) ? ' text-white bg-arsenic border-black' : 'bg-white border-[#BBBBBB]'
                                                                }
                                                                `
                                                            }
                                                            onClick={() => {setActiveSize( index ); setActiveModalItem( { ...activeproduct, activeitem: item } ) } }
                                                        >
                                                            {item.Tamanho && item.Tamanho.length && (
                                                                <>
                                                                    {item.Tamanho[0]} 
                                                                </>
                                                            )}
                                                        </button>
                                                    ) }
                                                </li>
                                            ))}
                                        </ul> 
                                        { activeSize !== null && activeproduct.product.items && (
                                            <span className='block font-RedHatRegular text-[0.7vw] my-[0.5vw]'> 
                                                tamanho selecionado: { activeproduct.product.items[activeSize].Tamanho[0] }
                                            </span> 
                                        ) }
                                    </>
                                ) }

                                { /* VARIANT "COR" */  }

                                { /* BUY BUTTON */  }
                                { activeproduct && activeproduct.activeitem && !productIds.includes(activeproduct.activeitem.itemId) ? 
                                    <button 
                                        onClick={() => { AddToCartHandler();  setActiveModalItem(null); DisableOverlay() }}
                                        className='bg-arsenic font-RedHatBold text-white text-[1.1vw] block py-[0.5vw] px-[2.2vw] mx-auto my-[3vw] rounded-[2vw] uppercase'>
                                        adicionar ao carrinho
                                    </button>
                                    :
                                    <button className='bg-pear font-RedHatBold gap-[0.7vw] text-black flex text-[1.1vw] justify-center items-center w-[17.78vw] text-center py-[0.5vw] px-[2.2vw] mx-auto my-[3vw] rounded-[2vw] uppercase'>
                                        ADICIONADO

                                        <svg className='w-[1.1vw] h-[1.1vw]' width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 1L6 11L1 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </button>
                                }


                                { /* SPECIFICATIONS */  }
                                <div>
                                    <ul className='grid grid-rows-6 grid-flow-col'>
                                        {activeProductAttrs && activeProductAttrs.length > 0 && attrProduct.map( (attr, index) => (
                                            activeProductAttrs.includes(attr.name) ?
                                                <li key={index} className='flex items-center mb-[1vw] gap-[0.3vw] font-RedHatRegular text-[1.11vw] text-arsenic' >
                                                    <img className='w-[2.7vw] h-[2.7vw]' alt={attr.name} src={attr.img}/>
                                                    {attr.name}
                                                </li>
                                            :
                                                null
                                        ) ) }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-[5vw] pt-[2.5vw]'>
                        <div className='flex mb-[4vw]'>
                            <div className='w-[27%]'>
                                <h3 className='font-RedHatBold text-[2.5vw] uppercase text-arsenic'>
                                    Faz mais <br />
                                    por <b className='text-darkgray'>vocÊ.</b>
                                </h3>
                            </div>
                            <div className='w-[73%]'>
                                <p className='font-RedHatRegular leading-[2] text-[1.3vw]'>
                                    Fez Sol? a proteção uv preserva a sua pele e o efeito <b className='uppercase'>dry</b> garante secagem rápida. à noite, a função antiodor te deixa tranquilo. e na natureza, a malha <b className='uppercase'>recicle +</b>, com acabamento a base de silicone reutilizado, deixa a peça sustentável.
                                </p>
                            </div>
                        </div>

                        { /* TAB NAVIGATION */  }
                        <div>
                            <ul className='flex items-center justify-between'>
                                { descriptiontabs && Object.keys(descriptiontabs).map( ( key, i) => (
                                    <li key={`tab-${i}`}> 
                                        <button 
                                            className={ `
                                                font-RedHatBold text-[1.19vw] uppercase border-b-[0.2vw]  focus:outline-none 
                                                ${descriptiontabs[key].active ? 'text-arsenic border-arsenic' : 'text-darkgray border-transparent'}
                                            ` }
                                            onClick={() => ActiveTabHandler( key )}
                                        >
                                            { descriptiontabs[key].name }
                                        </button>
                                    </li>
                                ) ) }
                            </ul>

                            <div>
                                { descriptiontabs && Object.keys(descriptiontabs).map( ( key, i) => {
                                    if(  descriptiontabs[key].active ){
                                        if( descriptiontabs[key].name === "Descrição" ){
                                            return (
                                                <div key={`desc-${i}`} className='mt-[2vw]'>
                                                    <p 
                                                        dangerouslySetInnerHTML={{__html: descriptiontabs[key].content }} 
                                                        className='font-RedHatRegular text-[1.3vw] leading-[1.5]'
                                                    />
                                                </div>
                                            )
                                        }else{
                                            return (
                                                <div key={`table-${i}`} className='mt-[2vw]'>
                                                    { descriptiontabs[key].table && (
                                                        <ul className='flex flex-col'>
                                                            { descriptiontabs[key].table.map( ( item, j ) => (
                                                                <li key={`item-${j}`} className='flex items-center py-[0.5vw] px-[0.6vw] border-b-[0.2vw] border-b-alto last:border-b-transparent odd:bg-alto'>
                                                                    <ul className='flex '>
                                                                        <li className='flex items-center w-full max-w-[10vw] min-w-[10vw]'>
                                                                            <p className='font-RedHatRegular mr-[2vw] text-[1.19vw]'> 
                                                                                { item.key }
                                                                            </p>
                                                                        </li>
                                                                        { item.value && (      
                                                                            <li className='flex'>
                                                                                <p className='font-RedHatRegular items-center flex-wrap gap-y-[0.3vw] flex gap-[1vw] text-[1.19vw]'> 
                                                                                    { item.value.map( ( item, y ) => (
                                                                                        <span key={`text-${y}`}>
                                                                                            { item }
                                                                                        </span>
                                                                                    ) ) }
                                                                                </p>
                                                                            </li>
                                                                        ) }
                                                                    </ul>
                                                                </li>
                                                            ) ) }
                                                        </ul>
                                                    ) }
                                                </div>
                                            )
                                        }
                                    }
                                    return null
                                } ) }
                            </div>
                        </div>

                    </div>
                </article>
            )  }
        </AnimatePresence>
    )

}