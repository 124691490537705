import { motion, AnimatePresence } from 'framer-motion';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


const xOffset = 100;
const carousel_animations = {
    enter: (direction) => ({
        x: direction > 0 ? xOffset : -xOffset,
        opacity: 0
    }),
    active: {
        x: 0,
        opacity: 1,
        transition: { delay: 0.2 }
    },
    exit: (direction) => ({
        x: direction > 0 ? -xOffset : xOffset,
        opacity: 0
    })
};

const card_animations = {
    visible: {
        scale: 1,
        opacity: 1,
        transition: { delay: 0.4, duration: 0.8 }
    },
    hidden: {
        scale: .95,
        opacity: 0,
    },
};


// function preloadImage ( src ) {
//     return new Promise((resolve, reject) => {
//         const img = new Image()
//         img.onload = function() {
//         resolve(img)
//         }
//         img.onerror = img.onabort = function() {
//         reject(src)
//         }
//         img.src = src
//     })
// }

function preloadImages ( banners ) {
    const imgs = []

    banners.forEach( (banner, index) => {
        const newimg = new Image()
        newimg.src = banner.imgurl
        imgs.push( newimg )
    } )
}

export default function BannerCarousel( { rawdata, delay } ) {

    const imgRef = createRef()
    const [banners, setBanners] = useState( null )
    const [active_banner, setActiveBanner] = useState(0)
    const [direction, setDirection] = useState(0)

    // Tratando a primeira carga
    useEffect( () => {

        if( rawdata ){

            // Precarregando as imagens
            preloadImages( rawdata )
            // Definindo o Array de banners
            setBanners( rawdata )
        }
    }, [rawdata])

    // Função que controla o carousel
    const NextBanner = useCallback(() => {
        setDirection(0)

        if( active_banner + 1 <  banners?.length ){
            setActiveBanner( active_banner + 1 )
        }else{
            setActiveBanner( 0 )
        }
        
    }, [active_banner, banners] )



    // Função que controla o carousel
    const PrevBanner = useCallback(() => {
        setDirection(1)

        if( active_banner - 1 >= 0 ){
            setActiveBanner( active_banner - 1 )
        }else{
            setActiveBanner( banners.length - 1 )
        }
    }, [active_banner, banners] )



    // Timer do carousel
    useEffect(() => {
        if( delay ){
            const redirect_timer = window.setInterval( () => NextBanner(), delay );

            return () => clearTimeout(redirect_timer);
        }

    }, [ NextBanner, delay ]); 


    return(
        <section className='relative mb-8 xl:mb-[3vw]'>
            { banners && (
                <>
                    <AnimatePresence 
                        custom={direction}
                    >
                        <div
                            className='block w-full h-[41.93vw] relative'
                        >

                            { banners[active_banner] && (
                                <motion.figure
                                    key={active_banner}
                                    variants={carousel_animations}
                                    initial="enter"
                                    animate="active"
                                    exit="exit"
                                    dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                                    custom={direction}
                                >
                                    <img ref={imgRef} className='absolute object-cover left-0 right-0 top-0 bottom-0 w-full rounded-b-[48px] lg:rounded-b-[2.5vh]' src={banners[active_banner].imgurl} alt={banners[active_banner].title} />
                                    <motion.figcaption 
                                        variants={card_animations}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                        className='absolute w-[33vw] left-12 lg:left-[2.5vh] -bottom-6 lg:-bottom-[1.75vh] p-6 lg:p-[1.5vh] bg-[url("assets/images/textures/glass.png")] rounded-2xl backdrop-blur-md'
                                    >
                                        { banners[active_banner].title && (
                                            <h2 className='text-3xl lg:text-[2.2vw] mb-4 lg:mb-[1.1vw] font-RedHatBold text-left text-pear uppercase'>{banners[active_banner].title}</h2>
                                        ) }

                                        { banners[active_banner].description && (
                                            <p className=' font-RedHatRegular lg:text-[1.2vw] mb-4 lg:mb-[2vw] text-white'> { banners[active_banner].description } </p>
                                        ) }

                                        { banners[active_banner].target && (
                                            <NavLink to={banners[active_banner].target} className='font-RedHatBold uppercase py-[.6vw] px-[2vw] rounded-full text-lg lg:text-[1vw]  text-white bg-black' href={banners[active_banner].target}> Confira </NavLink>
                                        )}
                                        
                                    </motion.figcaption>
                                </motion.figure>
                            ) }
                        </div>
                    </AnimatePresence>
                    <ul className='flex w-full items-center justify-center'>
                        { banners.map( ( banner, index ) => (
                            <li key={index}>
                                <button onClick={ () => setActiveBanner( index ) } className='min-w-7 mx-0.5 h-12 flex items-center justify-center'>
                                    { index === active_banner ? (
                                        <motion.i 
                                            variants={{
                                                growing:{
                                                    width: '5.8vw',
                                                }
                                            }}
                                            animate='growing'
                                            className='h-2 w-3 bg-pear rounded-full relative overflow-hidden'
                                        >
                                        </motion.i>
                                    ) : ( 
                                        <i className='h-2 w-3 bg-bouldergray rounded-full'></i>
                                    )}
                                </button>
                            </li>
                        ) ) }
                    </ul>
                    <button 
                        className='absolute top-0 bottom-0 right-4 m-auto z-10 w-6 h-6 xl:w-10 xl:h-10'
                        onClick={ () => { NextBanner() } }
                    > 
                        <svg className='absolute left-0 right-0 top-0 bottom-0 m-auto' viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.52513 1.52512L1.52513 1.52512C0.158291 2.89196 0.158291 5.10804 1.52513 6.47487L21.0503 26L1.52513 45.5251C0.158291 46.892 0.158291 49.108 1.52513 50.4749C2.89196 51.8417 5.10804 51.8417 6.47487 50.4749L28.4749 28.4749C29.8417 27.108 29.8417 24.892 28.4749 23.5251L6.47487 1.52512C5.10804 0.158292 2.89196 0.158292 1.52513 1.52512ZM23.5251 1.52512L23.8744 1.87442L23.5251 1.52512C22.1583 2.89196 22.1583 5.10804 23.5251 6.47487L43.0503 26L23.5251 45.5251C22.1583 46.892 22.1583 49.108 23.5251 50.4749C24.892 51.8417 27.108 51.8417 28.4749 50.4749L50.4749 28.4749C51.8417 27.108 51.8417 24.892 50.4749 23.5251L28.4749 1.52512L28.1295 1.87046L28.4749 1.52512C27.108 0.158292 24.892 0.158292 23.5251 1.52512Z" fill="#424242" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <button 
                        className='absolute top-0 bottom-0 left-4 m-auto z-10 w-6 h-6 xl:w-10 xl:h-10'
                        onClick={ () => { PrevBanner() } }
                    > 
                        <svg className='absolute left-0 right-0 top-0 bottom-0 m-auto' viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M50.4749 1.52512L50.4749 1.52512C51.8417 2.89196 51.8417 5.10804 50.4749 6.47487L30.9497 26L50.4749 45.5251C51.8417 46.892 51.8417 49.108 50.4749 50.4749C49.108 51.8417 46.892 51.8417 45.5251 50.4749L23.5251 28.4749C22.1583 27.108 22.1583 24.892 23.5251 23.5251L45.5251 1.52512C46.892 0.158292 49.108 0.158292 50.4749 1.52512ZM28.4749 1.52512L28.1256 1.87442L28.4749 1.52512C29.8417 2.89196 29.8417 5.10804 28.4749 6.47487L8.94975 26L28.4749 45.5251C29.8417 46.892 29.8417 49.108 28.4749 50.4749C27.108 51.8417 24.892 51.8417 23.5251 50.4749L1.52513 28.4749C0.158291 27.108 0.158291 24.892 1.52513 23.5251L23.5251 1.52512L23.8705 1.87046L23.5251 1.52512C24.892 0.158292 27.108 0.158292 28.4749 1.52512Z" fill="#424242" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </>
             ) }
        </section>
    )

}