import { ENV_CORSPROXY, ENV_VTEX_APPKEY, ENV_VTEX_APPTOKEN } from './envdata'

export const CreatFormOrder = async( retrys, delay ) => { 

    const OPTIONS = {
        method: 'GET',
        headers: {
            "accept": "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
            'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
        }
    }


    await new Promise( resolve => setTimeout( resolve, delay ? delay : 1000 ) )

    try{
        const response = await fetch( `${ENV_CORSPROXY}/api/checkout/pub/orderForm` , OPTIONS)
        const data = await response.json()
    
        return data
    }catch( errors ){
        if( retrys > 0 ){
            console.warn( `Request Error! trying again ${retrys}` )
            return CreatFormOrder( retrys - 1, 5000 )
        }else{
            if (errors.name === "AbortError") {
                console.warn("Operation timed out");
                return -1
            } else {
                console.warn( errors )
                return -1
            }
        }
    }
}



export const UpdateCartItem = async( productId, quantity, sellerId, formOrder ) => {

    const orderFormId = formOrder.orderFormId
    const bodycontent = {quantity: parseInt(quantity), seller: sellerId, id: productId}

    if( formOrder.items ){

        formOrder.items.forEach( ( item, i ) => {
            if( item.id === productId ){
                bodycontent.index  = i
            }
        } )

    }

    const OPTIONS = {
        method: 'PATCH',
        headers: {
            "accept": "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
            'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
        },
        body: JSON.stringify({orderItems: [bodycontent]})
    }

    try{
        const response = await fetch( `${ENV_CORSPROXY}/api/checkout/pub/orderForm/${orderFormId}/items`, OPTIONS)
        const data = await response.json()
        console.log( data )

        return data
    }catch( errors ){
        if (errors.name === "AbortError") {
            console.warn("Operation timed out");
            return -1
        } else {
            console.warn( errors )
            return -1
        }
    }
}



export const AddSellerData = async( seller, orderFormId ) => {

    const OPTIONS = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
            'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
        },
        body: JSON.stringify({utmSource: seller.replace(/\s/g, ''), utmCampaign: 'desconto_vendedor'})
    }

    try{
        const response = await fetch( `${ENV_CORSPROXY}/api/checkout/pub/orderForm/${orderFormId}/attachments/marketingData`, OPTIONS)
        const data = await response.json()

        return data
    }catch( errors ){
        if (errors.name === "AbortError") {
            console.warn("Operation timed out");
            return -1
        } else {
            console.warn( errors )
            return -1
        }
    }
}

export const RemoveSellerData = async( orderFormId ) => {

    const OPTIONS = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
            'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
        },
        body: JSON.stringify({utmSource: false, utmCampaign: 'desconto_vendedor'})
    }

    try{
        const response = await fetch( `${ENV_CORSPROXY}/api/checkout/pub/orderForm/${orderFormId}/attachments/marketingData`, OPTIONS)
        const data = await response.json()

        return data
    }catch( errors ){
        if (errors.name === "AbortError") {
            console.warn("Operation timed out");
            return -1
        } else {
            console.warn( errors )
            return -1
        }
    }
}



export const PostCouponData = async( coupon, orderFormId ) => {
    
        const OPTIONS = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
                'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
            },
            body: JSON.stringify({couponCode: coupon})
        }
    
        try{
            //https://aramisnova.vtexcommercestable.com.br/api/checkout/pub/orderForm/{orderFormId}/coupons
            const response = await fetch( `${ENV_CORSPROXY}/api/checkout/pub/orderForm/${orderFormId}/coupons`, OPTIONS)
            const data = await response.json()
    
            return data
        }catch( errors ){
            if (errors.name === "AbortError") {
                console.warn("Operation timed out");
                return -1
            } else {
                console.warn( errors )
                return -1
            }
        }
}