/* REACT */
import React from "react";


export default function PageCollection(){



    return (
        <section>
            <div className="w-full bg-arsenic flex rounded-b-[9.12vw] justify-center  overflow-hidden">
                <div className="w-1/2 h-full">
                    <div className="w-[36.87vw] m-auto">
                        <h2 className="text-6xl text-pear font-bold mt-20 mb-11 leading-[72px] lg:leading-[6.56vw] lg:mb-[4.15vw] lg:mt-[7.25vw] lg:text-[5.47vw]">COLEÇÃO INVERNO</h2>
                        <p className="text-2xl font-medium mb-24 leading-7 lg:leading-[2.3vw] lg:mb-[9.02vw] text-white lg:text-[2.01vw]">Galaxyrise globular star cluster and billions upon billions upon billions upon billions upon billions upon billions.</p>
                    </div>
                </div>
                <figure className="w-1/2 flex object-cover">
                    <img className='w-full h-full object-cover ' src={require('../assets/standby/banner-collection.png')} alt="imagem do banner"/> 
                </figure>
            </div>
            <main className="px-[3.69vw]">
                <div className="mt-[6.52vw] mb-[8.11vw]">
                    <p className="text-lg lg:leading-[2.1vw] lg:text-[1.64vw]">A very small stage in a vast cosmic arena Rig Veda two ghostly white figures in coveralls and helmets are softly dancing made in the interiors of collapsing stars citizens of distant epochs inconspicuous motes of rock and gas. With pretty stories for which there's little good evidence permanence of the stars of brilliant syntheses invent the universe venture the carbon in our apple pies. </p>
                </div>

                page-collection.js
            </main>
        </section>
    );
}