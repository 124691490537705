import { useState, useContext, createContext } from 'react'


const FaqContext = createContext("")
export default function FaqProvider({ children }){

    const [faqIsOpen, setFaqIsOpen] = useState(false)

    return (
        <FaqContext.Provider 
            value={{
                faqIsOpen,
                setFaqIsOpen,
            }}
        >
            {children}
        </FaqContext.Provider>
    )
}

export function UseFaqContext() {
    return useContext(FaqContext)
}