import React, { useState } from 'react';

import { UseOverlayContext } from '../context/overlay-context';
import { UseFrontEndContext } from '../context/frontend-context';
import { UseFaqContext } from '../context/faq-context';
import ReactMarkdown from 'react-markdown'

export default function Faq() {
    const [questionIsOpen, setQuestionIsOpen] = useState( null )
    
    // *---------------
    // * FRONTEND CONTEXT
    // *---------------
    const {
        faq
    } = UseFrontEndContext()

    // *---------------
    // * FAQ CONTEXT
    // *---------------
    const {
        faqIsOpen, setFaqIsOpen
    } = UseFaqContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        setOverlayZindex
    } = UseOverlayContext()

    const openQuestion = (index) => {
        if (questionIsOpen === index) {
            return setQuestionIsOpen(null)
        }

        setQuestionIsOpen(index)
    }

    return(
        <article className={`w-[79.76vw] relative ${faqIsOpen ? '' : 'hidden'} h-[111.21vw] overflow-auto px-[7.20vw] pt-[4.60vw] rounded-[1.09vw] bg-white`}>
            <h3 className='font-RedHatBold mb-[6.56vw] text-[4.38vw] text-arsenic text-center'>Dúvidas frequentes</h3>

            <button className='absolute top-[2.60vw] right-[2.60vw]' onClick={() => [setOverlayZindex(40), setFaqIsOpen(false)]}>
                <svg  className='w-[1.64vw]' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39 3L3 39" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M39 39L3 3" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>

            <ul className='flex flex-col gap-[2.92vw]'>
                {faq && faq.map((question, index) => 
                        <li key={index}>
                            <button onClick={() => openQuestion(index)} className={`px-[1.28vw] w-full h-[4.33vw] flex justify-between items-center ${index === questionIsOpen ? 'bg-pear' : 'bg-[#F0F0F0]'} shadow-md rounded-[0.455789vw] py-[1.05vw] `}>
                                <h4 className='text-[1.64vw] font-RedHatRegular'>{question.title}</h4>
                                <svg className='w-[1.14vw]' width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 15L14.5 2.5L27 15" stroke="#424242" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>

                            <div className={`mx-[1.64vw] flex flex-col gap-[1.5vw] ${index === questionIsOpen ? '' : 'hidden'} mt-[2.46vw]`}>
                                {question.content && (
                                    <ReactMarkdown>
                                        {question.content}
                                    </ReactMarkdown>
                                )}

                                {question.img && (
                                    <img alt={question.title || ''} src={question.img} />
                                )}
                            </div>
                        </li>
                    )
                }
            </ul>
        </article>
    )

}