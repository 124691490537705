import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
import { UseSearchContext } from '../context/search-context';
import { pathVariants } from '../animations/pathVariants';
import { UseCartContext } from '../context/cart-context';
import { UseOverlayContext } from '../context/overlay-context';
import { UseCartModalContext } from '../context/cart-modal-context';
import { UseProductModalContext } from '../context/product-modal-context';
import Keyboard from './keyboard';
import { UseFaqContext } from '../context/faq-context';

export default function SidebarArea( { children } ) {
    const [timerReset, setTimerReset] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        const intervalTimerReset = setInterval(() => {
            setTimerReset(timerReset + 1);  
        }, 1000);
        return () => {clearInterval(intervalTimerReset);}
    }, [timerReset])

    useEffect(() => {
        window.addEventListener('click', () => setTimerReset(0))
        if (timerReset/60 === 3) {
            navigate("/")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerReset])
    
    // *---------------
    // * CART MODAL CONTEXT
    // *---------------
    const { 
        cart_modal,
        ToggleCartModal
    } = UseCartModalContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        formOrder, setKeyboardInputReference, firstAccessCart, setFirstAccessCart, keyboardInputReference
    } = UseCartContext()

    // *---------------
    // * FAQ CONTEXT
    // *---------------
    const {
        setFaqIsOpen
    } = UseFaqContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const {
        ToggleOverlay, setOverlayZindex, ActiveOverlay
    } = UseOverlayContext()

    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        setSearchModal
    } = UseSearchContext()



    // *---------------
    // * PRODUCT MODAL CONTEXT
    // *---------------
    const { 
        activeproduct, 
    } = UseProductModalContext()

    const openCartHandle = () => { 
        ToggleCartModal()
        setKeyboardInputReference('')
        ActiveOverlay() 

        if (firstAccessCart === 'enable') {
            disableMessage(false)
        } 
    }

    const disableMessage = (toogle = true) => {
        setFirstAccessCart('disabled')

        if (toogle) {
            ToggleOverlay()
        } 
    }
    
    return(
        <motion.nav 
            variants={{ 
                hidden: {
                    x: children ? '25.5vw' : 0,
                },
                visible: {
                    x: '0',
                }
            }}
            initial="hidden"
            animate={ cart_modal ? 'visible' : 'hidden' }
            transition={{ type: "tween"  }}
            className={ `fixed flex top-[26vh] right-0 ${activeproduct === null ? 'z-50' : 'z-40 '}` }
        >
            <div className='w-[4.5vw]'> 
                <ul className=''>
                    {  children && (
                        <>
                            { /* ABRIR CARRINHO */ }
                            { !cart_modal && (
                                <li className='h-[13vw] px-[0.5vw] py-[1.2vw] mb-[1.2vw] relative last:mb-0 flex items-start justify-center  bg-pear lg:rounded-l-[1.2vw] rounded-l-xl '>
                                    {formOrder.items && (
                                            <motion.p  
                                                variants={{ 
                                                    hidden: {
                                                        scale: 0.5
                                                    },
                                                    visible: {
                                                        scale: 1
                                                    }
                                                }}
                                                initial="hidden"
                                                animate={formOrder.items.length < 1 ? 'hidden' : 'visible'}
                                                transition={{ type: "spring"  }}  
                                                className={`absolute ${formOrder.items.length < 1 ? 'hidden' : ''} right-[0.6vw] top-[0.6vw] flex items-center justify-center z-20 h-[1.5vw] w-[1.5vw] text-white text-[10px] lg:text-[1.1vw]  bg-arsenic rounded-full`}
                                            >
                                                {formOrder.items.length}
                                            </motion.p>
                                        )
                                    }
                                    
                                    <button onClick={ () => openCartHandle()} className='flex flex-col h-full items-center justify-center'>
                                        <span className='h-[2.3vw] w-[2.3vw] mb-[1vw] relative block'>
                                            <svg className='w-full absolute top-0 left-0 right-0 bottom-0 m-auto block'  viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <title> carrinho </title>
                                                <path d="M47.3603 45.2003H16.5261L8.99305 3.78201C8.90491 3.286 8.64617 2.8364 8.2616 2.51099C7.87702 2.18558 7.39079 2.00482 6.88703 2H2M14.5546 34.4059H48.4668C49.4765 34.409 50.455 34.0561 51.2303 33.4092C52.0056 32.7623 52.5281 31.8628 52.7059 30.8689L55.9999 12.8057H10.6396M24.678 50.5954C24.678 53.5777 22.2603 55.9954 19.278 55.9954C16.2956 55.9954 13.8779 53.5777 13.8779 50.5954C13.8779 47.613 16.2956 45.1953 19.278 45.1953C22.2603 45.1953 24.678 47.613 24.678 50.5954ZM52.7593 50.5952C52.7593 53.5775 50.3416 55.9952 47.3592 55.9952C44.3769 55.9952 41.9592 53.5775 41.9592 50.5952C41.9592 47.6128 44.3769 45.1951 47.3592 45.1951C50.3416 45.1951 52.7593 47.6128 52.7593 50.5952Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span className='block font-RedHatBold text-[1.5vw] vertical-lr rotate-180 uppercase'>Compras</span>
                                    </button>

                                    <div className={`absolute right-[8vw] transition-all ${firstAccessCart === 'enable' ? '' : 'hidden'} top-[-5vw] max-w-[28.62vw]`}>
                                        <h3 className='text-[2.8vw] text-pear mb-[1.60vw] font-RedHatRegular'>Este é o seu carrinho!</h3>

                                        <svg className='w-[29.8vw] h-[5vw]' width="662" height="108" viewBox="0 0 662 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <motion.path custom={0.3} variants={pathVariants} initial="hidden" animate={ firstAccessCart === 'enable' ? 'visible' : 'hidden' }  d="M631.894 46C639.501 54.3541 646.849 63.0566 654.749 71.14C655.7 72.1136 658.07 73.4014 658.525 74.8166C659.318 77.2831 654.207 80.9674 652.761 82.3685C645.197 89.7004 636.465 95.0224 631 104.13" stroke="#D8FF33" strokeWidth="6" strokeLinecap="round"/>
                                            <motion.path variants={pathVariants} initial="hidden" animate={ firstAccessCart === 'enable' ? 'visible' : 'hidden' }  d="M3 44.0439C22.9468 30.093 48.4804 24.8687 72.5778 20.1235C140.133 6.82089 211.914 -2.07847 280.903 6.20114C348.971 14.3701 406.167 48.899 471.885 63.8529C517.604 74.2564 562.318 76 609 76" stroke="#D8FF33" strokeWidth="6" strokeLinecap="round"/>
                                        </svg>

                                        <p className='text-[1.73vw] mb-[2.55vw] font-RedHatRegular mt-[1.23vw] text-white'>
                                            Clique no carrinho para visualizar todos os seus produtos e finalizar a compra. 
                                        </p>

                                        <button onClick={() => disableMessage()} className="text-[1.46vw] rounded-[2.28vw] font-RedHatRegular py-[0.3vw] px-[3.2vw] border-[0.136737vw] border-white text-white">
                                            ENTENDI
                                        </button>
                                    </div>
                                </li>
                            )}

                            { /* FECHAR CARRINHO */ }
                            { cart_modal && (
                                <li className='h-[13vw] px-[0.5vw] py-[1.2vw] mb-[1.2vw] last:mb-0 flex items-start justify-center  bg-pear lg:rounded-l-[1.2vw] rounded-l-xl '>
                                    <button onClick={ () => { openCartHandle() }} className='flex flex-col h-full items-center justify-center'>
                                        <span className='h-[2vw] w-[2vw] mb-[1vw] relative block'>
                                            <svg className='w-full absolute top-0 left-0 right-0 bottom-0 m-auto block' viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M39 3L21 21M21 21L3 39M21 21L39 39M21 21L3 3" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span className='block font-RedHatBold text-[1.5vw] vertical-lr rotate-180 uppercase'>FECHAR</span>
                                    </button>
                                </li>
                            ) }
                        </>
                    )}
                    <li className={`px-[0.5vw] py-[1.2vw] mb-[1.2vw] last:mb-0 flex items-center justify-center ${firstAccessCart === 'enable' ? 'hidden' : '' } transition-all duration-1000 bg-darkgray lg:rounded-l-[1.2vw] rounded-l-xl`}>
                        <NavLink className='h-[2.3vw] w-[2.3vw] relative block' to='/home'>
                            <svg className='w-full absolute top-0 left-0 right-0 bottom-0 m-auto block' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 58" fill="none">
                                <title> Home </title>
                                <path d="M35.378 53.6159V39.311C35.378 38.6787 35.1268 38.0723 34.6797 37.6252C34.2325 37.1781 33.6261 36.9269 32.9938 36.9269H23.4573C22.8249 36.9269 22.2185 37.1781 21.7714 37.6252C21.3243 38.0723 21.0731 38.6787 21.0731 39.311V53.6159C21.0731 54.2482 20.8219 54.8546 20.3748 55.3017C19.9277 55.7488 19.3213 56 18.689 56H4.38414C3.75183 56 3.14541 55.7488 2.6983 55.3017C2.25119 54.8546 2 54.2482 2 53.6159V26.0492C2.00534 25.7193 2.07664 25.3938 2.20971 25.0918C2.34279 24.7898 2.53493 24.5175 2.77484 24.2909L26.6162 2.62508C27.0557 2.22299 27.6299 2 28.2255 2C28.8212 2 29.3953 2.22299 29.8348 2.62508L53.6762 24.2909C53.9161 24.5175 54.1083 24.7898 54.2414 25.0918C54.3744 25.3938 54.4457 25.7193 54.4511 26.0492V53.6159C54.4511 54.2482 54.1999 54.8546 53.7528 55.3017C53.3057 55.7488 52.6992 56 52.0669 56H37.7621C37.1298 56 36.5234 55.7488 36.0763 55.3017C35.6291 54.8546 35.378 54.2482 35.378 53.6159Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </NavLink>
                    </li>
                    <li className={`px-[0.5vw] py-[1.2vw] mb-[1.2vw] last:mb-0 flex items-center justify-center ${firstAccessCart === 'enable' ? 'hidden' : '' } transition-all duration-1000 bg-darkgray lg:rounded-l-[1.2vw] rounded-l-xl`}>
                        <button className='h-[2.3vw] w-[2.3vw] relative block' onClick={() => [setOverlayZindex(50), setSearchModal(true)]}>
                            <svg className='w-full absolute top-0 left-0 right-0 bottom-0 m-auto block' fill="white" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30">
                                <title> Search </title>
                                <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/>
                            </svg>
                        </button>
                    </li>
                    <li className={`px-[0.5vw] py-[1.2vw] mb-[1.2vw] last:mb-0 flex items-center justify-center ${firstAccessCart === 'enable' ? 'hidden' : '' } transition-all duration-1000 bg-darkgray lg:rounded-l-[1.2vw] rounded-l-xl`}>
                        <button className='h-[2.3vw] w-[2.3vw] relative block' onClick={() => [setOverlayZindex(50), setFaqIsOpen(true)]}>
                            <svg className='w-full absolute top-0 left-0 right-0 bottom-0 m-auto block' viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <title>  Dúvidas </title>
                                <path d="M29 47C30.864 47 32.375 45.489 32.375 43.625C32.375 41.761 30.864 40.25 29 40.25C27.136 40.25 25.625 41.761 25.625 43.625C25.625 45.489 27.136 47 29 47Z" fill="white"/>
                                <path d="M30.9265 13.6897C29.011 13.3087 27.0254 13.5043 25.221 14.2517C23.4166 14.9991 21.8743 16.2648 20.7892 17.8887C19.7042 19.5127 19.125 21.4219 19.125 23.375C19.125 24.4796 20.0204 25.375 21.125 25.375C22.2296 25.375 23.125 24.4796 23.125 23.375C23.125 22.213 23.4696 21.0772 24.1151 20.111C24.7607 19.1449 25.6782 18.3919 26.7517 17.9472C27.8253 17.5025 29.0065 17.3862 30.1462 17.6129C31.2858 17.8396 32.3326 18.3991 33.1543 19.2208C33.9759 20.0424 34.5354 21.0892 34.7621 22.2288C34.9888 23.3685 34.8725 24.5498 34.4278 25.6233C33.9831 26.6968 33.2301 27.6143 32.264 28.2599C31.2978 28.9054 30.162 29.25 29 29.25C28.4696 29.25 27.9609 29.4607 27.5858 29.8358C27.2107 30.2109 27 30.7196 27 31.25V33.5C27 34.6046 27.8954 35.5 29 35.5C30.1046 35.5 31 34.6046 31 33.5V33.0454C32.2391 32.7891 33.4238 32.2957 34.4863 31.5858C36.1102 30.5007 37.3759 28.9584 38.1233 27.154C38.8707 25.3496 39.0663 23.3641 38.6853 21.4485C38.3042 19.5329 37.3637 17.7734 35.9827 16.3923C34.6016 15.0113 32.8421 14.0708 30.9265 13.6897Z" fill="white"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 29C0 12.9837 12.9837 0 29 0C45.0163 0 58 12.9837 58 29C58 45.0163 45.0163 58 29 58C12.9837 58 0 45.0163 0 29ZM29 4C15.1929 4 4 15.1929 4 29C4 42.8071 15.1929 54 29 54C42.8071 54 54 42.8071 54 29C54 15.1929 42.8071 4 29 4Z" fill="white"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            { children && (
                <div className='bg-pear flex pl-[1.2vw] py-[1.2vw] '>
                    <div className='bg-white w-[24.5vw] min-h-[30vh]'>
                        {children}
                    </div>
                </div>
            ) }

            {keyboardInputReference === 'seller' || keyboardInputReference === 'coupon' ? <Keyboard top='42vw' left='-65vw'/> : null}
        </motion.nav>
    )

}