import { useState, useContext, useEffect, useCallback, createContext } from 'react'
import { QueryMasterData } from '../shared/masterdata-service'

const FrontEndContext = createContext( "")
export default function FrontEndProvider({ children }){

    // *------------------* //
    // *-- BANNERS
    // *------------------* //
    const [ banners, _setBanners ] = useState([])

    // const dev_banners = [
    //     {
    //         img:'https://aramis-wp.s3.us-west-2.amazonaws.com/2022/08/demo-totem.jpg',
    //         title: 'coleção inverno',
    //         desc: 'Juntamos o conforto do esporte com a moda urbana para criar um novo lifestyle multidimensional, que vai além do que você conhece',
    //         // cta: 'confira',
    //         // target: '/home'
    //     },
    // ]

    const FetchBanners = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "TB", query: "?_fields=imgurl,title,target,description", controller, page: 0, perpage: 10, retrys: 3 } )
        if( data ){
            _setBanners( data )
        }
    }, [] )

    useEffect( () => {
        const controller = new AbortController()
        FetchBanners( { controler: controller } )
        
        return () => {
            controller.abort()
        }
    }, [FetchBanners] )


    // *------------------* //
    // *-- FAQ
    // *------------------* //
    const [ faq, _setFaq ] = useState([])
    const FetchFaq = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "TQ", query: "?_fields=title,content,img", controller, page: 0, perpage: 20, retrys: 3 } )
        if( data ){
            _setFaq( data )
        }
    }, [] )

    useEffect( () => {
        const controller = new AbortController()
        FetchFaq( { controler: controller } )
        
        return () => {
            controller.abort()
        }
    }, [FetchFaq] )


    // *------------------* //
    // *-- CATEGORY HIGHLIGHTS
    // *------------------* //
    const [ highlights, _setHighlights ] = useState([])
    const FetchHighlights = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "TI", query: "?_fields=title,imgurl,target", controller, page: 0, perpage: 5, retrys: 3 } )

        if( data ){
            _setHighlights( data )
        }
    }, [] )

    useEffect( () => {
        const controller = new AbortController()
        FetchHighlights( { controler: controller } )
        
        return () => {
            controller.abort()
        }
    }, [FetchHighlights] )



    // *------------------* //
    // *-- CATEGORY BANNERS
    // *------------------* //
    const [ _categoryBanners, _setCategoryBanners ] = useState([])
    const FetchCategoryBanners = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "CT", query: "?_fields=imgurl,idCategory", controller, page: 0, perpage: 1000, retrys: 3 } )
        if( data ){
            _setCategoryBanners( data )
        }
    }, [] )

    const getCategoryBanner = useCallback( ( id ) => {
        const banner = _categoryBanners.find( ( item ) => item.idCategory === id )
        return banner
    }, [_categoryBanners] )

    useEffect( () => {
        const controller = new AbortController()
        FetchCategoryBanners( { controler: controller } )
        
        return () => {
            controller.abort()
        }
    }, [FetchCategoryBanners] )
    


    // *------------------* //
    // *-- FOOTER BANNERS
    // *------------------* //
    const [ footerBanners, _setFooterBanners ] = useState([])
    const FetchFooterBanners = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "TF", query: "?_fields=imgurl,title,target", controller, page: 0, perpage: 10, retrys: 3 } )
        if( data ){
            _setFooterBanners( data )
        }
    }, [] )

    useEffect( () => {
        const controller = new AbortController()
        FetchFooterBanners( { controler: controller } )

        return () => {
            controller.abort()
        }
    }, [FetchFooterBanners] )



    // *------------------* //
    // *-- STANDBY VIDEOS
    // *------------------* //

    const [ standbyVideos, _setStandbyVideos ] = useState([])
    const FetchStandbyVideos = useCallback( async ( { controller } ) => {
        const data = await QueryMasterData( { acronym: "TS", query: "?_fields=title,videourl", controller, page: 0, perpage: 10, retrys: 3 } )
        if( data ){
            _setStandbyVideos( data )
        }
    }, [] )

    useEffect( () => {
        const controller = new AbortController()
        FetchStandbyVideos( { controler: controller } )

        return () => {
            controller.abort()
        }
    }, [FetchStandbyVideos] )

    return (
        <FrontEndContext.Provider 
            value={{
                banners,
                footerBanners,
                getCategoryBanner,
                standbyVideos,
                highlights,
                faq
            }}
        >
            {children}
        </FrontEndContext.Provider>
    )
}

export function UseFrontEndContext() {
    return useContext(FrontEndContext)
}