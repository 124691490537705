import React from 'react';
import { AnimatePresence } from "framer-motion"

export default function BannterCategory( { imgurl, alt } ) {

    return(
        <AnimatePresence>
            { imgurl && (
                <img className='block w-full h-[32vw] object-cover' src={imgurl} alt={alt ? alt : ''} />
            )}
        </AnimatePresence>
    )

}