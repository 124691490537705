const DEBUG = false

const GetCategory = (product) => {
    if( product?.categories?.length > 0 ){
        return product.categories[0]?.split('/')?.filter(item => item)?.pop()
    }else{
        return ''
    }
}

const GetPrice = (activeitem) => {
    if( activeitem?.sellers?.length > 0 ){
        return activeitem.sellers[0]?.commertialOffer?.Price
    }else{
        return 0
    }
}

export const DatalayerProductView = () => {
    if( window?.dataLayer ){
        window.dataLayer.push( {
            'event': "productView"
        })
    }
}

export const DatalayerProductDetails = ( product, activeitem, origin ) => {

    if( activeitem === null ){
        activeitem = product?.items?.length > 0 ? product.items[0] : null
    }

    if( product && activeitem ){
        const product_data = {
            'ecommerce': {
                'detail': {
                    'actionField': {'list': origin },
                    'products': [{
                        'name': activeitem.nameComplete,
                        'id': activeitem.itemId,
                        'price': GetPrice( activeitem ),
                        'brand': product.brand,
                        'category': GetCategory( product ),
                        'variant': activeitem?.Tamanho?.length > 0 ? activeitem.Tamanho[0] : '',
                    }]
                }
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'product or activeitem not found' );
    }

}

export const DatalayerProductLink = ( product, activeitem, origin, url, position ) => {

    console.log( product, activeitem, origin, url, position )

    if( !activeitem ){
        activeitem = product?.items?.length > 0 ? product.items[0] : null
    }

    if( !position ){
        position = 1
    }

    if( product && activeitem && url ){
        const product_data = {
            'event': 'productClick',
            'ecommerce': {
            'click': {
                'actionField': {'list': origin },
                'products': [{
                    'name': activeitem.nameComplete,
                    'id': activeitem.itemId,
                    'price': GetPrice( activeitem ),
                    'brand': product.brand,
                    'category': GetCategory( product ),
                    'variant': activeitem?.Tamanho?.length > 0 ? activeitem.Tamanho[0] : '',
                    'position': position
                }]
            }
            },
            'eventCallback': function() {
                document.location = url
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'product activeitem, url not found' );
    }

}

export const DatalayerProductLinkByName = ( url, origin, position ) => {

    if( !position ){
        position = 1
    }

    if( url && origin ){
        const product_data = {
            'event': 'productClick',
            'ecommerce': {
            'click': {
                'actionField': {'list': origin },
                'products': [{
                    'name': url
                }]
            }
            },
            'eventCallback': function() {
                document.location = url
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'url not defined' );
    }

}
        

export const DatalayerAddToCart = ( product, activeitem, quantity ) => {

    if( !quantity ){
        quantity = 1
    }

    if( product && activeitem ){
        const variant = activeitem?.Tamanho?.length > 0 ? activeitem.Tamanho[0] : null

        const product_data = {
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'BRL',
                'add': { 
                    'products': [{ 
                        'name': activeitem.nameComplete,
                        'id': activeitem.itemId,
                        'price': GetPrice( activeitem ),
                        'brand': product.brand,
                        'category': GetCategory( product ),
                        'quantity': quantity,
                        'variant': variant
                    }]
                }
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'product or activeitem not found' );
    }

}


export const DatalayerAddOrderItem = ( orderitem, quantity ) => {

    if( !quantity ){
        quantity = 1
    }

    if( orderitem ){
        const price = orderitem?.sellingPrice ? orderitem.sellingPrice/100 : orderitem?.price ? orderitem.price/100 : 0
        const variant = orderitem?.skuSpecifications?.length > 0 ? orderitem.skuSpecifications[0] : null

        const product_data = {
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'BRL',
                'add': {
                    'products': [{
                        'name': orderitem?.name,
                        'id': orderitem?.id,
                        'price': price.toString(),
                        'brand': orderitem?.additionalInfo?.brandName,
                        'category': orderitem?.productCategories?.length > 0 ?  orderitem.productCategories[ orderitem.productCategories.length - 1 ] : '',
                        'variant': variant ? variant.fieldValues[0] : '',
                        'quantity': quantity
                    }]
                }
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'orderitem not found' );
    }
}


export const DatalayerRemoveOrderItem = ( orderitem, quantity ) => {

    if( !quantity ){
        quantity = 1
    }

    if( orderitem ){
        const price = orderitem?.sellingPrice ? orderitem.sellingPrice/100 : orderitem?.price ? orderitem.price/100 : 0
        const variant = orderitem?.skuSpecifications?.length > 0 ? orderitem.skuSpecifications[0] : null
        const category = orderitem?.productCategories ? Object.keys( orderitem?.productCategories ).pop() : ''

        const product_data = {
            'event': 'removeFromCart',
            'ecommerce': {
                'currencyCode': 'BRL',
                'add': {
                    'products': [{
                        'name': orderitem?.name,
                        'id': orderitem?.id,
                        'price': price.toString(),
                        'brand': orderitem?.additionalInfo?.brandName,
                        'category': orderitem?.productCategories ? orderitem.productCategories[category] : '',
                        'variant': variant ? variant.fieldValues[0] : '',
                        'quantity': quantity
                    }]
                }
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'orderitem not found' );
    }
}

export const DatalayerCheckoutButton = ( order ) => {

    console.log( order )

    if( order?.items?.length > 0 ){
        let products = []

        order.items.forEach( item => {
            const price = item?.sellingPrice ? item.sellingPrice/100 : item?.price ? item.price/100 : 0
            const variant = order?.skuSpecifications?.length > 0 ? order.skuSpecifications[0] : null
            const category = item?.productCategories ? Object.keys( item?.productCategories ).pop() : ''

            products.push({
                'name': item?.name,
                'id': item?.id,
                'price': price,
                'brand': item?.additionalInfo?.brandName,
                'category': item?.productCategories ? item.productCategories[category] : '',
                'variant': variant ? variant.fieldValues[0] : '',
                'quantity': item?.quantity
            })
        } )

        const product_data = {
            'event': 'checkout',
            'ecommerce': {
            'checkout': {
                'actionField': { 'step': 1 },
                'products': products
                }
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }
    }else{
        console.log( 'datalayer.push', 'product or activeitem not found' );
    }

}


export const DatalayerClear = () => {
    if( window?.dataLayer ){
        window.dataLayer.push({ ecommerce: null });
    }
}

export const DatalayerViewItems = ( products ) => {

    if( products ){
        let filtered_products = []

        products.forEach( ( product, index ) => {
            const item = product.items[0]
            const variant = item?.variations?.length > 0 ? item.variations[0]?.values?.pop() : null
            const category = product?.categories?.length > 0 ? product?.categories[0] : ''

            filtered_products.push({
                'name': item?.name,
                'id': item?.itemId,
                'price': GetPrice( item ),
                'brand': product?.brand,
                'category': category,
                'variant': variant,
                'index' : index
            })
        } )

        const product_data = {
            'event': 'viewItems',
            'ecommerce': {
                'currencyCode': 'BRL',
                'impressions': filtered_products
            }
        }

        // only send data in production
        if( !DEBUG && window?.dataLayer ){
            // window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push( product_data )
        }else{
            console.log( 'datalayer.push', product_data );
        }

    }
}

export const DatalayerSimpleEvent = ( event ) => {
    if( window?.dataLayer ){
        window.dataLayer.push( {
            'event': event
        })
    }
}


export const DatalayerPageView = () => {
    if( window?.dataLayer ){
        window.dataLayer.push( {
            'event': 'page_view',
            'page_location' : window.location.hash.replace( /#/, '' ),
            'page_title' : 'Categoria ' + window.location.hash.replace( /#/, '' ).replace( /\//g, ' - ' )
        })
    }
}