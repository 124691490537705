import React from 'react';
import { UseCartContext } from '../context/cart-context';

export default function InputCartSeller() {

    // *---------------
    // * STATES
    // *---------------

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        seller
    } = UseCartContext()

    const { 
        setSeller
    } = UseCartContext()

    const { 
        HandlerSubmit
    } = UseCartContext()

    const { 
        openKeyboard
    } = UseCartContext()

    const { 
        cupomName
    } = UseCartContext()

    const { 
        setName
    } = UseCartContext()

    const { 
        error
    } = UseCartContext()

    // *---------------
    // * FUNCTIONS
    // *---------------
    const HandlerRemove = (e) => {
        e.preventDefault()
        setName( '' )
        setSeller( '' )
    }


    return(
        <div className='relative w-full flex justify-center pb-[2vw]'>
            <form 
                className=' flex gap-[0.5vw] items-end justify-between'
                onSubmit={ (e) => { HandlerSubmit(e)} }
            >
                <label className='relative'>
                    <span className={`py-[0.5vw] mb-[2vw] font-RedHatBold text-[1vw] uppercase `}>Código do vendedor </span>
                    <input 
                        className={`font-RedHatBold text-[1vw] px-[1vw] leading-[2vw] w-full py-[0.5vw] border-b-2 border-gray-black focus:outline-none ${ error.length > 0 ? 'bg-red-200' : '' }`}
                        type="text" 
                        onClick={() => openKeyboard('seller')}
                        value={seller}
                        onChange={ (e) => setSeller( e.target.value ) }
                        placeholder='Digite o código' 
                    />
                    { cupomName.length > 0 && ( 
                        <span className='absolute w-full rounded-full left-0 bottom-2 px-[1vw] py-[0.5vw] right-[1vw] text-[1vw] font-RedHatBold text-gray-black bg-pear line-clamp-1'>
                            { seller } - { cupomName }
                        </span>
                    ) }
                </label>
                {  cupomName.length === 0 && (
                    <input className='text-[1vw] leading-[3vw] w-[5vw] h-[3vw] text-center font-RedHatBold uppercase bg-arsenic text-white rounded-full px-[1vw] '  type="submit" value="OK" />
                ) }
                { cupomName.length > 0 && (
                    <button 
                        className='relative text-[1vw] leading-[2vw] w-[5vw] h-[3vw] text-center font-RedHatBold uppercase bg-bouldergray text-white rounded-full px-[1vw] py-[0.5vw] '
                        onClick={ (e) => { HandlerRemove(e) } }
                    >
                        <svg className='absolute left-0 top-0 bottom-0 right-0 m-auto h-[1.2vw] w-[1.2vw]' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46 2L24 24M24 24L2 46M24 24L46 46M24 24L2 2" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                )}

            </form>
            { error.length > 0 && (
                <p className='absolute bottom-0 left-0 right-0 text-[0.8vw] text-red-500 font-RedHatBold py-[0.5vw]'>{error}</p>
            )}
        </div>
    )

}