import React, { useState, useContext } from 'react'


const OverlayContext = React.createContext()
export default function OverlayProvider({ children }){
    const [ overlay, _setOverlay ] = useState( false )
    const [ qrcode, setQrcode ] = useState( false )
    const [ overlayZindex, setOverlayZindex ] = useState( 40 )

    const ToggleOverlay = () => {
        _setOverlay( !overlay )
    }

    const ToggleQrcode = () => {
        setQrcode( !qrcode )
    }

    const disabledQrcode = () => {
        setQrcode( false )
    }

    const ActiveOverlay = () => {
        _setOverlay( true )
    }

    const DisableOverlay = () => {
        _setOverlay( false )
    }

    return (
        <OverlayContext.Provider 
            value={{
                overlay,
                ToggleOverlay,
                ActiveOverlay,
                DisableOverlay,
                ToggleQrcode,
                overlayZindex,
                setOverlayZindex,
                disabledQrcode,
                qrcode,
                setQrcode,
            }}
        >
            {children}
        </OverlayContext.Provider>
    )
}
export function UseOverlayContext() {
  return useContext(OverlayContext)
}