export const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0
    },
    visible: i => ({
        opacity: 1,
        pathLength: 1,
        transition: {
            duration: 2,
            ease: "easeInOut",
            delay: i,
        }
    })
  } 