import React from 'react';

export default function BackgroundSection( { foreigner_color, background_color, children, className, nopad, oversized } ) {


    return(
        <section 
            className={ `relative flex flex-col ${className}` }
            style={{ backgroundColor: background_color ? background_color : '#424242' } }
        >
            <div className={`flex flex-col relative z-[1] flex-1  ${!nopad ? 'px-12 lg:px-[4vw]': ''}`}>
                {children}
            </div>
            <div 
                className={ `${ !oversized ?  'h-12 lg:h-[4vw] ' : 'h-24 lg:h-[8vw]'}  w-full flex justify-between mt-auto ${ nopad ? ( !oversized ? '-mt-12 lg:mt-[-4vw]' : '-mt-24 lg:mt-[-8vw]' ) : '' }` }
                style={{color: foreigner_color ? foreigner_color : '#FFF'}}
            >
                <svg className={`z-[2] w-auto ${ !oversized ?  'h-12 lg:h-[4vw] ' : 'h-24 lg:h-[8vw]'} `}  xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                    <path d="M0 0V100H100C44.7715 100 0 55.2285 0 0Z" fill="currentColor"/>
                </svg>
                <svg className={`z-[2] w-auto ${ !oversized ?  'h-12 lg:h-[4vw] ' : 'h-24 lg:h-[8vw]'} `} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
                    <path d="M100 0V100H0C55.2285 100 100 55.2285 100 0Z" fill="currentColor"/>
                </svg>
            </div>
        </section>
    )

}