

export const ENV_VTEX_APPKEY = 'vtexappkey-aramismenswear-HXYGGF'
export const ENV_VTEX_APPTOKEN = 'TMGOHZAUSUYVGIXZHAFAUGXDPPWTEHRKMCKEISZDYIYMMCCITFMBCMAJPFPKYUGPTYAOERUQNHONJKZIHZKSZTJFHEXXBRIOJEGWGVIRLNCLUYRLNNFDJPLLUNVCYNOB'

// https://aramisnova.vtexcommercestable.com.br
export const ENV_CORSPROXY = 'https://www.aramisway.com.br/widdle/commercestable'
/*

https://www.aramisway.com.br/widdle/myvtex

https://www.aramisway.com.br/widdle/commercestable
*/

// https://aramisnova.myvtex.com
export const ENV_CORSNOVAPROXY = 'https://www.aramisway.com.br/widdle/myvtex'