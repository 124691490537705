import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
import BackgroundVideo from '../components/background-video';
import { UseCartContext } from "../context/cart-context";
import { UseFrontEndContext } from "../context/frontend-context";
import { UseSearchContext } from "../context/search-context";
import { UseFilterContext } from "../context/filter-context";
import { UseCartModalContext } from "../context/cart-modal-context";
import { UseFaqContext } from "../context/faq-context";
import { UseOverlayContext } from "../context/overlay-context";
export default function PageStandBy(){
    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        GetFormOrder, setFirstAccessCart, setProdutsIds, setKeyboardInputReference
    } = UseCartContext()

    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        setSearchModal
    } = UseSearchContext()

    // *---------------
    // * FRONTEND CONTEXT
    // *---------------   
    const {
        standbyVideos
    } = UseFrontEndContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        DisableOverlay, setOverlayZindex, disabledQrcode
    } = UseOverlayContext()

    // *---------------
    // * FILTER CONTEXT DATA
    // *---------------
    const {
        HideFilterModal
    } = UseFilterContext()

    // *---------------
    // * CART MODAL CONTEXT DATA
    // *---------------
    const {
        DisableCartModal
    } = UseCartModalContext()

    // *---------------
    // * FAQ CONTEXT
    // *---------------
    const {
        setFaqIsOpen
    } = UseFaqContext()

    const [VideoUrl, setVideoUrl] = useState(null);
    const [stop, setStop] = useState(false)
    const navigate = useNavigate();

    const resetAll = useCallback(async () => {
        if (!stop) {
            setStop(false)
            await GetFormOrder()
            await setProdutsIds( [] )
            await DisableOverlay()
            await HideFilterModal() 
            await DisableCartModal() 
            await setKeyboardInputReference('') 
            await setFaqIsOpen(false)
            await setSearchModal(false)
            await setFirstAccessCart(false)
            await setOverlayZindex(40) 
            await disabledQrcode()
            setStop(true)
        }
    }, [GetFormOrder, setProdutsIds, DisableOverlay, HideFilterModal, DisableCartModal, setKeyboardInputReference, setFaqIsOpen, setSearchModal, setFirstAccessCart, setOverlayZindex, disabledQrcode, stop])

    useEffect(() => {
        resetAll()
    }, [resetAll])

    useEffect( () => {
        if (standbyVideos) {
            if( standbyVideos.length > 0 ){
                const target =  Math.floor( Math.random() * standbyVideos.length )
    
                setVideoUrl( standbyVideos[target].videourl )
            }
        }
    }, [standbyVideos])

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='overflow-hidden'
            onClick={ () => { navigate("/home") }}
        >
            { VideoUrl && (
                <BackgroundVideo video={ VideoUrl } overlay={ true }/>
            ) }
        </ motion.div>
    );
}

const containerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.5,
        borderRadius: '100%'
    },
    visible: {
        opacity: 1,
        scale: 1,
        borderRadius: '0%',
        transition: {
            delay: .6,
            duration: .6
        }
    },
    exit: {
        opacity: 0,
        scale: 0,
        borderRadius: '100%',
        transition: {
            ease: 'easeInOut'
        }
    }
}