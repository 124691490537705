import React, { useState, useContext } from 'react'


const NavigationContext = React.createContext()
export default function NavigationProvider({ children }){

    // SIDEBAR MODAL
    const [ activeTab, setActiveTab ] = useState( null )

    return (
        <NavigationContext.Provider 
            value={{
                activeTab,
                setActiveTab,
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export function UseNavigationContext() {
    return useContext(NavigationContext)
}