import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseNavigationContext } from '../context/navigation-context';


export default function Navigation( { title, items, navigateonclick } ) {

    const navigate = useNavigate();

    const refitems = useRef(null)

    const { 
        activeTab,
        setActiveTab
    } = UseNavigationContext()


    const clickhandler = useCallback((id) => { 
        if( navigateonclick ){
            navigate( `/category/${id}` ) 
        }else{
            setActiveTab( id )
        }
    }, [navigateonclick, setActiveTab, navigate])
    
    const sectionclickhandler = useCallback((e) => {
        e.preventDefault()

        const the_parent = refitems.current.parentNode
        const actual_position = the_parent.scrollLeft
        const ul_width = refitems.current.offsetWidth
        let gap = 0
        let target_pos = 0 

        const styles = window.getComputedStyle( refitems.current )
        if( styles.gap ){
            gap = Math.floor( parseFloat( styles.gap.replace( 'px', '' ) ) )
        }
   
        // Descobrindo o proximo item que deve ser exibido
        for (let i = 1; i < refitems.current.querySelectorAll('li').length; i++) {
            const li = refitems.current.querySelectorAll('li')[i];
            
            if( ( li.offsetLeft - gap ) > actual_position ){
                target_pos = li.offsetLeft - gap
                break;
            }
        }

        // Se o proximo scroll bater no final da UL, volta para o inicio
        if( ( the_parent.offsetWidth + actual_position + 3 * gap ) >= ul_width ){
            target_pos = 0
        }

        the_parent.scroll({
            left: target_pos,
            top: 0,
            behavior: 'smooth'
        })

    }, [])

    return(
        <nav className='py-9 lg:py-[2.5vw] flex items-center justify-between relative pr-[4vw]'>
            { title && (
                <h2 className='text-2xl lg:text-[1.6vw] font-RedHatBold text-darkgray uppercase pr-[1.2vw]'> { title } </h2>
            ) }
            { items && ( 
                <>
                    <div className='relative after:absolute after:top-0 after:bottom-0 after:right-0  after:black after:w-[5vw] after:block after:bg-gradient-to-l after:from-white w-[calc(100%-4vw)] '>
                        <div className='relative h-[4.5vw] w-full overflow-auto scrollbar-none'>
                            <ul ref={refitems} className='absolute left-0 top-0 bottom-0 flex w-fit items-center flex-1 gap-[2vw] px-4 lg:px-[1.2vw] justify-between'>
                                { items.length > 0 && items.map( ( item, index ) => (
                                    <li className={`last:mr-[5vw] ${ activeTab === ( item.id ) ? 'order-1 last:mr-0' : 'order-2' }`} key={ index }>
                                        <button 
                                            onClick={ (e) => { e.preventDefault(); clickhandler( item.id ); }}
                                            className={
                                                `font-RedHatBold text-[12px] lg:text-[1.05vw] uppercase border-b-2 transition-colors duration-300 whitespace-nowrap
                                                    ${ activeTab === ( item.id )
                                                        ? 'text-arsenic border-b-arsenic'  
                                                        : 'text-darkgray border-none' 
                                                    }`
                                            }
                                        >
                                            {item.name}
                                        </button>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    </div>
                    <button className='absolute right-[0.75vw] top-0 bottom-0 m-auto h-[2.5vw] w-[2.5vw] border-2 bg-pear border-black rounded-full block' onClick={ (e) => sectionclickhandler(e) }> 
                        <svg className='absolute left-0 right-0 top-0 bottom-0 m-auto w-[1vw] h-[1vw]' viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title> Avançar </title>
                            <path d="M1 1L14 11.5L1 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </>
            ) }
        </nav>
    )

}