
import React from "react"
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';

const animecontainer = {
    begin: { opacity: 1 },
    end: {
        opacity: 1,
        transition: {
            staggerChildren: .25
        }
    }
};

const animetext = {
    begin: {
        opacity: 0,
        transition: {
            delay: .5, duration: 1.4
        }
    },
    end: {
        opacity: 1,
        transition: {
            delay: .5, duration: 1.4
        }
    }
}

const animebutton = {
    begin: {
        opacity: 0,
        transition: {
            delay: 1.4, duration: 1.2
        }
    },
    end: {
        opacity: 1,
        transition: {
            delay: 1.4, duration: 1.2
        }
    }
}

const animeline = {
    begin: {
        opacity: 0,
        pathLength: 0,
        transition: {
            duration: 1.4, type: "spring", stiffness: 30, damping: 25
        }
    },
    end: {
        opacity: 1,
        pathLength: 1,
        transition: {
            duration: 1.4, type: "spring", stiffness: 30, damping: 25
        }
    }
}

const animebg = {
    begin: {
        opacity: 0,
        transition: {
            duration: 1.4, type: "spring", stiffness: 30, damping: 25
        }
    },
    end: {
        opacity: 1,
        transition: {
            duration: 1.4, type: "spring", stiffness: 30, damping: 25
        }
    }
}

export default function StandByOverlay() {

    return(
        <div className="absolute left-0 top-0 right-0">
            <div 
                
            >
                <svg 
                    className='w-full max-h-[50vh] relative z-20' 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 2194 1619" 
                    fill="none"
                >
                     <motion.g 
                        variants={animecontainer}
                        initial='begin'
                        animate='end'
                        exit='begin'
                        className='stroke-[7vw]' 
                        style={{strokeWidth: 'clamp(110px, 5vw, 130px)'}}
                    >
                        <motion.path 
                            variants={ animeline }
                            d="M153 141V326.359C153 508.967 8.38796 657 -170 657C-348.388 657 -493 508.967 -493 326.359V141" 
                            stroke="#D7FF35" 
                        />
                        <motion.path 
                            variants={ animeline }
                            d="M1335 550C1540.45 550 1707 411.432 1707 240.5C1707 69.5679 1540.45 -69 1335 -69" 
                            stroke="#D7FF35" 
                        />
                        <motion.path 
                            variants={ animeline }
                            d="M2010 828V1619" 
                            stroke="#D7FF35" 
                        />
                     </motion.g>

                     <motion.g 
                        variants={animecontainer}
                        initial='begin'
                        animate='end'
                        exit='begin'
                        className='stroke-[7vw]' 
                        style={{strokeWidth: 'clamp(110px, 5vw, 130px)'}}
                    >
                        <motion.path 
                            variants={ animeline }
                            d="M2656 -60V125.359C2656 307.967 2511.39 456 2333 456C2154.61 456 2010 307.967 2010 125.359V-60" 
                            stroke="#C2C2C2" />
                        <motion.path 
                            variants={ animeline }
                            d="M425 1341C425 1118.98 275.015 939 90 939C-95.0154 939 -245 1118.98 -245 1341" 
                            stroke="#C2C2C2" />
                        <motion.path 
                            variants={ animeline }
                            d="M664 -360V431"
                            stroke="#C2C2C2" />
                    </motion.g>

                    { false && (
                        <motion.g
                            variants={animetext}
                            initial='begin'
                            animate='end'
                            exit='begin'
                        >
                            <path d="M288 654V514H317V654H288Z" fill="#333333"/>
                            <path d="M337.492 654V514H365.092L434.692 604.4V514H461.892V654H436.292L364.892 560.4V654H337.492Z" fill="#333333"/>
                            <path d="M482.297 654V514H590.497V539.2H511.297V572.2H563.897V596.6H511.297V654H482.297Z" fill="#333333"/>
                            <path d="M605.031 654V514H634.031V654H605.031Z" fill="#333333"/>
                            <path d="M654.523 654V514H682.123L751.723 604.4V514H778.923V654H753.323L681.923 560.4V654H654.523Z" fill="#333333"/>
                            <path d="M799.328 654V514H828.328V654H799.328Z" fill="#333333"/>
                            <path d="M887.02 654V539.6H839.82V514H963.02V539.6H916.02V654H887.02Z" fill="#333333"/>
                            <path d="M935.303 654L992.903 514H1026.3L1083.3 654H1051.9L1037.7 617.2H979.903L965.503 654H935.303ZM988.903 594H1028.9L1008.9 542.4L988.903 594Z" fill="#333333"/>
                            <path d="M1143.12 656C1131.78 656 1120.72 653.933 1109.92 649.8C1099.12 645.533 1089.52 639.533 1081.12 631.8L1097.72 611C1105.85 618.2 1113.65 623.4 1121.12 626.6C1128.72 629.8 1136.72 631.4 1145.12 631.4C1150.98 631.4 1156.05 630.733 1160.32 629.4C1164.58 627.933 1167.85 625.867 1170.12 623.2C1172.52 620.533 1173.72 617.4 1173.72 613.8C1173.72 609 1171.98 605.333 1168.52 602.8C1165.05 600.133 1159.12 598.067 1150.72 596.6L1122.92 591.8C1111.05 589.8 1101.98 585.6 1095.72 579.2C1089.58 572.8 1086.52 564.533 1086.52 554.4C1086.52 545.733 1088.72 538.2 1093.12 531.8C1097.65 525.4 1103.98 520.533 1112.12 517.2C1120.38 513.733 1130.18 512 1141.52 512C1151.78 512 1161.98 513.733 1172.12 517.2C1182.38 520.667 1191.45 525.467 1199.32 531.6L1183.72 553.2C1169.05 542 1154.32 536.4 1139.52 536.4C1134.32 536.4 1129.78 537.067 1125.92 538.4C1122.05 539.733 1119.05 541.6 1116.92 544C1114.92 546.267 1113.92 549 1113.92 552.2C1113.92 556.467 1115.45 559.8 1118.52 562.2C1121.58 564.467 1126.72 566.2 1133.92 567.4L1160.32 571.8C1174.32 574.067 1184.78 578.533 1191.72 585.2C1198.65 591.733 1202.12 600.4 1202.12 611.2C1202.12 620.267 1199.72 628.2 1194.92 635C1190.12 641.667 1183.25 646.867 1174.32 650.6C1165.52 654.2 1155.12 656 1143.12 656Z" fill="#333333"/>
                            <path d="M288 855V715H358.2C367.4 715 375.467 716.867 382.4 720.6C389.467 724.333 395 729.533 399 736.2C403 742.733 405 750.333 405 759C405 767.267 403 774.667 399 781.2C395 787.733 389.467 792.867 382.4 796.6C375.333 800.333 367.267 802.2 358.2 802.2H317V855H288ZM317 778.2H355C361.267 778.2 366.267 776.467 370 773C373.867 769.533 375.8 764.933 375.8 759.2C375.8 753.333 373.867 748.667 370 745.2C366.267 741.733 361.267 740 355 740H317V778.2Z" fill="#333333"/>
                            <path d="M487.733 857.2C477.333 857.2 467.666 855.4 458.733 851.8C449.799 848.067 441.933 842.933 435.133 836.4C428.466 829.733 423.199 822.067 419.333 813.4C415.599 804.6 413.733 795.133 413.733 785C413.733 774.867 415.599 765.467 419.333 756.8C423.199 748 428.466 740.333 435.133 733.8C441.933 727.133 449.799 722 458.733 718.4C467.666 714.667 477.333 712.8 487.733 712.8C498.133 712.8 507.799 714.667 516.733 718.4C525.799 722 533.666 727.133 540.333 733.8C547.133 740.333 552.399 748 556.133 756.8C559.866 765.467 561.733 774.867 561.733 785C561.733 795.133 559.866 804.6 556.133 813.4C552.399 822.067 547.133 829.733 540.333 836.4C533.666 842.933 525.799 848.067 516.733 851.8C507.799 855.4 498.133 857.2 487.733 857.2ZM487.733 830.6C494.133 830.6 499.999 829.467 505.333 827.2C510.666 824.8 515.333 821.6 519.333 817.6C523.333 813.467 526.466 808.6 528.733 803C530.999 797.4 532.133 791.4 532.133 785C532.133 778.467 530.999 772.467 528.733 767C526.466 761.4 523.333 756.6 519.333 752.6C515.333 748.467 510.666 745.267 505.333 743C499.999 740.6 494.133 739.4 487.733 739.4C481.466 739.4 475.666 740.6 470.333 743C464.999 745.267 460.333 748.467 456.333 752.6C452.333 756.6 449.199 761.4 446.933 767C444.666 772.467 443.533 778.4 443.533 784.8C443.533 791.333 444.666 797.4 446.933 803C449.199 808.6 452.333 813.467 456.333 817.6C460.333 821.6 464.999 824.8 470.333 827.2C475.666 829.467 481.466 830.6 487.733 830.6Z" fill="#333333"/>
                            <path d="M626.828 857C615.495 857 604.428 854.933 593.628 850.8C582.828 846.533 573.228 840.533 564.828 832.8L581.428 812C589.561 819.2 597.361 824.4 604.828 827.6C612.428 830.8 620.428 832.4 628.828 832.4C634.695 832.4 639.761 831.733 644.028 830.4C648.295 828.933 651.561 826.867 653.828 824.2C656.228 821.533 657.428 818.4 657.428 814.8C657.428 810 655.695 806.333 652.228 803.8C648.761 801.133 642.828 799.067 634.428 797.6L606.628 792.8C594.761 790.8 585.695 786.6 579.428 780.2C573.295 773.8 570.228 765.533 570.228 755.4C570.228 746.733 572.428 739.2 576.828 732.8C581.361 726.4 587.695 721.533 595.828 718.2C604.095 714.733 613.895 713 625.228 713C635.495 713 645.695 714.733 655.828 718.2C666.095 721.667 675.161 726.467 683.028 732.6L667.428 754.2C652.761 743 638.028 737.4 623.228 737.4C618.028 737.4 613.495 738.067 609.628 739.4C605.761 740.733 602.761 742.6 600.628 745C598.628 747.267 597.628 750 597.628 753.2C597.628 757.467 599.161 760.8 602.228 763.2C605.295 765.467 610.428 767.2 617.628 768.4L644.028 772.8C658.028 775.067 668.495 779.533 675.428 786.2C682.361 792.733 685.828 801.4 685.828 812.2C685.828 821.267 683.428 829.2 678.628 836C673.828 842.667 666.961 847.867 658.028 851.6C649.228 855.2 638.828 857 626.828 857Z" fill="#333333"/>
                            <path d="M749.953 857C738.62 857 727.553 854.933 716.753 850.8C705.953 846.533 696.353 840.533 687.953 832.8L704.553 812C712.686 819.2 720.486 824.4 727.953 827.6C735.553 830.8 743.553 832.4 751.953 832.4C757.82 832.4 762.886 831.733 767.153 830.4C771.42 828.933 774.686 826.867 776.953 824.2C779.353 821.533 780.553 818.4 780.553 814.8C780.553 810 778.82 806.333 775.353 803.8C771.886 801.133 765.953 799.067 757.553 797.6L729.753 792.8C717.886 790.8 708.82 786.6 702.553 780.2C696.42 773.8 693.353 765.533 693.353 755.4C693.353 746.733 695.553 739.2 699.953 732.8C704.486 726.4 710.82 721.533 718.953 718.2C727.22 714.733 737.02 713 748.353 713C758.62 713 768.82 714.733 778.953 718.2C789.22 721.667 798.286 726.467 806.153 732.6L790.553 754.2C775.886 743 761.153 737.4 746.353 737.4C741.153 737.4 736.62 738.067 732.753 739.4C728.886 740.733 725.886 742.6 723.753 745C721.753 747.267 720.753 750 720.753 753.2C720.753 757.467 722.286 760.8 725.353 763.2C728.42 765.467 733.553 767.2 740.753 768.4L767.153 772.8C781.153 775.067 791.62 779.533 798.553 786.2C805.486 792.733 808.953 801.4 808.953 812.2C808.953 821.267 806.553 829.2 801.753 836C796.953 842.667 790.086 847.867 781.153 851.6C772.353 855.2 761.953 857 749.953 857Z" fill="#333333"/>
                            <path d="M821.125 855V715H850.125V855H821.125Z" fill="#333333"/>
                            <path d="M870.617 855V715H936.417C945.884 715 954.151 716.6 961.217 719.8C968.284 723 973.817 727.533 977.817 733.4C981.817 739.133 983.817 745.8 983.817 753.4C983.817 759.533 982.217 765.2 979.017 770.4C975.951 775.467 971.751 779.6 966.417 782.8C973.217 786 978.617 790.333 982.617 795.8C986.617 801.267 988.617 807.467 988.617 814.4C988.617 822.4 986.551 829.467 982.417 835.6C978.417 841.733 972.684 846.533 965.217 850C957.884 853.333 949.351 855 939.617 855H870.617ZM899.617 772.2H934.617C940.884 772.2 945.817 770.733 949.417 767.8C953.151 764.733 955.017 760.667 955.017 755.6C955.017 750.533 953.151 746.533 949.417 743.6C945.817 740.533 940.884 739 934.617 739H899.617V772.2ZM899.617 831H937.417C944.084 831 949.417 829.4 953.417 826.2C957.417 822.867 959.417 818.467 959.417 813C959.417 807.4 957.417 803 953.417 799.8C949.417 796.467 944.084 794.8 937.417 794.8H899.617V831Z" fill="#333333"/>
                            <path d="M1003.9 855V715H1032.9V855H1003.9Z" fill="#333333"/>
                            <path d="M1053.39 855V715H1082.39V829.4H1158.99V855H1053.39Z" fill="#333333"/>
                            <path d="M1173.98 855V715H1202.98V855H1173.98Z" fill="#333333"/>
                            <path d="M1252.47 829.2H1276.87C1283.14 829.2 1288.94 828.133 1294.27 826C1299.74 823.733 1304.47 820.6 1308.47 816.6C1312.6 812.6 1315.74 807.933 1317.87 802.6C1320.14 797.267 1321.27 791.467 1321.27 785.2C1321.27 778.933 1320.14 773.133 1317.87 767.8C1315.6 762.333 1312.47 757.6 1308.47 753.6C1304.47 749.6 1299.74 746.467 1294.27 744.2C1288.94 741.933 1283.14 740.8 1276.87 740.8H1252.47V829.2ZM1223.47 855V715H1277.27C1287.8 715 1297.54 716.8 1306.47 720.4C1315.4 723.867 1323.2 728.8 1329.87 735.2C1336.54 741.6 1341.74 749.067 1345.47 757.6C1349.34 766.133 1351.27 775.333 1351.27 785.2C1351.27 795.2 1349.4 804.4 1345.67 812.8C1341.94 821.2 1336.67 828.6 1329.87 835C1323.2 841.267 1315.4 846.2 1306.47 849.8C1297.54 853.267 1287.8 855 1277.27 855H1223.47Z" fill="#333333"/>
                            <path d="M1344.91 855L1402.51 715H1435.91L1492.91 855H1461.51L1447.31 818.2H1389.51L1375.11 855H1344.91ZM1398.51 795H1438.51L1418.51 743.4L1398.51 795Z" fill="#333333"/>
                            <path d="M1528.8 829.2H1553.2C1559.46 829.2 1565.26 828.133 1570.6 826C1576.06 823.733 1580.8 820.6 1584.8 816.6C1588.93 812.6 1592.06 807.933 1594.2 802.6C1596.46 797.267 1597.6 791.467 1597.6 785.2C1597.6 778.933 1596.46 773.133 1594.2 767.8C1591.93 762.333 1588.8 757.6 1584.8 753.6C1580.8 749.6 1576.06 746.467 1570.6 744.2C1565.26 741.933 1559.46 740.8 1553.2 740.8H1528.8V829.2ZM1499.8 855V715H1553.6C1564.13 715 1573.86 716.8 1582.8 720.4C1591.73 723.867 1599.53 728.8 1606.2 735.2C1612.86 741.6 1618.06 749.067 1621.8 757.6C1625.66 766.133 1627.6 775.333 1627.6 785.2C1627.6 795.2 1625.73 804.4 1622 812.8C1618.26 821.2 1613 828.6 1606.2 835C1599.53 841.267 1591.73 846.2 1582.8 849.8C1573.86 853.267 1564.13 855 1553.6 855H1499.8Z" fill="#333333"/>
                            <path d="M1642.45 855V715H1750.05V740.2H1671.45V772.2H1723.25V796.6H1671.45V829.8H1750.85V855H1642.45Z" fill="#333333"/>
                            <path d="M1816.36 857C1805.03 857 1793.96 854.933 1783.16 850.8C1772.36 846.533 1762.76 840.533 1754.36 832.8L1770.96 812C1779.09 819.2 1786.89 824.4 1794.36 827.6C1801.96 830.8 1809.96 832.4 1818.36 832.4C1824.23 832.4 1829.29 831.733 1833.56 830.4C1837.83 828.933 1841.09 826.867 1843.36 824.2C1845.76 821.533 1846.96 818.4 1846.96 814.8C1846.96 810 1845.23 806.333 1841.76 803.8C1838.29 801.133 1832.36 799.067 1823.96 797.6L1796.16 792.8C1784.29 790.8 1775.23 786.6 1768.96 780.2C1762.83 773.8 1759.76 765.533 1759.76 755.4C1759.76 746.733 1761.96 739.2 1766.36 732.8C1770.89 726.4 1777.23 721.533 1785.36 718.2C1793.63 714.733 1803.43 713 1814.76 713C1825.03 713 1835.23 714.733 1845.36 718.2C1855.63 721.667 1864.69 726.467 1872.56 732.6L1856.96 754.2C1842.29 743 1827.56 737.4 1812.76 737.4C1807.56 737.4 1803.03 738.067 1799.16 739.4C1795.29 740.733 1792.29 742.6 1790.16 745C1788.16 747.267 1787.16 750 1787.16 753.2C1787.16 757.467 1788.69 760.8 1791.76 763.2C1794.83 765.467 1799.96 767.2 1807.16 768.4L1833.56 772.8C1847.56 775.067 1858.03 779.533 1864.96 786.2C1871.89 792.733 1875.36 801.4 1875.36 812.2C1875.36 821.267 1872.96 829.2 1868.16 836C1863.36 842.667 1856.49 847.867 1847.56 851.6C1838.76 855.2 1828.36 857 1816.36 857Z" fill="#333333"/>
                        </motion.g>
                    ) }
                </svg>

                <motion.div
                    variants={ animebg }
                    initial='begin'
                    animate='end'
                    exit='begin'
                    className="absolute left-0 top-0 right-0 bottom-0 z-10" style={{background:'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)'}}
                >

                </motion.div>

                <NavLink to='/home'>
                    <motion.span
                        variants={ animebutton }
                        initial='begin'
                        animate='end'
                        exit='begin'
                        className='absolute top-[45vw] left-0 right-0 z-20 mx-auto  w-fit font-RedHatBold text-[2.5vw] tracking-wider uppercase py-[1vw] px-[3.5vw] rounded-full bg-arsenic flex items-center justify-center text-white'
                    >
                        Explorar
                        <svg className='ml-[1vw] h-[2.5vw] w-[2.5vw]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 51" fill="none">
                            <path d="M48 26L4 26" stroke="#AAAAAA" strokeWidth="7" strokeLinecap="square"/>
                            <path d="M30 46L50 25.5L30 5" stroke="#CAEF30" strokeWidth="7" strokeLinecap="square"/>
                        </svg>
                    </motion.span>
                </NavLink>
            </div>
        </div>
    )

}
