import React, { useState, useContext } from 'react'


const FilterContext = React.createContext()
export default function FilterProvider({ children }){

    // FILTER
    const [ filter_is_visible, _setModalVisible ] = useState( false )
    const [ filterquery, SetFilterQuery ] = useState( null )

    const ShowFilterModal = () => {
        _setModalVisible( true )
    }

    const HideFilterModal = () => {
        _setModalVisible( false )
    }

    return (
        <FilterContext.Provider 
            value={{
                ShowFilterModal,
                HideFilterModal,
                filter_is_visible,
                filterquery, 
                SetFilterQuery
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}

export function UseFilterContext() {
    return useContext(FilterContext)
}