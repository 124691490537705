import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion"
import BackgroundSection from '../components/background-section';
import BannterCategory from '../components/banner-category';
import FooterSection from '../components/footer-section';
import SidebarArea from '../components/sidebar';
import { UseNavigationContext } from '../context/navigation-context';
import Navigation from '../components/navigation';
import { GetCategories } from '../shared/get-categories';
import { useParams } from 'react-router-dom';
import Cart from '../components/cart';
import ProductList from '../components/product-list-revised';
import { ProductsQueryIntelligentSearch } from '../shared/intelligent-search';
import ProdctModal from '../components/product-modal';
import Overlay from '../components/overlay';
import FilterProducts from '../components/filter-products';
import { UseFilterContext } from '../context/filter-context';
import { UseFrontEndContext } from "../context/frontend-context";
import { UseOverlayContext } from "../context/overlay-context";
import { DatalayerPageView, DatalayerSimpleEvent } from '../shared/datalayer-events';


// *---------------
// * PAGE TRANSITION ANIMATION
// *---------------
const pagetransition = {
    hidden: {
        x: '-100vw',
        opacity: 0
    },
    visible: {
        x: '0px',
        opacity: 1,
        transition: {
            delay: .3,
            duration: .6
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}

export default function PageCategory(){
    
    // *---------------
    // * ROUTER PARAMS
    // *---------------
    const params = useParams()

    // *---------------
    // * DATALAYER CHECKER
    // *---------------
    const [ datalayer_pushed, setDatalayerPushed ] = useState(false)

    // *---------------
    // * NAVIGATION CONTEXT
    // *---------------
    const { 
        setActiveTab,
    } = UseNavigationContext()

    // *---------------
    // * NAVIGATION CONTEXT
    // *---------------
    const {
        banners,
        getCategoryBanner,
        footerBanners
    } = UseFrontEndContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const {
        ActiveOverlay,
    } = UseOverlayContext()


    // *---------------
    // * FILTER CONTEXT
    // *---------------
    const { 
        filterquery,
        ShowFilterModal,
    } = UseFilterContext()

    // *---------------
    // * CATEGORIES 
    // *---------------
    const [ categories ] = GetCategories( 3, 3000 )

    // *---------------
    // * PRODUCTS, PAGINATION AND FILTER
    // *---------------
    const [ products, setProducts ] = useState( [] )
    const [ pagination, setPagination ] = useState( null )
    const [ filterFacets, setFilterFacets ] = useState( null )
    const [ total, setTotal ] = useState( null )
    const [ loading, setLoading ] = useState( true )
    const [ urlBanner, setUrlBanner ] = useState( false )

    const QueryData = useCallback( async( { brandname, page, perpage, categoryid, filterquery, controller } ) => {

        setLoading( true )
        const rawdata = await ProductsQueryIntelligentSearch( { brandname, page, perpage, categoryid, filterquery, controller, retrys: 3, delay: 3000 } )

        if( rawdata ) {
            
            if( rawdata.products && rawdata.products.length > 0 ) { 
                setProducts( rawdata.products )
            }else{
                setProducts( -1 )
            }

            if( rawdata.pagination ){
                setPagination( rawdata.pagination )
            }

            if( rawdata.recordsFiltered && rawdata.products.length > 0 ){
                setTotal( rawdata.recordsFiltered )
            }else{
                setTotal( 0 )
            }

            if( rawdata.filterfacets ){
                setFilterFacets( rawdata.filterfacets )
            }
            
        }

        setLoading( false )
    }, [] )

    const PaginationClickHandler = ( page ) => {
        QueryData( { brandname: params.brandname, page, perpage: 8, categoryid: params.categoryid, filterquery } )
    }

    const ClearFilterHandler = () => {
        QueryData( { brandname: params.brandname, page: 1, perpage: 8, categoryid: params.categoryid } )

        setLoading(true)
        setProducts(1)
    }

    useEffect(() => {
        const controller = new AbortController()

        if( params ){
            setActiveTab( parseInt( params.categoryid ) )

            QueryData( {brandname: 'aramis', page: 1, perpage: 8, categoryid: params.categoryid, orderdesc: true, controller } )
            setDatalayerPushed( true )
            
        }

        return () => {
            controller.abort()
        }
    },[params, setActiveTab, QueryData, setDatalayerPushed])

    useEffect(() => {
        if( !datalayer_pushed ){
            DatalayerPageView()
            DatalayerSimpleEvent( 'CategoryView' )
        }
    },[datalayer_pushed])

    useEffect(() => {

        if (params) {
            if (getCategoryBanner(Number(params.categoryid)) !== undefined ) {
                setUrlBanner(getCategoryBanner(Number(params.categoryid)).imgurl)
    
            } else if (banners && banners.length > 0 && !urlBanner) {
                setUrlBanner(banners[Math.floor(Math.random() * banners.length)].imgurl)
            }
        }
        
    }, [banners, getCategoryBanner, params, urlBanner])


    // *---------------
    // * FILTER
    // *---------------
    useEffect(() => {
        const controller = new AbortController()
        
        if( filterquery && filterquery.length > 0 ){
            QueryData( {brandname: 'aramis', page: 1, perpage: 8, categoryid: params.categoryid, orderdesc: true, controller, filterquery } )
        }else{
            QueryData( {brandname: 'aramis', page: 1, perpage: 8, categoryid: params.categoryid, orderdesc: true, controller } )
        }

        return () => {
            controller.abort()
        }

    },[filterquery, QueryData, params])

    useEffect(() => {
        setLoading(true)
        
        setProducts(1)
    }, [filterquery])

    // *---------------
    // * Footer 
    // *---------------
    const [footerCards, setFooterCards] = useState( null );

    useEffect( () => {
        if (footerBanners) {
            if (footerBanners.length > 0) {
                setFooterCards(footerBanners)
            }
        }
    }, [footerBanners] )


    // *---------------
    // * MARKUP
    // *---------------
    return (
        <motion.div
            variants={pagetransition}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='bg-white min-h-screen w-screen flex flex-col overflow-hidden'
        >
            { /* BANNER */ }
            <BackgroundSection foreigner_color={'#fff'} background_color={'#424242'} nopad={true} oversized={true}>
                <BannterCategory imgurl={urlBanner} />
            </BackgroundSection>

            { /* CONTENT */ }
            <BackgroundSection  className={'flex-1'} foreigner_color={'#D9D9D9'} background_color={'#fff'} >
                { /* CATEGORIES NAV */ }
                <div className='w-full'>
                    <Navigation title='CATEGORIAS' items={categories} navigateonclick={true} />
                </div>


                <div className=' w-full justify-between mb-[2vw] pr-[3vw]'>
                    { /* PRODUCTS RESULT */ }
                        <div className='flex w-full mb-[2vw] items-center justify-between'>
                            <button
                                className='bg-arsenic text-white uppercase font-RedHatRegular text-[1vw] px-[1vw] py-[0.35vw] flex items-center rounded-full border-[0.2vw] border-darkgray'
                                onClick={ (e) => { e.preventDefault(); ShowFilterModal( true ); ActiveOverlay() } }
                            >
                                <span>FILTRAR</span>

                                <svg className='inline-block w-[1.2vw] h-[1.2vw] align-middle ml-[1vw]' viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.7271 16.4307H1M25 16.4307H21.1819M6.99995 4.43066H1M24.9999 4.43066H12.4545M21.1817 16.4323C21.1817 17.9385 19.9607 19.1596 18.4544 19.1596C16.9482 19.1596 15.7272 17.9385 15.7272 16.4323C15.7272 14.9261 16.9482 13.7051 18.4544 13.7051C19.9607 13.7051 21.1817 14.9261 21.1817 16.4323ZM12.4544 4.43233C12.4544 5.93854 11.2334 7.15957 9.72719 7.15957C8.22097 7.15957 6.99994 5.93854 6.99994 4.43233C6.99994 2.92611 8.22097 1.70508 9.72719 1.70508C11.2334 1.70508 12.4544 2.92611 12.4544 4.43233Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                
                            </button>
                            { total !== null && !loading && (
                                <p className='font-RedHatLight text-[1.2vw]'> 
                                    <span className='inline-block mr-[0.5vw] font-RedHatBold'>
                                        { total }
                                    </span>
                                    PRODUTOS
                                </p>
                            )}
                        </div>
                        
                        { /* PRODUCTS LIST */ }
                        { products !== null && products !== -1 && ( 
                            <ProductList products={products} placeholders={8} columns={4} loading={loading} />
                        ) }

                        { products === -1 && !loading && (
                            <div className='w-full flex flex-col justify-center items-center py-[3vw] mb-[70%]'>
                                <p className='font-RedHatLight text-[1.6vw]'>Nenhum produto encontrado</p>
                                <button onClick={ () => ClearFilterHandler() } className='my-[3vw] rounded-full border-[0.1vw] border-darkgray text-[1.3vw] font-RedHatBold uppercase px-[2.4vw] leading-[2.8vw]'> Limpar Filtro </button>
                            </div>
                        )}
                </div>

                { /* PRODUCTS PAGINATION */ }
                <div className='text-center w-full pt-[3vw]'>
                    { pagination && (
                        <ul className='flex items-center justify-center gap-[1vw]'>
                            { pagination.before && pagination.before.map( (item, index) => (
                                <li key={index}>
                                    <button onClick={ () => { PaginationClickHandler( item.index ) } } className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold'>
                                        {item.index}
                                    </button>
                                </li>
                            ) ) }
                            { pagination.current && pagination.current.index !== 0 && (
                                <li>
                                    <button className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold bg-arsenic text-white'>
                                        { pagination.current.index }
                                    </button>
                                </li>
                            )}
                            { pagination.after && pagination.after.map( ( item, index ) => ( 
                                <li key={index}>
                                    <button onClick={ () => { PaginationClickHandler( item.index ) } } className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold'>
                                        {item.index}
                                    </button>
                                </li>
                            ) ) }
                        </ul>
                    ) }
                </div>
            </BackgroundSection>

            { /* SIDEBAR */ }
            <SidebarArea>
                <Cart />
            </SidebarArea>

            { /* PRODUCT MODAL */ }
            <ProdctModal />

            { /* FILTER */ }
            <FilterProducts filterFacets={ filterFacets } />

            { /* OVERLAY */ }
            <Overlay />

            <FooterSection rawdata={ footerCards } />
        </motion.div>
    );
}