import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence } from "framer-motion"
import { LocalePrice } from '../shared/locale-price';
import { UseCartContext } from '../context/cart-context';
import { UseOverlayContext } from '../context/overlay-context';
import { UseProductModalContext } from '../context/product-modal-context';
import { QueryProductSimilars } from '../shared/product-similars-service';
import { QueryProductByLink } from '../shared/product-service';
import { UseColorsContext } from '../context/color-context';
import { DatalayerAddToCart } from '../shared/datalayer-events';


export default function ProductCard( {rawproduct, i, allproducts} ) {

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        UpdateProductItem, firstAccessCart, setFirstAccessCart, formOrder, productIds, setProdutsIds
    } = UseCartContext()

    // *---------------
    // * COLORS CONTEXT
    // *---------------
    const {
        GetColorsByName
    } = UseColorsContext()

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const {
        ToggleOverlay
    } = UseOverlayContext()

    // *---------------
    // * PRODUCT MODAL CONTEXT
    // *---------------
    const { 
        setActiveModalItem
    } = UseProductModalContext()

    // *---------------
    // * LOCAL STATES
    // *---------------
    const [ activeitem, setActiveItem ] = useState( null )
    const [ items, setItems ] = useState( [] )
    const [ similars, setSimilars ] = useState( [] )
    const [ brand, setBrand ] = useState( '' )
    const [ commertialOffer, setCommertialOffer ] = useState( null )
    const [ imgs, setImgs ] = useState( [] )
    const [ activeImg, setActiveImg ] = useState( 0 )
    const [ video, setVideo ] = useState( {} )
    const [ colorsIndex, setColorsIndex ] = useState( 3 )


    const FetchSimilarsColors = useCallback( async ( { productId } ) => { 

        const products_url = await QueryProductSimilars( { productId } )
        if( products_url ){

            const similar_products = []
            if( products_url?.length > 0 ){
                await Promise.all(products_url.map(async ( productLink ) => {
                    const the_product = await QueryProductByLink( { productTextLink: productLink } )

                    if( the_product ){
                        similar_products.push( the_product[0] )
                    }
                }));
            }

            setSimilars( similar_products )
        }

    }, [] )

    useEffect( () => {

        if( rawproduct.items ){
            if( rawproduct.items ){
                setActiveItem( rawproduct.items[0] )
                setItems( rawproduct.items )
            }

            if( rawproduct.brand ){
                setBrand( rawproduct.brand  )
            }

            FetchSimilarsColors( { productId: rawproduct.productId } )
        }

    }, [ rawproduct, FetchSimilarsColors ] )

    //When items has filled
    useEffect( () => {

        // Images gallery setup
        if( items.length > 0  ){
            if( items[0].images  ){
                setImgs( items[0].images )
                setActiveImg( items[0].images[0] )
            }
        }

        // Video setup
        if( items.length > 0 ){
            if( items[0].videos && items[0].videos.length > 0  ){
                //console.log( items[0].videos[0]  )
                setVideo( {
                    visible: false,
                    videoUrl: items[0].videos[0]
                }  )
            }
        }

    }, [ items ] )


    //When Activeitem is changed
    const GetPricePayment = ( installments ) => {
        if( installments ){
            let bestinstallment = null

            installments.forEach( installment => { 
                if( bestinstallment === null ){
                    bestinstallment = installment
                }

                if( bestinstallment.NumberOfInstallments < installment.NumberOfInstallments ){
                    bestinstallment = installment
                }
            })
 
            if( bestinstallment !== null ){
                return `ou ${ bestinstallment.NumberOfInstallments }x de ${ LocalePrice( bestinstallment.Value ) }`
            }
        }
    }

    // CHANGE COLORS
    const nextColor = (maxColors) => {
        if (colorsIndex > maxColors) {
            setColorsIndex(3)
        } else {
            setColorsIndex(colorsIndex + 3)
        }
    }


    // Test freeshipping politicy for product
    const freeShippingTest = ( price ) => {
        if( price > 499 ){
            return true
        } else {
            return false
        }
    }

    // Get commercial offer object
    const GetCommertialOffer = useCallback( ( product ) => {
        if( product ){
            // PRODUCT PRICE
            let commertialOffer = null

            if (activeitem.sellers) {
                if (activeitem.sellers.length > 1) {
                    activeitem.sellers.forEach(seller => seller.sellerDefault ? commertialOffer = seller.commertialOffer : null )
                } else {
                    commertialOffer = activeitem.sellers ? activeitem.sellers[0].commertialOffer : null
                }
            }
            
            if( commertialOffer ){

                const installmentformated = GetPricePayment( commertialOffer.Installments )

                return {
                    price: LocalePrice( commertialOffer.Price.toFixed(2) ),
                    listPrice: LocalePrice( commertialOffer.ListPrice.toFixed(2) ),
                    installment: installmentformated,
                    freeShipping: freeShippingTest( commertialOffer.Price )
                } 
            }
        }
    }, [activeitem] )


    useEffect( () => {
       if( activeitem ){
            // PRODUCT PRICE
            const _commertialOffer = GetCommertialOffer( activeitem )
            if( _commertialOffer ){
                setCommertialOffer( _commertialOffer )
            }
       }
    }, [ activeitem, GetCommertialOffer ] )



    // ADD TO CART HANDLER
    const AddToCartHandler = ( product ) => { 
        let productId = product.itemId ? product.itemId : null
        let sellerId = product.sellers ? product.sellers[0].sellerId : null

        if( productId && sellerId ){
            UpdateProductItem( productId, sellerId, 1 )
        }else{
            console.warn( 'AddToCartHandler não encontrou productId ou sellerId' )
        }

        if (!firstAccessCart) {
            setFirstAccessCart('enable')
            ToggleOverlay()
        } 

        // ADD EVENT IN DATALAYER
        DatalayerAddToCart( rawproduct, activeitem)
    }

    // PLAY VIDEO HANDLER
    const PlayVideoHandler = () => {
        setVideo(
            {
                ...video,
                visible: !video.visible,
            }
        )
    }

    // ACTIVE IMAGE HANDLER
    const ActiveImageHandler = ( img ) => {
        setActiveImg( img ) 
        setVideo(
            {
                ...video,
                visible: false,
            }
        )
    }

    useEffect(() => {
        if (formOrder && formOrder.items) {
            if (formOrder.items.length >= 1) {
                formOrder.items.forEach(item => {
                    if (!productIds.includes(item.id)) setProdutsIds([...productIds, item.id]);
                })
            }
        }
    }, [formOrder, productIds, setProdutsIds])

    return(
        <AnimatePresence>
            <article className={commertialOffer && commertialOffer.price === 'R$ 0,00' ? 'opacity-80' : ''}>
                    <figure className='block relative w-full h-[28vw] bg-darkgray rounded-[1.1vw] overflow-hidden'>
                        { activeImg && (
                            <img 
                                onClick={ (e) => { e.preventDefault(); commertialOffer && commertialOffer.price === 'R$ 0,00' ? e.preventDefault() : setActiveModalItem( { product: rawproduct, activeitem: activeitem, commertialOffer } )  } }
                                src={ activeImg.imageUrl } alt={ activeImg.imageText } className='absolute inset-0 w-full h-full object-cover' 
                            />
                        ) }
                        { !activeImg && (
                            <img className='absolute w-full h-full top-0 left-0' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8+B8AAocBwlBk+oQAAAAASUVORK5CYII=" alt='noimg'/>
                        )}
                        { video && video.videoUrl && video.visible && (     
                            <video 
                                autoPlay 
                                muted 
                                loop
                                src={video.videoUrl}
                                className='absolute inset-0 w-full h-full object-cover'
                            />
                        )}
                        <figcaption>
                            { /* PHOTOS NAV */  }
                            { imgs && imgs.length && (
                                <ul className='absolute flex bottom-[0.8vw] left-[0.8vw]'>
                                    { imgs.map( ( img, index ) => { 
                                        if( index < 5 ){
                                            return(  
                                                <li key={index} className=''>
                                                    <button 
                                                        onClick={() => ActiveImageHandler( img )} 
                                                        className={
                                                            `relative flex w-[1.6vw] h-[1.6vw] justify-center items-center  cursor-pointer`
                                                        }
                                                    >
                                                        <span 
                                                            className={
                                                                `w-[0.8vw] h-[0.8vw] bg-white border rounded-full
                                                                    ${ activeImg.imageId === img.imageId
                                                                        ? 'bg-darkgray border-black'  
                                                                        : 'border-darkgray' 
                                                                    } 
                                                                `}
                                                        > 
                                                        </span>
                                                    </button>
                                                </li>
                                            ) 
                                        }else{
                                            return null
                                        }
                                } )}
                                </ul>
                            ) }

                            { /* VIDEO BUTTON */  }
                            { video && video.videoUrl &&  (
                                <button 
                                    className='absolute bottom-[0.8vw] right-[0.8vw] flex items-center justify-center w-[1.6vw] h-[1.6vw]  bg-arsenic rounded-full'
                                    onClick={ () => PlayVideoHandler() }
                                >
                                    <svg className='w-[1vw] h-[1vw]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5V19L19 12L8 5Z" fill="#FFF"/>
                                    </svg>
                                </button>
                            ) }
                        </figcaption>
                    </figure>
                

                { activeitem && (
                    <>
                        { /* PRODUCT BRAND */  }
                        { brand && (
                            <span className='font-RedHatRegular font-light text-[0.7vw] text-arsenic mt-[1vw]'>
                                {brand}
                            </span>
                        ) }

                        { /* PRODUCT COMPLEMENTNAME */  }
                        { activeitem?.complementName && (
                            <h3 className='font-RedHatBold font-bold uppercase lg:leading-[1.2vw] lg:min-h-[2.4vw] text-arsenic text-[1vw] line-clamp-2'>
                                {activeitem.complementName}
                            </h3>
                        )}

                        { /* ITEM PRICE & FREE SHIPPING */  }
                        <div className='flex justify-between mt-[0.82vw]'>
                            { commertialOffer && (
                                <div>
                                    <div className='flex flex-wrap items-center '>
                                        {
                                            commertialOffer.price === 'R$ 0,00' ? 
                                                null
                                            :
                                            <>
                                                { /* ORIGINAL PRICE */  }
                                                { commertialOffer.listPrice > commertialOffer.price  && (
                                                    <span className='text-[8px] text-arsenic lg:text-[0.8vw] mr-[0.45vw] font-semibold relative line-through'>
                                                        { commertialOffer.listPrice }
                                                    </span>
                                                ) }


                                                { /* PRICE WITH DISCOUNT */  }
                                                { commertialOffer.price && (
                                                    <p className='text-arsenic font-semibold text-[9px] lg:text-[0.9vw]'> 
                                                        { commertialOffer.price }
                                                    </p>
                                                )}
                                            </>
                                        }
                                        
                                    </div>

                                    { /* PRICE INSTALLMENT */  }
                                    { commertialOffer.installment && (
                                        <p className='text-[7px] lg:text-[0.7vw] mt-[0.31vw] text-[#8D8D8D]'>
                                            { commertialOffer.installment }
                                        </p>
                                    ) }
                                </div>
                            )}

                            { /* FREE SHIPPING */  }
                            { commertialOffer && commertialOffer.freeShipping && (
                                <p className=' border border-black text-center w-max rounded-[0.9vw] pt-[0.1vw] mt-[0.1vw] px-[0.6vw] h-max text-[7px] lg:text-[0.54vw] font-bold'>FRETE GRÁTIS</p>
                            )}
                        </div>

                        { /* PRODUCT VARIANTS */  }
                        <div className='mt-[1.28vw]'>

                            { /* VARIANT "TAMANHO" */  }
                            { items.length && items[0].Tamanho &&  (
                                <ul className='flex flex-wrap gap-[0.6vw]'>
                                    { items.map((item, index) => { 
                                        if( item.sellers && item.sellers.length && item.sellers[0].commertialOffer.AvailableQuantity > 0 ) {
                                            return (
                                                <li key={index} className={`relative ${(item.Tamanho[0].toLowerCase() === 'p') ? '!order-1' : 'order-4'} ${ (item.Tamanho[0].toLowerCase() === 'm') ? '!order-2' : 'order-4' } ${ (item.Tamanho[0].toLowerCase() === 'g') ? '!order-3' : 'order-4' }`}>
                                                    <button 
                                                        className={
                                                            `flex w-[2.1vw] text-[7px] lg:text-[0.63vw] justify-center items-center h-[2.1vw]  border rounded-full cursor-pointer uppercase focus:outline-none ${
                                                                ( activeitem && activeitem.itemId === item.itemId ) ? ' text-white bg-arsenic border-black' : 'bg-white border-[#BBBBBB]'
                                                            }`
                                                        }
                                                        onClick={() => setActiveItem(item)}
                                                    >
                                                        {item.Tamanho && item.Tamanho.length && (
                                                            <>
                                                                {item.Tamanho[0]}
                                                            </>
                                                        )}
                                                    </button>
                                                </li>
                                            )
                                        }else{
                                            return null
                                        }
                                    })}
                                </ul> 
                            ) } 

                            { /* VARIANT "COR" */  }
                            <ul className='flex items-center max-w-[9.25vw] gap-[0.6vw] mt-[1.05vw] min-h-[2vw]'>
                                { similars && similars.length > 0 && (
                                    <>
                                        { similars.slice(colorsIndex - 3, colorsIndex).map( ( product, i) => {
                                            if( product && product.Cor ){
                                                return(
                                                    <li key={i}>
                                                        <button className='flex w-[2.1vw] text-[7px] lg:text-[0.63vw] justify-center items-center h-[2.1vw] border rounded-full cursor-pointer' style={{ backgroundColor: GetColorsByName ? GetColorsByName( product?.['Cor Real'][0] ) : 'black' }} onClick={ (e) => { e.preventDefault(); setActiveModalItem( { product: product, activeitem: product.items[0], commertialOffer: GetCommertialOffer( product.items[0] ) } ) } }>
                                                        </button>
                                                    </li>
                                                )
                                            }else{
                                                return null
                                            }
                                        } ) }

                                    </>
                                ) }

                                { similars && similars.length > 3 && ( 
                                    <button className='ml-auto' onClick={() => nextColor(similars.length)}>
                                        <svg className='w-[1.1vw] h-[1.1vw]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L9 9L1 17" stroke="#424242" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9 1L17 9L9 17" stroke="#424242" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                )   }
                            </ul>

                            <div className='flex items-center -mt-[2.1vw]'> {/*mt-[1.73vw]*/}
                                {commertialOffer && (
                                    commertialOffer.price === 'R$ 0,00' ?
                                    <div className={ ` bg-arsenic border-darkgray border-[0.2vw] font-bold text-white text-[10px] lg:text-[0.91vw] block py-[0.3vw] ml-auto px-[2.2vw] rounded-[2vw] transition-all` }>
                                        INDISPONÍVEL
                                    </div>
                                    : 
                                    <button 
                                        onClick={(e) => productIds.includes(activeitem.itemId) ? e.preventDefault() : AddToCartHandler(activeitem)} 
                                        className={ ` ${productIds.includes(activeitem.itemId) 
                                            ? 'bg-pear text-black px-[1vw] border-pear items-center justify-center flex gap-[0.319052vw]' 
                                            : 'bg-arsenic px-[2.2vw] text-white border-darkgray  active:translate-y-[0.2vw]'}  border-[0.2vw] font-bold text-[10px] lg:text-[0.91vw] block py-[0.3vw] ml-auto rounded-[2vw] transition-all` }
                                    >
                                        {productIds.includes(activeitem.itemId) ? 'ADICIONADO' : 'COMPRAR'}

                                        {productIds.includes(activeitem.itemId) ? 
                                            <svg className='w-[1.2vw]' width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 1L6 11L1 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> 
                                            : 
                                            ''
                                        }
                                    </button>
                                    
                                )    }
                            </div>

                        </div>
                    </>
                ) }


            </article>
        </AnimatePresence>
    )

}