import { ENV_CORSNOVAPROXY } from './envdata'


//https://developers.vtex.com/vtex-rest-api/reference/searchdocuments

export const QueryMasterData = async ( { acronym, controller, page, perpage, query, retrys, delay } ) => {
    const HEADERS =  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'REST-Range': `resources=${page}-${page + perpage - 1}`,
        'X-VTEX-API-AppKey': 'vtexappkey-aramismenswear-HXYGGF',
        'X-VTEX-API-AppToken': 'TMGOHZAUSUYVGIXZHAFAUGXDPPWTEHRKMCKEISZDYIYMMCCITFMBCMAJPFPKYUGPTYAOERUQNHONJKZIHZKSZTJFHEXXBRIOJEGWGVIRLNCLUYRLNNFDJPLLUNVCYNOB'
    }

    const OPTIONS = {
        method: 'GET',
        headers: HEADERS,
    }

    // Injetando o signal no options
    if( controller ){
        OPTIONS.signal = controller.signal
    }

    // FETCH DELAY
    await new Promise(r => setTimeout(r, delay ? delay : 1000) )

    try{
        const fetch_url = `${ENV_CORSNOVAPROXY}/api/dataentities/${acronym}/search/${query ? query : ''}`
        const response = await fetch( fetch_url, OPTIONS )
        const data = await response.json()
        //console.log(data)
        return data
    }catch( errors ){
        if( retrys > 0 ){
            console.warn( `Request Error! trying again, \nerror log: ${errors}` )
            return QueryMasterData( { acronym, controller, page, perpage, query, retrys: retrys - 1, delay: 5000 } )
        }else{
            if (errors.name === "AbortError") {
                console.warn("Operation timed out");
                return -1
            } else {
                if ( controller && controller.signal.aborted ) return;
                console.warn( errors )
                return -1
            }
        }
        
    }

}