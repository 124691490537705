import { ENV_CORSPROXY, ENV_VTEX_APPKEY, ENV_VTEX_APPTOKEN } from './envdata'

const OPTIONS = {
    method: 'GET',
    headers: {
        "accept": "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        'X-VTEX-API-AppKey': ENV_VTEX_APPKEY,
        'X-VTEX-API-AppToken': ENV_VTEX_APPTOKEN
    }
}

export const ValidateCustomSeller = async( seller_id ) => {
    try{
        const response = await fetch( `${ENV_CORSPROXY}/api/dataentities/TL/search?_fields=id,codigovendedora,nomevendedora,promovendedor,data,datainativo,createdIn&_where=codigovendedora=${seller_id}`, OPTIONS)
        const data = await response.json()
        
        return data
    }catch( errors ){
        if (errors.name === "AbortError") {
            console.warn("Operation timed out");
            return -1
        } else {
            console.warn( errors )
            return -1
        }
    }
}
