import React from 'react';
import { UseCartContext } from '../context/cart-context';

export default function InputCartCoupon() {
    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        AddCoupom
    } = UseCartContext()

    const { 
        coupon
    } = UseCartContext()

    const { 
        setCoupon
    } = UseCartContext()

    const { 
        openKeyboard
    } = UseCartContext()

    // *---------------
    // * FUNCTIONS
    // *---------------
    const HandlerSubmit = (e) => {
        e.preventDefault();
        AddCoupom( coupon )
    }

    return(
        <div className='w-full flex justify-center pb-[1.4vw]'>
            <form 
                className='flex gap-[0.5vw] items-end justify-between'
                onSubmit={ (e) => { HandlerSubmit(e)} }
            >
                <label>
                    <span className='py-[0.5vw] mb-[2vw] font-RedHatBold text-[1vw] uppercase'>Cupom promocional </span>
                    <input 
                        className='font-RedHatBold text-[1vw] px-[1vw] leading-[2vw] w-full py-[0.5vw] border-b-2 border-gray-black focus:outline-none'
                        type="text" 
                        onClick={() => openKeyboard('coupon')}
                        value={coupon}
                        onChange={ (e) => setCoupon( e.target.value ) }
                        placeholder='Digite o código' 
                    />
                </label>
                <input className='text-[1vw] leading-[3vw] w-[5vw] h-[3vw] text-center font-RedHatBold uppercase bg-arsenic text-white rounded-full px-[1vw]'  type="submit" value="OK" />
            </form>
        </div>
    )

}