import { ENV_CORSNOVAPROXY } from './envdata'



// Ex: buscando por brand
// https://aramisnova.myvtex.com/_v/api/intelligent-search/product_search/brand/ereditaryo/?simulationBehavior=default&count=6&page=1&hide-unavailable-items=false
// https://aramisnova.myvtex.com/_v/api/intelligent-search/facets/category-1/236/?locale=pt-BR&hide-unavailable-items=false

export const ProductsQueryIntelligentSearch = async( {brandname, page, perpage, categoryid, filterquery, query, controller, retrys, delay } ) => {

    const OPTIONS = {
        method: 'GET',
        headers: {
            "accept": "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }

    // Injetando o signal no options
    if( controller ){
        OPTIONS.signal = controller.signal
    }

    // FETCH DELAY
    await new Promise(r => setTimeout(r, delay ? delay : 1000) )

    try{
        const QUERYURL = `${ENV_CORSNOVAPROXY}/_v/api/intelligent-search/product_search${ categoryid ? `/category-3/${categoryid}` : '' }/brand/Urban-Performance${ filterquery && filterquery.length > 0 ? filterquery : '' }/?${query ? `query=${query}&` : ''}simulationBehavior=default&count=${perpage}&page=${page}&hide-unavailable-items=true&sort=release:desc`
        console.log( QUERYURL )
        const response = await fetch( 
            QUERYURL, 
            OPTIONS
        ).catch(
            (err) => {
                if ( controller && controller.signal.aborted ) return;
            }
        )

        if( response ){
            const data = await response.json()

            const searchfacets = await  QueryIntelligentSearchFacets( {brandname, categoryid, controller } )
        
            data.filterfacets = searchfacets
            return data
        }

    }catch( errors ){
        if( retrys > 0 ){
            console.warn( `Request Error! trying again, \nerror log: ${errors}` )
            return ProductsQueryIntelligentSearch( {brandname, page, perpage, categoryid, filterquery, controller, retrys: retrys - 1, delay: 5000 } )
        }else{
            if (errors.name === "AbortError") {
                console.warn("Operation timed out");
                return -1
            } else {
                if ( controller && controller.signal.aborted ) return;
                console.warn( errors )
                return -1
            }
        }
    }
}



export const QueryIntelligentSearchFacets =  async( {brandname, categoryid, controller } ) => {

    const OPTIONS = {
        method: 'GET',
        headers: {
            "accept": "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }

    // Injetando o signal no options
    if( controller ){
        OPTIONS.signal = controller.signal
    }

    try{
        //https://aramisnova.myvtex.com/_v/api/intelligent-search/facets/category-1/2/brand/aramis/
        const QUERYURL = `${ENV_CORSNOVAPROXY}/_v/api/intelligent-search/facets${ categoryid ? `/category-3/${categoryid}` : '' }/brand/Urban-Performance/`
        //console.log( QUERYURL )
        const response = await fetch( 
            QUERYURL, 
            OPTIONS
        ).catch(
            (err) => {
                if ( controller && controller.signal.aborted ) return;
                console.error(err);
            }
        )

        const data = await response.json()
        console.log( data )

        return data
    }catch( errors ){
        if (errors.name === "AbortError") {
            console.warn("Operation timed out");
            return -1
        } else {
            if ( controller && controller.signal.aborted ) return;
            console.warn( errors )
            return -1
        }
    }
}