import { useState, useEffect, useCallback } from "react";
import { ENV_CORSPROXY } from './envdata';

const HEADERS =  {
        "accept": "application/json",
        "content-type": "application/json"
    }

const OPTIONS = {
    method: 'GET',
    headers: HEADERS,
}


export const GetCategories = ( retrys, delay ) => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const FetchCategories = async( controller, retrys, delay ) => {
        // Injetando o signal no options
        if( controller ){
            OPTIONS.signal = controller.signal
        }

        if( delay ){
             await new Promise(r => setTimeout(r, delay))
        }

        try{
            const QUERYURL = `${ENV_CORSPROXY}/api/catalog_system/pub/category/tree/4`
            const response = await fetch(
                QUERYURL,
                OPTIONS
            ).catch(
                (err) => {
                    if ( controller && controller.signal.aborted ) return;
                    console.error(err);
                }
            )

            if( response ){
                let newData = []
                const data = await response.json()
                
                for (let i = 0; i < data.length; i++) {
                    if (data[i].name === "Urban Performance") {
                        
                        if (data[i].hasChildren) {
                            const childresUrban = []

                            data[i].children.forEach(item => {
                                if (item.hasChildren) {
                                    item.children.forEach(children => {
                                        if (children.hasChildren) {
                                            childresUrban.push(children)
                                        }
                                    })
                                }
                            })
                            newData = childresUrban 
                        }
                    }
                }

                return newData
            }
        }catch( errors ){
            if( retrys > 0 ){
                await new Promise( resolve => setTimeout( resolve, delay ) )
                console.warn( `Request Error! trying again` )
                return FetchCategories( controller, retrys - 1, delay )
            }else{ 
                if (errors.name === "AbortError") {
                    console.warn("Operation timed out");
                    return -1
                } else {
                    if ( controller && controller.signal.aborted ) return;
                    console.warn( errors )
                    return -1
                }
            }
        }
    }

    const fetchdata = useCallback( async(controller) => {
        // Voltando para o estado inicial
        setLoading(true)
        setData(null)
    
        // Injetando o signal no options
        if( controller ){
            OPTIONS.signal = controller.signal
        }

        // Fazendo a requisição
        const categories = await FetchCategories( controller, retrys, delay )

        // Tratando o response
        if( categories ){
            setData(categories)
            setLoading(false)
        }

    }, [])

    useEffect(() => {
        const controller = new AbortController()

        fetchdata(controller)

        return () => {
            controller.abort()
        }

    }, [ fetchdata ]);

    return [ data, loading ];
}