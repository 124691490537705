/* REACT */
import React, { useState, useRef } from "react";
import BackgroundSection from '../components/background-section';
import { useSwipeable } from "react-swipeable";
import { motion } from "framer-motion";


export default function PagePrototype(){

    const images = [
        {
            id: 1,
            url: require( '../assets/images/mockup/mockup-01.jpg' ),
            alt: 'image 1',
            bullets: [
                {
                    x: '65%',
                    y: '10%',
                    title: 'Arcu dolor arcu',
                    flavor: 'Sit quam cras ac fermentum purus. Pharetra, laoreet sit risus cursus aliquam sem ullamcorper. '
                },
            ]
        },
        {
            id: 2,
            url: require( '../assets/images/mockup/mockup-02.jpg' ),
            alt: 'image 1'
        },
        {
            id: 3,
            url: require( '../assets/images/mockup/mockup-03.jpg' ),
            alt: 'image 1',
            bullets: [
                {
                    x: '25%',
                    y: '50%',
                    title: 'Arcu dolor arcu',
                    flavor: 'Sit quam cras ac fermentum purus. Pharetra, laoreet sit risus cursus aliquam sem ullamcorper. '
                }
            ]
        },
        {
            id: 4,
            url: require( '../assets/images/mockup/mockup-04.jpg' ),
            alt: 'image 1',
            bullets: [
                {
                    x: '25%',
                    y: '50%',
                    title: 'Nam egestas cum',
                    flavor: 'Sit quam cras ac fermentum purus. Pharetra, laoreet sit risus cursus aliquam sem ullamcorper. '
                },
                {
                    x: '80%',
                    y: '5%',
                    title: 'Arcu dolor arcu',
                    flavor: 'Sit quam cras ac fermentum purus. Pharetra, laoreet sit risus cursus aliquam sem ullamcorper. '
                }
            ]
        },
        {
            id: 5,
            url: require( '../assets/images/mockup/mockup-05.jpg' ),
            alt: 'image 1',
        },
        {
            id: 6,
            url: require( '../assets/images/mockup/mockup-06.jpg' ),
            alt: 'image 1',
        },
        {
            id: 7,
            url: require( '../assets/images/mockup/mockup-07.jpg' ),
            alt: 'image 1',
        },
    ]

    const myRef = useRef();
    const config = {
        delta: 10, // min distance(px) before a swipe starts
        preventScrollOnSwipe: false, // prevent default touchmove on document.body
        trackTouch: true, // track touch input
        trackMouse: true, // track mouse input
        rotationAngle: 0, // set a rotation angle
    }
    const handlers = useSwipeable({
        onSwiping: (eventData) => swipingHandler( eventData ),
        ...config,
    })

    const swipingHandler = (eventData) => {
        const windowWidth = window.innerWidth;
        let moviment = 0

        if( eventData.dir === "Left" ){
            const percent = ( eventData.absX  * 100 ) / Math.min( windowWidth, eventData.initial[0] )
            const target_move = ( images.length * percent ) / 100
            moviment = ( Math.min( Math.round( target_move ) , images.length ) )
            moviment = Math.max( 0, images.length - moviment )
            //console.log( moviment )
        }
        if( eventData.dir === "Right" ){
            const percent = ( eventData.absX * 100 ) / ( windowWidth - eventData.initial[0] )
            const target_move = ( images.length * percent ) / 100
            moviment = Math.min( Math.round( target_move ) , images.length )  
            //console.log( moviment )  
        }

        setActiveImage( moviment > images.length -1 ? images.length -1 : moviment )
    }

    const [ activeImage, setActiveImage ] = useState( 3 );


    return (
        <section className='h-screen bg-alto'>
            { /* 360mockup */ }
            <BackgroundSection foreigner_color={'#D9D9D9'} background_color={'#fff'} nopad={true} oversized={true}>
                <div 
                    ref={myRef} 
                    {...handlers} 
                    className='bg-white pt-[20vw] pb-[10vw]'
                >
                    <div className='max-w-full min-h-[30vh] relative'>
                        { images.map( ( image, i ) => (
                            <div 
                                key={i} 
                                className='absolute left-0 right-0 top-0 bottom-0 h-[24vw] w-full m-auto select-none'
                                style={{ zIndex: activeImage === i ? i+10 : i }}
                            >
                                <div className='absolute left-0 right-0 top-0 bottom-0' ></div>
                                <img 
                                    src={image.url} 
                                    alt={image.alt} 
                                    className='w-full h-full object-cover select-none' 
                                    
                                />
                                { image?.bullets && image.bullets.map( ( bullet, j ) => (
                                    <div key={j} className='absolute top-0 left-0 w-[3vw] h-[3vw] flex items-center justify-center' style={{ zIndex: i, top: bullet.y, left: bullet.x }}>
                                        <motion.button 
                                            variants={{
                                                hidden: {
                                                    scale: 0,
                                                    opacity: 0
                                                },
                                                visible: {
                                                    scale: 1,
                                                    opacity: 1
                                                }
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.1
                                            }}
                                            initial='initial'
                                            animate={ activeImage === i ? 'visible' : 'hidden' }
                                            className='relative w-[2vw] h-[2vw] bg-pear border-2 border-alto rounded-full'
                                        > 
                                            <span className='absolute left-0 top-0 bottom-0 right-0 bg-white rounded-full block animate-ping'></span>
                                        </motion.button>
                                        <div className='absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 w-[150px] h-[150px]'>
                                            
                                        </div>
                                    </div>
                                ) ) }
                            </div>
                        ) ) }
                    </div>

                    <h3 className='absolute left-0 right-0 top-[14%] text-[5vw] text-[#AAA] font-RedHatBold text-center'> 
                        ACABAMENTO SUSTENTAVEL 
                    </h3>
                </div>
            </BackgroundSection>
        </section>
    );
}