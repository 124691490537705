import React, { useRef, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import { UseOverlayContext } from '../context/overlay-context';

import QrcodeSession from './qrcodeSession';
import SearchModal from './search-modal';
import Faq from './faq';

import { UseFilterContext } from '../context/filter-context';
import { UseCartModalContext } from '../context/cart-modal-context';
import { UseFaqContext } from '../context/faq-context';
import { UseProductModalContext } from '../context/product-modal-context';
import { UseCartContext } from '../context/cart-context';
import { UseSearchContext } from '../context/search-context';


export default function Overlay() {
    const overlayRef = useRef(null)

    // *---------------
    // * OVERLAY CONTEXT
    // *---------------
    const { 
        overlay, overlayZindex, ActiveOverlay, DisableOverlay, setOverlayZindex, disabledQrcode
    } = UseOverlayContext()

    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        searchModal, setSearchModal
    } = UseSearchContext()

    // *---------------
    // * FILTER CONTEXT DATA
    // *---------------
    const {
        filter_is_visible, HideFilterModal
    } = UseFilterContext()

    // *---------------
    // * PRODUCT MODAL CONTEXT
    // *---------------
    const { 
        activeproduct, setActiveModalItem
    } = UseProductModalContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        firstAccessCart, setFirstAccessCart, setKeyboardInputReference
    } = UseCartContext()

    // *---------------
    // * FAQ CONTEXT
    // *---------------
    const {
        faqIsOpen, setFaqIsOpen
    } = UseFaqContext()

    // *---------------
    // * CART MODAL CONTEXT DATA
    // *---------------
    const {
        cart_modal, DisableCartModal
    } = UseCartModalContext()

    // FUNCTION CLICK IN MASK DISABLE ALL MODALS


    // CHECK OPEN MODALS NOT TO DISABLE OVERLAY
    useEffect(() => {
        if( filter_is_visible || faqIsOpen || cart_modal || activeproduct || searchModal || firstAccessCart === 'enable' ){
            ActiveOverlay()
        }else {
            DisableOverlay()
        }
    }, [filter_is_visible, ActiveOverlay, DisableOverlay, faqIsOpen, activeproduct, cart_modal, firstAccessCart, searchModal] )

    return(
        <AnimatePresence>
            { overlay && (
                <motion.div 
                    variants={{
                        visible: {
                            opacity: 1,
                        },
                        hidden:{
                            opacity: 0,
                        }
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className={`fixed top-0 flex items-center justify-center bottom-0 left-0 right-0  ${overlayZindex !== 40 ? `z-${overlayZindex}` : 'z-40'}`}
                >
                    <div onClick={() => [ DisableOverlay(), HideFilterModal(), DisableCartModal(), setKeyboardInputReference(''), setFaqIsOpen(false), setSearchModal(false), setActiveModalItem(null), firstAccessCart === 'enable' ? setFirstAccessCart('disable') : null, setOverlayZindex(40), disabledQrcode()]} ref={overlayRef} className='w-full h-full absolute bg-opacity-80 top-0 left-0 bg-black' />
                    
                    <QrcodeSession />
                    <Faq />
                    <SearchModal />
                </motion.div>
            ) }
        </AnimatePresence>
    )

}