import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion"
import SidebarArea from '../components/sidebar';
import { useLocation } from 'react-router-dom';
import Cart from '../components/cart';
import { ProductsQueryIntelligentSearch } from '../shared/intelligent-search';
import ProductList from "../components/product-list-revised";
import { UseSearchContext } from "../context/search-context";
import { UseCartContext } from "../context/cart-context";
import Keyboard from "../components/keyboard";
import ProdctModal from "../components/product-modal";
import Overlay from "../components/overlay";
import BackgroundSection from "../components/background-section";


// *---------------
// * PAGE TRANSITION ANIMATION
// *---------------
const pagetransition = {
    hidden: {
        x: '-100vw',
        opacity: 0
    },
    visible: {
        x: '0px',
        opacity: 1,
        transition: {
            delay: .3,
            duration: .6
        }
    },
    exit: {
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}

export default function PageSearch(){
    const { search } = useLocation()
    
    // *---------------
    // * SEARCH CONTEXT
    // *---------------
    const {
        searchQuery, setSearchQuery, queryInPage, setQueryInPage
    } = UseSearchContext()

    // *---------------
    // * CART CONTEXT
    // *---------------
    const { 
        openKeyboard, keyboardInputReference, setKeyboardInputReference
    } = UseCartContext()

    // *---------------
    // * ROUTER PARAMS
    // *---------------

    useEffect(() => {
        const params = new URLSearchParams(search)

        const q = params.get('q');

        setSearchQuery(q ? q : '')
    }, [setSearchQuery, search])

    // *---------------
    // * ROUTER PARAMS
    // *---------------
    
    const [ products, setProducts ] = useState( [] )
    const [ loading, setLoading ] = useState( true )
    const [ pagination, setPagination ] = useState( null )
    const [ overflowWhite, setOverlayWhite ] = useState( false )

    const submitAction = (e) => {
        e.preventDefault()

        setSearchQuery(queryInPage)

        setKeyboardInputReference('')
    }

    const setIput = useCallback(() => {
        setQueryInPage(searchQuery)
    }, [setQueryInPage, searchQuery])

    useEffect(() => {
        setIput()
    }, [setIput])

    const QueryData = useCallback( async( { query, brandname, page, perpage, categoryid, filterquery, controller } ) => {
        setLoading( true )
        const rawdata = await ProductsQueryIntelligentSearch( { brandname, query, page, perpage, categoryid, filterquery, controller, retrys: 3, delay: 3000 } )

        if( rawdata ) {
            
            if( rawdata.products && rawdata.products.length > 0 ) { 
                setProducts( rawdata.products )
            }else{
                setProducts( -1 )
            }

            if( rawdata.pagination ){
                setPagination( rawdata.pagination )
            }

        }

        setLoading( false )
    }, [] )

    const PaginationClickHandler = ( page ) => {
        QueryData( {  query: searchQuery, page, perpage: 12 } )
    }

    useEffect(() => {
        setLoading(true)
        setProducts(1)
        QueryData( {  query: searchQuery, page: 1, perpage: 12} )

    }, [searchQuery, QueryData])

    // *---------------
    // * MARKUP
    // *---------------
    return (
        <motion.div
            variants={pagetransition}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='bg-white min-h-screen w-screen flex flex-col overflow-hidden'
        >

            <BackgroundSection className={'pt-[7vw] px-[2vw]'} foreigner_color={'#fff'} background_color={'white'}>
                <form 
                    onSubmit={submitAction} 
                    className='w-full rounded-[4.19vw] z-20 relative pl-[1.41vw] m-auto pr-[1.05vw] border-black flex border-[0.091158vw] bg-[#F2F2F2] mb-[4.69vw] h-[3.46vw] max-w-[70.83vw] overflow-hidden outline-none'
                >
                    <input onClick={() => {openKeyboard('search-page'); setOverlayWhite(true)}} placeholder='Faça uma nova pesquisa' className='outline-none bg-transparent text-[1.64vw] font-RedHatRegular h-full flex-1' type='text' value={queryInPage} onChange={(e) => setQueryInPage(e.target.value)} />
                    <button className='outline-none py-[1.05vw] h-full' type='submit' > 
                        <svg className='w-full h-full top-0 left-0 right-0 bottom-0 m-auto block' height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.122 28.2441C22.3692 28.2441 28.2441 22.3692 28.2441 15.122C28.2441 7.87494 22.3692 2 15.122 2C7.87494 2 2 7.87494 2 15.122C2 22.3692 7.87494 28.2441 15.122 28.2441Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M24.3979 24.4082L31.9904 32.0006" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </form>

                { products !== null && products !== -1 && ( 
                    <h2 className="text-[2.10vw] font-RedHatBold mb-[3.65vw] text-arsenic">
                        Você pesquisou por "{searchQuery && searchQuery}" 
                    </h2>
                ) }

                { products === -1 && ( 
                    <h2 className="text-[2.10vw] mt-[3vw] text-center font-RedHatBold mb-[3.65vw] text-arsenic">
                        Ops... não encontramos nenhum produto com <br /> {searchQuery && searchQuery} 
                    </h2>
                ) }
                
                <div className='w-full justify-between mb-[2vw]'>
                    { /* PRODUCTS RESULT */ }

                        
                        { /* PRODUCTS LIST */ }
                        { products !== null && products !== -1 && ( 
                            <ProductList products={products} placeholders={12} columns={4} loading={loading} />
                        ) }

                </div>
                
                { /* PRODUCTS PAGINATION */ }
                <div className='text-center w-full pt-[3vw]'>
                    { pagination && (
                        <ul className='flex items-center justify-center gap-[1vw]'>
                            { pagination.before && pagination.before.map( (item, index) => (
                                <li key={index}>
                                    <button onClick={ () => { PaginationClickHandler( item.index ) } } className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold'>
                                        {item.index}
                                    </button>
                                </li>
                            ) ) }
                            { pagination.current && (
                                <li>
                                    { products !== null && products !== -1 && ( 
                                        <button className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold bg-arsenic text-white'>
                                            { pagination.current.index }
                                        </button>
                                    ) }                                    
                                </li>
                            )}
                            { pagination.after && pagination.after.map( ( item, index ) => ( 
                                <li key={index}>
                                    <button onClick={ () => { PaginationClickHandler( item.index ) } } className='border-darkgray border-[0.2vw] rounded-full h-[3vw] w-[3vw] text-[1.2vw] font-RedHatBold'>
                                        {item.index}
                                    </button>
                                </li>
                            ) ) }
                        </ul>
                    ) }
                </div>
                
                {overflowWhite && keyboardInputReference && (
                    <div onClick={() => {setOverlayWhite(false); setKeyboardInputReference('')}} className="w-full h-full absolute bg-white top-0 left-0 z-10 opacity-70" />
                )}
            </BackgroundSection>

            { /* SIDEBAR */ }
            <SidebarArea>
                <Cart />
            </SidebarArea>

            <ProdctModal />

            <Overlay />
            {keyboardInputReference === 'search-page' ? <Keyboard className='m-auto w-max' top='28vw' left='0' right='0'/> : null}

        </motion.div>
    );
}