import { ENV_CORSPROXY } from './envdata'


//https://developers.vtex.com/vtex-rest-api/reference/productsearchsimilars 

export const QueryProductByLink = async ( { productTextLink, retrys, delay } ) => {
    const HEADERS =  {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }

    const OPTIONS = {
        method: 'GET',
        headers: HEADERS,
    }

    // FETCH DELAY
    await new Promise(r => setTimeout(r, delay ? delay : 1000) )

    try{
        const fetch_url = `${ENV_CORSPROXY}/api/catalog_system/pub/products/search/${productTextLink}/p`
        const response = await fetch( fetch_url, OPTIONS )
        const data = await response.json()
        
        return data
    }catch( errors ){
        if( retrys > 0 ){
            console.warn( `Request Error! trying again, \nerror log: ${errors}` )
            return QueryProductByLink( { productTextLink, retrys: retrys - 1, delay: 5000 } )
        }else{
            if (errors.name === "AbortError") {
                console.warn("Operation timed out");
                return -1
            }
        }
        
    }

}