import React from 'react';
import { UseFrontEndContext } from '../context/frontend-context';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion";

/*const highlightsAnimation3 = {
    hidden: {
        opacity: 0,
        x: -20,
    },
    visible: i => ({
        opacity: 1,
        x: 0,
            transition: {
            type: "Inertia",
            bounce: 0.4,
            duration: 0.8,
            delay: i
        },
    })
};*/

export default function Highlights() {
    // *---------------
    // * FRONT END CONTEXT
    // *---------------
    const { 
        highlights
    } = UseFrontEndContext()

    return(
        <section className=''>
            {
                false && (
                    <h2 className='text-3xl lg:text-[2.2vw] mb-4 lg:mb-[2vw] font-RedHatBold text-left text-white uppercase'> titulo </h2>
                )
            }

            {  highlights && (
                <ul className='flex items-center justify-between pr-[5vw]'>
                    { highlights.map( (highlight, index) => (
                        <motion.li key={index} className='block relative overflow-hidden w-[calc(25%-1.8vw)] pb-[calc(25%-1.8vw)] pt-[3vw]'>
                            <NavLink to={highlight.target}>
                                <figcaption className='absolute font-RedHatBold text-[1.5vw] top-0 uppercase text-pear'> {highlight.title} </figcaption>
                                <img 
                                    className='absolute top-[3vw] right-0 left-0 bottom-0 m-auto w-full object-cover rounded-xl overflow-hidden ' 
                                    src={highlight.imgurl}
                                    alt={highlight.title}
                                />
                            </NavLink>
                        </motion.li>
                    ) ) }
                </ul>
            ) }
        </section>
    )

}